import * as OurType from "../../typebox";

export const UnderwritingNegative = OurType.OurEnum({
  amount: { title: "Requested amount" },
  creditHistory: { title: "Credit history is negative" },
  weakFinancialPosition: { title: "Weak financial position" },
  misleadLender: { title: "Tried to mislead the lender" },
  lowInventory: {},
  rarelyOpened: { title: "Store is rarely opened" },
  notOwner: { title: "Store doesnt belong to the applicant" },
  lowScore: {},
  businessCategory: {},
  location: {},
  revenue: {},
  notReadyForVisit: {},
  age: { title: "Client's age" },
  cancelled: { title: "Client requested to cancel" },
  productCriteria: { title: "Does not meet the product criteria" },
  other: {},
});
