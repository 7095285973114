import { Box } from '@mui/material';
import { BoxProps } from '@mui/system';

type ImageProps = {
  src: string;
  width?: number;
  height?: number;
} & BoxProps;

export const Image = ({ ...props }: ImageProps) => {
  return <Box component="img" {...props} />;
};
