import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { SaleChannel } from "./SaleChannel";

export const Business = OurType.PrismaModel("Business", {
  id: Type.Number(),
  name: Type.String(),
  saleChannels: Type.Array(SaleChannel),
});
