import StepLayout from '../pages/StepLayout';
import { Navigate, RouteObject } from 'react-router-dom';
import { steps, StepContent } from '../Steps';
import { AuthGuard } from '../../auth/AuthGuard';

const routes = steps.reduce<StepContent[]>((acc, step) => [...acc, ...step.content], []);
const publicSteps = routes.filter((route) => route.isPublic);
const privateSteps = routes.filter((route) => !route.isPublic);

const Router: RouteObject[] = [
  ...publicSteps.map((step) => ({
    path: step.path,
    element: <StepLayout {...step.layoutProps} />,
    children: [step],
  })),
  ...privateSteps.map((step) => ({
    path: step.path,
    element: (
      <AuthGuard>
        <StepLayout {...step.layoutProps} />
      </AuthGuard>
    ),
    children: [step],
  })),
  {
    path: '*',
    element: <Navigate to={routes[0].path} />,
  },
];

export default Router;
