import { ThemeProvider, createTheme } from '@mui/material';
import { FC } from 'react';
import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import ScrollToTop from '../components/ScrollToTop';
import { store } from '../store/store';
import { SnackbarProvider } from './components/snackbar/SnackbarProvider';
import Router from './routes/Router';
import ErrorBoundary from '../components/ErrorBoundary';
import { useBrandSettings } from './hooks/useBrandSettings';
import { useDynamicFavicon } from './hooks/useDynamicFavicon';
import Cookies from 'js-cookie';
import { UTM_COOKIE_NAME } from '@getmo/onboarding/shared/cookies';
import { UTM_KEYS } from '@getmo/common/shared/models/utm';
import { APIProvider } from '@vis.gl/react-google-maps';
import { useGoogleMapsCreds } from './hooks/useGoogleMapsCreds';

const params = new URLSearchParams(location.search);
const utm: Record<string, string> = {};
for (const key of UTM_KEYS) {
  const value = params.get('utm_' + key);
  if (value) {
    utm[key] = value;
  }
}
if (Object.keys(utm).length) {
  Cookies.set(UTM_COOKIE_NAME, JSON.stringify(utm), {
    expires: 365,
    domain: '.' + location.hostname.split('.').slice(-2).join('.'),
  });
}

export const OnboardingApp: FC = () => {
  const routing = useRoutes(Router);
  const { themeSettings, favicons } = useBrandSettings();
  const theme = createTheme(themeSettings);
  const { apiKey: mapsApiKey } = useGoogleMapsCreds();
  useDynamicFavicon(favicons);

  return (
    <ThemeProvider theme={theme}>
      <ScrollToTop>
        <Provider store={store}>
          <APIProvider apiKey={mapsApiKey}>
            <SnackbarProvider>
              <ErrorBoundary>{routing}</ErrorBoundary>
            </SnackbarProvider>
          </APIProvider>
        </Provider>
      </ScrollToTop>
    </ThemeProvider>
  );
};
