import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { ShopOrderStatus } from "./ShopOrderStatus";

export const ShopOrder = OurType.PrismaModel("ShopOrder", {
  id: Type.Number(),
  number: Type.String(),
  createdAt: OurType.DateType(),
  updatedAt: OurType.DateType(),
  status: ShopOrderStatus,
  amount: OurType.TypeDecimal(),
  fee: OurType.TypeDecimal(),
  city: OurType.Nullable(Type.String()),
  connectionId: Type.Number(),
});
