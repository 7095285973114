import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ImageList,
  ImageListItem,
  Typography,
} from '@mui/material';
import { useRef, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';

export const useImagePickerModal = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const resolveRef = useRef<(file: File | null) => void>(() => {});

  const open = (files: File[]) => {
    setFiles(files);
    setIsModalOpen(true);

    return new Promise<File | null>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  const onConfirm = () => {
    resolveRef.current(selectedFile);
    setIsModalOpen(false);
  };

  const onCancel = () => {
    resolveRef.current(null);
    setIsModalOpen(false);
  };

  const selectFile = (index: number) => {
    setSelectedFile(files[index]);
  };

  const modal = (
    <Dialog open={isModalOpen} onClose={onCancel} maxWidth={'sm'}>
      <DialogTitle textAlign="center">Select one image</DialogTitle>
      <DialogContent>
        <Typography mb={2}>
          It seems you are trying to upload a PDF document with multiple images. Please choose the image you would like
          to upload.
        </Typography>

        <ImageList cols={files.length < 2 ? 1 : 2}>
          {files.map((file, i) => (
            <ImageListItem key={i} onClick={() => selectFile(i)}>
              {selectedFile === file && (
                <CheckIcon
                  fontSize="large"
                  style={{
                    left: 'calc(50% - 17.5px)',
                    top: 'calc(50% - 17.5px)',
                    position: 'absolute',
                    color: 'white',
                    zIndex: '1',
                  }}
                />
              )}
              <img
                srcSet={URL.createObjectURL(file)}
                src={URL.createObjectURL(file)}
                loading="lazy"
                style={selectedFile === file ? { filter: 'brightness(0.5)' } : {}}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </DialogContent>
      <Box mb={1}>
        <DialogActions>
          <Button variant="contained" color="primary" size="small" onClick={onConfirm} disabled={!selectedFile}>
            Upload
          </Button>
          <Button size="small" onClick={onCancel}>
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );

  return { modal, open };
};
