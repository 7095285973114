import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const UnderwritingNote = OurType.PrismaModel("UnderwritingNote", {
  id: Type.Number(),
  underwritingId: Type.Number(),
  key: Type.String(),
  date: OurType.DateType(),
  text: OurType.Multiline(),
  employeeId: Type.Number(),
});
