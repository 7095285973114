import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { UserRole } from "./UserRole";
import { UnderwritingStatus } from "./UnderwritingStatus";
import { UnderwritingNegative } from "./UnderwritingNegative";

export const UnderwritingAction = OurType.PrismaModel("UnderwritingAction", {
  id: Type.Number(),
  underwritingId: Type.Number(),
  date: OurType.DateType(),
  negatives: Type.Array(UnderwritingNegative),
  comment: OurType.RichText(),
  newStatus: OurType.Nullable(UnderwritingStatus),
  isAutoCheck: Type.Boolean({ default: false }),
  employeeId: Type.Number(),
  employeeRole: UserRole,
  selectedTermsId: OurType.Nullable(
    Type.Number({ title: "Terms suggested at the stage" })
  ),
});
