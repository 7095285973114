import { createEndpoint } from '@getmo/common/endpoint';
import * as DB from '@getmo/common/prisma/typebox';
import { Nullable } from '@getmo/common/typebox';
import { type StaticDecode, Type } from '@getmo/common/vendor/typebox';

export type Shop = StaticDecode<typeof shop>;
const shop = Type.Object({
  id: Type.Number(),
  isSynced: Type.Boolean(),
  link: Type.String(),
  type: DB.ShopType,
});

export type ShopData = StaticDecode<typeof shopData>;
const shopData = Type.Object({
  type: DB.ShopType,
  link: Type.String(),
});

export type ShopSyncLink = StaticDecode<typeof shopSyncLink>;
const shopSyncLink = Type.Object({
  link: Type.String(),
  type: Nullable(DB.ShopType),
  isSynced: Type.Boolean(),
  syncLink: Type.String(),
});

export const shopListEndpoint = createEndpoint({
  method: 'get',
  path: '/user/data/shops',
  response: Type.Array(shop),
});

export const shopCreateEndpoint = createEndpoint({
  method: 'post',
  path: '/user/data/shops',
  body: shopData,
  response: shop,
});

export const shopUpdateEndpoint = createEndpoint({
  method: 'patch',
  path: '/user/data/shops/:shopId',
  params: Type.Object({
    shopId: Type.Number(),
  }),
  body: shopData,
  response: shop,
});

export const shopDeleteEndpoint = createEndpoint({
  method: 'delete',
  path: '/user/data/shops/:shopId',
  params: Type.Object({
    shopId: Type.Number(),
  }),
  response: Type.Object({}),
});

export const shopSyncEndpoint = createEndpoint({
  method: 'get',
  path: '/user/data/shops/:saleChannelId/sync',
  params: Type.Object({
    saleChannelId: Type.Number(),
  }),
  response: shopSyncLink,
});

export const shopeeCallbackEndpoint = createEndpoint({
  method: 'get',
  path: '/shops/callback/shopee/:saleChannelId',
  params: Type.Object({
    saleChannelId: Type.Number(),
  }),
  qs: Type.Object({
    refererHost: Type.Optional(Type.String()),
    code: Type.String(),
    shop_id: Type.Number(),
  }),
  response: Type.Void(),
});

export const lazadaCallbackEndpoint = createEndpoint({
  method: 'get',
  path: '/shops/callback/lazada/:saleChannelId',
  params: Type.Object({
    saleChannelId: Type.Number(),
  }),
  qs: Type.Object({
    refererHost: Type.Optional(Type.String()),
    code: Type.String(),
  }),
  response: Type.Void(),
});
