import { Stack, StackProps, Typography } from '@mui/material';
import { Image } from '../../components/Image';

type Props = {
  text: string;
  src: string;
  isCenter?: boolean;
} & StackProps;

export const VerifiedBy = ({ text, src, isCenter, ...stackProps }: Props) => {
  return (
    <Stack
      {...stackProps}
      display="flex"
      direction="row"
      columnGap={2}
      alignItems="center"
      justifyContent={isCenter ? 'center' : 'start'}
    >
      <Typography color="text.secondary" variant="subtitle1">
        {text}
      </Typography>
      <Image src={src} />
    </Stack>
  );
};
