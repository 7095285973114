export enum Path {
  Index = '/',
  AccountPhoneStarter = '/account',
  AccountVerify = '/account/verify',
  Email = '/email',
  BusinessInformation = '/business',
  BusinessLocation = '/business/location',
  BusinessCompany = '/business/company',
  BusinessStores = '/business/stores',
  BankStatements = '/financial/bankStatements',
  Financial = '/financial',
  Verification = '/verification',
  VerificationKycInfo = '/verification/info',
  VerificationKycSelfie = '/verification/selfie',
  Simulation = '/simulation',
  Finish = '/finish',
}
