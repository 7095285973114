import * as OurType from "../../typebox";

export const RepaymentScheduleStatus = OurType.OurEnum({
  pendingInvoice: {},
  pendingRevenue: {},
  unpaid: {},
  paidOnTime: {},
  paidLate: {},
  restructured: {},
  writtenOff: {},
});
