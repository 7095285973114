import { LocalPhoneRounded } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import { usePhoneCode } from '../../../hooks/useBrandSettings';
import { noTranslateClassName } from '../../../../utils/weglot';

export const InputPhoneIcon = () => {
  const phoneCode = usePhoneCode();

  return (
    <>
      <InputAdornment position="start">
        <LocalPhoneRounded />
      </InputAdornment>
      <InputAdornment position="start" className={noTranslateClassName}>
        +{phoneCode}
      </InputAdornment>
    </>
  );
};
