import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const UnderwritingContractorsData = OurType.PrismaModel(
  "UnderwritingContractorsData",
  {
    underwritingId: Type.Number(),
    avgCustomers6Months: OurType.Nullable(
      Type.Number({
        title: "Average number of customers for 6 months",
        unit: "pcs",
      })
    ),
    mainCustomerShare6Months: OurType.Nullable(
      OurType.TypePercent({ title: "Share of the main customer for 6 months" })
    ),
    avgSuppliers6Months: OurType.Nullable(
      Type.Number({
        title: "Average number of suppliers for 6 months",
        unit: "pcs",
      })
    ),
    mainSupplierShare6Months: OurType.Nullable(
      OurType.TypePercent({ title: "Share of the main supplier for 6 months" })
    ),
  }
);
