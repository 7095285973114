import { Box, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import { useBrandSettings } from '../../hooks/useBrandSettings';

export const WelcomeStepper = () => {
  const { region } = useBrandSettings();
  const steps = [
    {
      label: 'Create an Account',
      description: `Start by filling out our online application. It takes around ${region === 'ph' ? 5 : 15} minutes to complete.`,
    },
    {
      label: 'Fill in & Connect your Data',
      description:
        region === 'ph'
          ? 'Fill in & upload the necessary documents.'
          : 'Fill in & upload the necessary documents, including your business registration, bank statements, personal info, and sales data.',
    },
    {
      label: 'Get an Offer',
      description:
        region === 'ph'
          ? 'We will prepare your Loan Offer the same day.'
          : 'We will prepare your Loan Offer in a maximum of 3 business days.',
    },
  ];
  return (
    <Box width="100%" pb={5}>
      <Stepper
        orientation="vertical"
        sx={{
          '& .MuiStepLabel-iconContainer': {
            marginRight: '8px',
          },
          '& .MuiStepContent-root': {
            pl: '28px',
          },
        }}
      >
        {steps.map((step, idx) => (
          <Step key={idx} active>
            <StepLabel sx={{ pt: idx !== 0 ? '15px' : '' }}>
              <Typography variant="subtitle1" fontWeight={600} pb={0}>
                {step.label}
              </Typography>
            </StepLabel>
            <StepContent>
              <Typography variant="subtitle1">{step.description}</Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
