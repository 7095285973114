import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { PhPersonalIdType } from "./PhPersonalIdType";
import { Gender } from "./Gender";

export const Person = OurType.PrismaModel("Person", {
  id: Type.Number(),
  name: OurType.Nullable(Type.String()),
  phone: OurType.FullPhone(),
  email: OurType.Nullable(OurType.Email()),
  personalIdNumber: OurType.Nullable(Type.String({ title: "Personal ID" })),
  phPersonalIdType: OurType.Nullable(PhPersonalIdType),
  dob: OurType.Nullable(OurType.LocalDate({ title: "Date of birth" })),
  gender: OurType.Nullable(Gender),
  photo: OurType.Nullable(Type.String()),
  photoId: OurType.Nullable(Type.String()),
  kyc: OurType.Nullable(Type.Boolean()),
});
