import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { ShopConnectionStatus } from "./ShopConnectionStatus";

export const ShopConnection = OurType.PrismaModel("ShopConnection", {
  saleChannelId: Type.Number(),
  createdAt: OurType.DateType(),
  updatedAt: OurType.DateType(),
  lastSyncAt: OurType.Nullable(OurType.DateType()),
  status: ShopConnectionStatus,
  shopId: OurType.Nullable(Type.Number()),
  shopName: OurType.Nullable(Type.String()),
  accessToken: OurType.Nullable(Type.String()),
  refreshToken: OurType.Nullable(Type.String()),
  tokenExpiresAt: OurType.Nullable(OurType.DateType()),
  refreshTokenExpiresAt: OurType.Nullable(OurType.DateType()),
});
