import { useMediaQuery, useTheme } from '@mui/material';

export const useThemeQuery = () => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down(380));

  return { isMedium, isSmall, isExtraSmall };
};
