import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import { FC, useState } from 'react';
import * as Yup from 'yup';
import { StepContent } from '../../../components/StepContent/StepContent';
import { StepSubmitButton } from '../../../components/StepSubmitButton/StepSubmitButton';
import { summaryThunk } from '../../../store/reducers/thunks';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { number } from '../../../utils/number';
import FormikSelectInput from '../../components/FormikSelectInput';
import FormikSliderInput from '../../components/FormikSliderInput';
import FormikTextInput from '../../components/FormikTextInput';
import { InputMoneyIcon } from '../../components/input/icons/InputMoneyIcon';
import { useSnackbar } from '../../components/snackbar/useSnackbar';
import { useApiErrors } from '../../hooks/useApiErrors';
import { useNextStep } from '../../hooks/useNextStep';
import { IdBusinessCategory, BusinessTypes, IndonesianCities, SalesChannel } from './businessInfoOptions';
import { $Enums } from '@getmo/common/prisma';
import { createSelector } from '@reduxjs/toolkit';
import { selectSummaryData } from '../../../store/reducers/summaryReducer';
import { DocumentFileInput } from '../../components/upload/DocumentFileInput';
import { DocumentType } from '@getmo/onboarding/shared/endpoints/documents';
import { defaultMimeTypes as defaultMimes } from '../../types/mimeType';
import FormikCheckbox from '../../components/FormikCheckbox';

const queryValues = new URLSearchParams(location.search);

const BusinessInformation: FC = () => {
  const title = <>Business Information</>;
  const subtitle = (
    <>
      To help us tailor the perfect financing solution for your business, please provide the following details. Your
      answer will assist us in understanding your unique needs and ensuring a seamless loan application process. Rest
      assured, your information is kept strictly confidential.
    </>
  );

  const selectInitialValues = createSelector([selectSummaryData], (summary) => {
    const { business } = summary;
    return {
      name: business.name || '',
      legalType: business.legalType || ('' as $Enums.LegalType),
      typeOfSalesChannel: business.typeOfSalesChannel || ('' as $Enums.SaleChannelType),
      idBusinessCategory: business.idBusinessCategory ?? ('' as $Enums.IdBusinessCategory),
      idCity: business.idCity ?? ('' as $Enums.IdCity),
      yearsBusinessOperation: business.yearsBusinessOperation ?? 1,
      revenue: String(business.revenue || queryValues.get('revenue') || ''),
      loanAmount: String(business.loanAmount || queryValues.get('loanAmount') || ''),
      loanPurpose: business.loanPurpose || '',
      loanDuration: business.loanDuration || Number(queryValues.get('loanDuration')) || 3,
    };
  });

  const initialValues = useAppSelector(selectInitialValues);

  const [initialErrors, setInitialErrors] = useState({});
  const { showError } = useSnackbar();
  const { goToNextStep } = useNextStep();
  const [isSubmitting, setSubmitting] = useState(false);
  const dispatch = useAppDispatch();

  const validation = Yup.object({
    name: Yup.string().required(),
    legalType: Yup.string().required(),
    typeOfSalesChannel: Yup.string().required(),
    idBusinessCategory: Yup.string().required(),
    idCity: Yup.string().required(),
    yearsBusinessOperation: Yup.number().required(),
    revenue: Yup.number().required(),
    loanAmount: Yup.number().required(),
    loanPurpose: Yup.string().required(),
    loanDuration: Yup.number().required(),
  });

  const onSubmit = async ({ loanAmount, revenue, ...values }: typeof initialValues) => {
    const data = {
      ...values,
      loanAmount: parseInt(loanAmount) || 0,
      revenue: parseInt(revenue) || 0,
    };

    setInitialErrors({});
    setSubmitting(true);

    dispatch(summaryThunk.updateBusinessData(data))
      .unwrap()
      .then(() => {
        goToNextStep();
      })
      .catch((e) => {
        const { error, validationErrors } = useApiErrors(e);
        setInitialErrors(validationErrors);
        if (error) {
          showError(error);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <StepContent title={title} subtitle={subtitle} width="standard">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        initialErrors={initialErrors}
        validationSchema={validation}
        onSubmit={onSubmit}
      >
        {({ initialValues, handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <Grid container direction="column" width="100%">
                <Grid item>
                  <FormikTextInput type="text" name="name" label="Shop Name" placeholder="GetMo Indonesia" required />
                </Grid>
                <Grid item width="100%">
                  <FormikSelectInput label="Business Type" name="legalType" options={BusinessTypes} required />
                </Grid>
                <Grid item width="100%">
                  <FormikSelectInput label="Sales Channel" name="typeOfSalesChannel" options={SalesChannel} required />
                </Grid>
                <Grid item width="100%">
                  <FormikSelectInput
                    label="Business location (city)"
                    name="idCity"
                    options={IndonesianCities}
                    required
                  />
                </Grid>
                <Grid item width="100%">
                  <FormikSelectInput
                    label="Business industry"
                    name="idBusinessCategory"
                    options={IdBusinessCategory}
                    required
                  />
                </Grid>
                <Grid item mb={2}>
                  <FormikSliderInput
                    name="yearsBusinessOperation"
                    label="How many years your business operates"
                    required
                    initialValue={initialValues.yearsBusinessOperation}
                    valueLabelDisplay="off"
                    valueLabel={(value) => {
                      if (value < 1) {
                        return `Less than 1 year`;
                      }
                      if (value > 10) {
                        return `More than 10 years`;
                      }
                      return `${value} year` + (value > 1 ? 's' : '');
                    }}
                    min={0}
                    max={11}
                  />
                </Grid>
                <Grid item>
                  <FormikTextInput
                    type="text"
                    name="revenue"
                    isNumeric
                    label="Average Monthly Revenue"
                    required
                    placeholder={(100000000).toLocaleString()}
                    InputProps={{ startAdornment: <InputMoneyIcon /> }}
                    transform={(value) => number.prettifyMoney(value)}
                    untransform={(value) => number.deprettifyMoney(value)}
                  />
                </Grid>
                <Grid item>
                  <FormikTextInput
                    type="text"
                    name="loanAmount"
                    isNumeric
                    label="Desired Loan Amount"
                    required
                    placeholder={(100000000).toLocaleString()}
                    InputProps={{ startAdornment: <InputMoneyIcon /> }}
                    transform={(value) => number.prettifyMoney(value)}
                    untransform={(value) => number.deprettifyMoney(value)}
                  />
                </Grid>
                <Grid item>
                  <FormikTextInput
                    type="text"
                    name="loanPurpose"
                    label="Loan Purpose"
                    placeholder="e.g Business expansion"
                    required
                  />
                </Grid>
                <Grid item>
                  <FormikSliderInput
                    name="loanDuration"
                    label="Desired Loan Duration"
                    required
                    initialValue={initialValues.loanDuration}
                    valueLabel={(value) => `${value} Month${value > 1 ? 's' : ''}`}
                    min={1}
                    max={7}
                  />
                </Grid>
                <Grid item mt={5}>
                  <DocumentFileInput
                    name="stockFile"
                    label="Upload a photo of your stock, goods for sale or place of doing business"
                    docType={DocumentType.saleChannelOwnershipProof}
                    mimeTypes={defaultMimes.companyProfile}
                    required={false}
                  />
                </Grid>
                <Grid item>
                  <FormikCheckbox
                    name="isProductivePurposeConfirmed"
                    label="I agree and acknowledge that the lended funds can be used only for productive purposes of the business described in this loan application"
                  />
                </Grid>
                <Grid item display="flex" justifyContent="center" py={3}>
                  <StepSubmitButton isSubmitting={isSubmitting} />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </StepContent>
  );
};

export default BusinessInformation;
