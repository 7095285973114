import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useState } from 'react';

export const useMissingStatementsDialog = (monthCount: number, onSubmit: VoidFunction) => {
  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };

  const modal = (
    <Dialog keepMounted open={open} onClose={closeModal} maxWidth={'sm'}>
      <DialogTitle textAlign="center">Could not confirm statements&apos; periods</DialogTitle>
      <DialogContent>
        <Typography fontWeight={500} sx={{ mb: '1rem' }}>
          We could not recognize the dates in all of the uploaded statements, so we cannot confirm, that they cover the
          last {monthCount} months completely.
        </Typography>
        <Alert severity="warning" sx={{ mb: '0.5rem' }}>
          You may proceed, but if any of the statements is missing, your application will not be considered. Did you
          make sure that you uploaded bank statements for every one of the last {monthCount} months?
        </Alert>
      </DialogContent>
      <Box>
        <DialogActions>
          <Button size="small" variant="contained" color="primary" onClick={onSubmit}>
            I&apos;m sure
          </Button>
          <Button size="small" onClick={closeModal}>
            Let me check again
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );

  return { modal, openModal, closeModal };
};
