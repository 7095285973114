import { createReducer } from '@reduxjs/toolkit';
import { kycAction } from './actions';

interface State {
  attemps: number;
}

export const kycDefaultAttempts = 3;
const initialState: State = {
  attemps: kycDefaultAttempts,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(kycAction.decrementAttemps, (state) => {
    state.attemps = state.attemps - 1;
  });
});
