import { type PDFDocument, type PDFRawStream } from '@cantoo/pdf-lib';
import { type Decoder } from 'image-in-browser';

type PdfContent = string | Uint8Array | ArrayBuffer;

const importPdfLib = () => import('@cantoo/pdf-lib' /* webpackPrefetch: true */);

/*
 * Trying to load pdf file
 */
export const loadPdfWithoutEncryption = async (pdf: PdfContent) => {
  try {
    const { PDFDocument } = await importPdfLib();
    return await PDFDocument.load(pdf, { ignoreEncryption: true });
  } catch {
    return null;
  }
};

export const loadPdfWithPassword = async (pdf: PdfContent, password: string) => {
  try {
    const { PDFDocument } = await importPdfLib();
    return await PDFDocument.load(pdf, { password });
  } catch {
    return null;
  }
};

const getImageExt = async (content: Uint8Array) => {
  const { BmpDecoder, GifDecoder, JpegDecoder, PngDecoder, TiffDecoder, WebPDecoder } = await import(
    'image-in-browser' /* webpackPrefetch: true */
  );
  const decorders: [Decoder, string][] = [
    [new BmpDecoder(), 'bmp'],
    [new GifDecoder(), 'gif'],
    [new JpegDecoder(), 'jpg'],
    [new PngDecoder(), 'png'],
    [new TiffDecoder(), 'tiff'],
    [new WebPDecoder(), 'webp'],
  ];
  const result = decorders.find(([decoder]) => {
    return decoder.isValidFile(content);
  });
  return result?.[1] ?? null;
};

const getImageMimeType = (ext: string) => {
  return `image/${ext === 'jpg' ? 'jpeg' : ext}`;
};

const extractImage = async (stream: PDFRawStream): Promise<{ content: Uint8Array; ext: string } | null> => {
  const { PDFName, PDFNumber, PDFArray } = await importPdfLib();
  const pako = await import('pako' /* webpackPrefetch: true */);

  const filterRes = stream.dict.lookup(PDFName.of('Filter'));
  const filter = filterRes instanceof PDFArray ? filterRes.lookup(0)?.toString() : filterRes?.toString();
  if (filter === '/JPXDecode') return null; // TODO: add support for JPEG2000

  const content = filter === '/FlateDecode' ? pako.inflate(stream.getContents()) : stream.getContents();

  const ext = await getImageExt(content);
  if (ext) {
    return { content, ext };
  }

  const { decodePnm, encodePng } = await import('image-in-browser' /* webpackPrefetch: true */);

  // pnm (pbm image) without headers
  const colorSpaceObj = stream.dict.lookup(PDFName.of('ColorSpace'));
  const colorSpace = colorSpaceObj instanceof PDFName ? colorSpaceObj.toString() : '';
  const bitsObj = stream.dict.lookup(PDFName.of('BitsPerComponent'));
  const bits = bitsObj instanceof PDFNumber ? bitsObj.asNumber() : 0;

  const pType = colorSpace.toString() === '/DeviceGray' ? 5 : colorSpace.toString() === '/DeviceRGB' ? 6 : null;
  if (!pType) {
    return null;
  }
  const hPType = `P${pType}`;
  const hImageSize = `${stream.dict.lookup(PDFName.of('Width'))} ${stream.dict.lookup(PDFName.of('Height'))}`;
  const hColorsNum = Math.pow(2, bits) - 1;
  const headers = new TextEncoder().encode([hPType, hImageSize, hColorsNum, ''].join('\n'));

  const pbmData = new Uint8Array(headers.length + content.length);
  pbmData.set(headers, 0);
  pbmData.set(content, headers.length);

  const pbmImage = decodePnm({ data: pbmData });
  if (pbmImage) {
    return { content: encodePng({ image: pbmImage }), ext: 'png' };
  }

  return null;
};

export const getPdfImages = async (document: PDFDocument, fileName: string, type: 'ktp' | 'npwp') => {
  const { PDFName, PDFRawStream } = await importPdfLib();

  if (type === 'npwp') {
    // Do not extract images from electronic NPWP

    // I'm very unsure that this code really checks existance of texts
    const hasTexts = document.context
      .enumerateIndirectObjects()
      .some((o) => o[1] instanceof PDFRawStream && o[1].dict.has(PDFName.of('Length1')));

    if (hasTexts) return [];
  }

  return (
    await Promise.all(
      document.context
        .enumerateIndirectObjects()
        .map(([, obj]) => obj)
        .filter((obj) => obj instanceof PDFRawStream && obj.dict.lookup(PDFName.of('Subtype'))?.toString() === '/Image')
        .map(async (pdfRawStream) => {
          if (!(pdfRawStream instanceof PDFRawStream)) {
            return;
          }

          const img = await extractImage(pdfRawStream);
          if (!img) {
            return;
          }

          return new File([img.content], `${fileName}.${img.ext}`, { type: getImageMimeType(img.ext) });
        }),
    )
  ).filter((f): f is File => !!f);
};
