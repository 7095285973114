type UrlOptions = {
  strictProtocol?: boolean;
};

export const getUrlRegex = (domain?: string, { strictProtocol = true }: UrlOptions = {}) => {
  const domainRegex = domain ?? '[-a-zA-Z0-9%._+~#=]{2,256}\\.[a-z]{2,6}';
  const protocol = `(http(s)?://)${strictProtocol ? '' : '?'}`;
  const regex = `^${protocol}(www.)?${domainRegex}([-a-zA-Z0-9@:%_+.~#?&//=]*)`;
  return new RegExp(regex, 'gi');
};

export const isUrlValid = (url: string, options?: UrlOptions) => getUrlRegex(undefined, options).exec(url);

export const isUrlMatching = (url: string, domain?: string, options?: UrlOptions) =>
  getUrlRegex(domain, options).exec(url);

export const addProtocol = (url: string): string => {
  if (!url || !isUrlValid(url, { strictProtocol: false }) || /^https?:\/\//i.test(url)) {
    return url;
  }
  return 'https://' + url;
};
