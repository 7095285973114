import { $Enums } from '@getmo/common/prisma';
import { unwrapResult } from '@reduxjs/toolkit';
import { ChangeEventHandler, FocusEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { shopAction } from '../../../store/reducers/actions';
import { ShopInput } from '../../../store/reducers/shopReducer';
import { shopThunk } from '../../../store/reducers/thunks';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { urls } from '../../../utils/url';
import { useSnackbar } from '../../components/snackbar/useSnackbar';
import { useApiErrors } from '../../hooks/useApiErrors';
import { useOpenWindow } from '../../hooks/useOpenWindow';
import { addProtocol } from '@getmo/common/utils/url';
import { useDebounce } from '../../hooks/useDebounce';
import { Button } from '@mui/material';
import { getShopType, getShopConnectionType } from '@getmo/common/utils/shops';
import { Shop } from '@getmo/onboarding/shared/endpoints/shops';

export const useConnectionStoreLink = (input: ShopInput) => {
  const { shops } = useAppSelector(({ shop }) => shop);
  const [url, setUrl] = useState('');
  const [isConnectRequired, setIsConnectRequired] = useState(false);
  const [error, setError] = useState('');
  const [needAcceptEmail, setNeedAcceptEmail] = useState(false);
  const shop = useMemo(() => input.shop, [input]);
  const isConnected = useMemo(() => !!shop?.isSynced, [shop]);
  const inputRef = useRef(null);
  const [axiosAbort, setAxiosAbort] = useState(new AbortController());

  const updateURL = (url: string) => {
    axiosAbort.abort();
    setAxiosAbort(new AbortController());
    setError('');
    setUrl(url);
  };

  useDebounce(() => updateShopData(url), 1000);

  const onURLChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => updateURL(target.value);

  const validate = (url: string): string => {
    if (!url) {
      return '';
    }
    if (!urls.isValid(url, { strictProtocol: false })) {
      return 'Invalid URL';
    }
    if (shops.some(({ id, link }) => link === url && id !== shop?.id)) {
      return 'Shop with this URL already exists';
    }
    return '';
  };

  const dispatch = useAppDispatch();

  const onClickRemoveBtn = useCallback(async () => {
    if (input.shop) {
      await dispatch(shopThunk.delete(input.shop.id));
    }
    setUrl('');
    dispatch(shopAction.deleteInput(input.id));
  }, [dispatch, input]);

  const updateShopData = useCallback(
    async (url: string) => {
      const error = validate(url);
      const shopType = getShopType(url);
      if (!error && shopType) {
        const data = {
          link: url,
          type: shopType,
        };
        if (!shop) {
          const result = await dispatch(shopThunk.create(data));
          dispatch(shopAction.updateInput({ input, shop: unwrapResult(result) }));
          return;
        }

        if (shop.link === url) {
          return;
        }

        const result = await dispatch(shopThunk.update({ id: shop.id, data }));
        dispatch(shopAction.updateInput({ input, shop: unwrapResult(result) }));
      }

      if (shop && !error && !url) {
        await dispatch(shopThunk.delete(shop.id));
        dispatch(shopAction.updateInput({ input, shop: undefined }));
      }
    },
    [dispatch, shop, input],
  );

  const onBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value.trim();
    const url = addProtocol(value);
    if (value !== url) {
      updateURL(url);
    }
    updateShopData(url);
    setError(url ? validate(url) : '');
  };

  useEffect(() => {
    const setShopData = async (shop: Shop) => {
      setUrl(shop.link);

      if (isConnected || getShopConnectionType(shop.type)) {
        dispatch(shopAction.clearSyncLink(shop.id));
      }
    };
    if (shop) {
      setShopData(shop);
    }

    setIsConnectRequired(shop ? !!getShopConnectionType(shop.type) : false);
  }, [shop]);

  useEffect(() => {
    if (shop) {
      dispatch(shopAction.clearSyncLink(shop.id));
    }
  }, [url]);

  const { showError, showWarning, closeSnackbar } = useSnackbar();
  const { openWindow } = useOpenWindow();
  const handleConnect = useCallback(() => {
    if (!shop) {
      return;
    }

    if (!input.syncLink) {
      dispatch(shopThunk.getSyncLink({ id: shop.id, controller: axiosAbort }))
        .unwrap()
        .then((result) => {
          if (result.type === $Enums.ShopType.tokopedia) {
            setNeedAcceptEmail(result.isSynced);
            if (!result.isSynced) {
              showWarning(
                <span>
                  An email has been sent to the account linked to your store.
                  <br />
                  Follow the link inside to grant GetMo read-only access to marketplace data,
                  <br /> then come back here and press &quot;Connect account&quot; again.
                </span>,
                {
                  persist: true,
                  action: (id) => (
                    <Button
                      variant="text"
                      color="secondary"
                      onClick={() => {
                        closeSnackbar(id);
                      }}
                    >
                      Dismiss
                    </Button>
                  ),
                },
              );
            }
            return;
          }

          if (result.syncLink) {
            openWindow(result.syncLink);
          } else {
            showWarning('Cannot connect your shop at the moment, try again later');
          }
        })
        .catch((e) => {
          const { error } = useApiErrors(e);
          if (error) {
            showError(error);
          }
        })
        .finally(() => {
          setAxiosAbort(new AbortController());
        });
      return;
    }

    if (input.shop?.type !== $Enums.ShopType.tokopedia) {
      openWindow(input.syncLink);
    }
  }, [shop, input.syncLink]);

  return {
    shop,
    isConnectRequired,
    isConnected,
    inputValue: url,
    inputRef,
    error,
    onURLChange,
    onBlur,
    onClickRemoveBtn,
    handleConnect,
    needAcceptEmail,
  };
};
