import { createEndpoint } from '@getmo/common/endpoint';
import * as DB from '@getmo/common/prisma/typebox';
import { Base64, Nullable } from '@getmo/common/typebox';
import { type StaticDecode, Type } from '@getmo/common/vendor/typebox';

export type PersonUnverifiedData = StaticDecode<typeof personUnverifiedData>;
const personUnverifiedData = Type.Composite([
  Type.Pick(DB.PersonUnverifiedData, ['fullName', 'dob', 'gender', 'personalIdNumber']),
  Type.Object({
    phPersonalIdType: Type.Optional(DB.PersonUnverifiedData.properties.phPersonalIdType),

    // Backward compatibility
    personalIdTypePh: Type.Optional(DB.PersonUnverifiedData.properties.phPersonalIdType),
  }),
]);

export const personUnverifiedDataReadEndpoint = createEndpoint({
  method: 'get',
  path: '/person/unverfieid',
  response: Type.Union([
    Type.Object({}),
    Type.Object({ data: personUnverifiedData }),
    Type.Object({ ktpOcrData: Type.Pick(DB.KtpOcrData, ['fullName', 'dob', 'nik']) }),
  ]),
});

export const personUnverifiedDataUpdateEndpoint = createEndpoint({
  method: 'post',
  path: '/person/unverified',
  body: personUnverifiedData,
  response: personUnverifiedData,
});

export const personVerificationReason = Type.Union([Type.Literal('imgManipulationScore'), Type.Literal('liveness')]);
export const personVerificationEndpoint = createEndpoint({
  method: 'post',
  path: '/person/verification',
  qs: Type.Object({
    skipVidaRequest: Type.Optional(Type.Boolean()),
  }),
  body: Type.Object({
    selfiePhoto: Base64(),
  }),
  response: Type.Object({
    data: Nullable(
      Type.Object({
        result: Nullable(Type.Boolean()),
        reasons: Type.Array(personVerificationReason),
      }),
    ),
  }),
});

export const personSelfieUploadEndpoint = createEndpoint({
  method: 'post',
  path: '/person/selfie/upload',
  body: Type.Object({
    selfiePhoto: Base64(),
  }),
  response: Type.Void(),
});
