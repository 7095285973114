import { getUrlRegex, isUrlValid, isUrlMatching } from '@getmo/common/utils/url';

export type UrlOptions = {
  strictProtocol: boolean;
};

export const urls = {
  getUrlRegex,
  isValid: isUrlValid,
  isUrlMatching,
};
