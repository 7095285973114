import { Box, Button, Grid } from '@mui/material';
import { Stack } from '@mui/system';
import { Form, Formik } from 'formik';
import { FC, useMemo } from 'react';
import { StepContent } from '../../../components/StepContent/StepContent';
import { StepSubmitButton } from '../../../components/StepSubmitButton/StepSubmitButton';
import { useAppSelector } from '../../../store/store';
import { useNextStep } from '../../hooks/useNextStep';
import Blibli from '@getmo/common/assets/stores/blibli.svg';
import Lazada from '@getmo/common/assets/stores/lazada.svg';
import Shopee from '@getmo/common/assets/stores/shopee.svg';
import Tiktok from '@getmo/common/assets/stores/tiktok.svg';
import Tokopedia from '@getmo/common/assets/stores/tokopedia.svg';
import Website from '@getmo/common/assets/stores/website.svg';
import Instagram from '@getmo/common/assets/stores/instagram.svg';
import { ConnectStoreLink } from './ConnectionStoreLink';
import { StoreIcon } from './StoreIcon';
import { useConnectStoresModal } from './useConnectStoresModal';
import { useConnectionStore } from './useConnectionStore';
import { getShopConnectionType } from '@getmo/common/utils/shops';
import { Shop } from '@getmo/onboarding/shared/endpoints/shops';

const title = [<>Share Your Online Platform</>];
const subtitle = [
  <>
    Enhance your online presence by sharing the links to your e-commerce platform, official website, and social media
    accounts. You can connect your e-commerce & share with us view access to your marketplace data. Rest assured, your
    data&apos;s security is our top priority.
  </>,
];

const ConnectStores: FC = () => {
  const { inputs, addInput } = useConnectionStore();
  const { goToNextStep } = useNextStep();

  const isShopsLoading = useAppSelector(({ shop }) => shop.isLoading);
  const notSyncedShops = useMemo(() => {
    return inputs
      .filter((i) => !!i.shop && !i.shop.isSynced && getShopConnectionType(i.shop.type))
      .map((input) => input.shop as Shop);
  }, [inputs]);

  const hasShops = useMemo(() => {
    return inputs.some((i) => i.shop);
  }, [inputs]);

  const { modal, openModal } = useConnectStoresModal(notSyncedShops, goToNextStep);

  const onSubmit = () => {
    if (notSyncedShops.length || !hasShops) {
      openModal();
      return;
    }

    goToNextStep();
  };
  return (
    <StepContent title={title} subtitle={subtitle} width="extended">
      <Formik initialValues={{}} onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container direction="column" gap={3}>
              <Grid item>
                <Stack display="flex" direction="row" justifyContent="space-around" mb={1}>
                  <StoreIcon src={Tokopedia} title="Tokopedia" />
                  <StoreIcon src={Shopee} title="Shopee" />
                  <StoreIcon src={Lazada} title="Lazada" />
                  <StoreIcon src={Tiktok} title="Tiktok Shop" />
                  <StoreIcon src={Blibli} title="Blibli" />
                  <StoreIcon src={Instagram} title="Instagram" />
                  <StoreIcon src={Website} title="Website" />
                </Stack>
              </Grid>
              <Grid item>
                <Box display="flex" flexDirection="column" rowGap={2}>
                  <Stack rowGap={0}>
                    {inputs.map((input, idx) => {
                      return (
                        <ConnectStoreLink
                          key={`shop-${idx}`}
                          input={input}
                          isLoading={isShopsLoading}
                          isLast={inputs.length === 1}
                        />
                      );
                    })}
                    {inputs.length < 11 && (
                      <Button
                        size="small"
                        disableRipple
                        variant="outlined"
                        onClick={addInput}
                        sx={{
                          maxWidth: 'fit-content',
                        }}
                      >
                        + Add another link
                      </Button>
                    )}
                  </Stack>
                </Box>
              </Grid>
              <Grid item display="flex" justifyContent="center" py={3}>
                <StepSubmitButton disabled={isShopsLoading} />
              </Grid>
              {modal}
            </Grid>
          </Form>
        )}
      </Formik>
    </StepContent>
  );
};

export default ConnectStores;
