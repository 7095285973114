import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const UnderwritingPhBankAccount = OurType.PrismaModel(
  "UnderwritingPhBankAccount",
  {
    id: Type.Number(),
    underwritingId: Type.Number(),
    bankId: Type.Number({ title: "Bank" }),
    accountNumber: Type.String(),
  }
);
