import { useEffect } from 'react';
import { useAppDispatch } from '../../../store/store';
import { summaryAction } from '../../../store/reducers/actions';
import { CircularProgress } from '@mui/material';

const Finish = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(summaryAction.setFinished());
  }, []);

  return <CircularProgress />;
};

export default Finish;
