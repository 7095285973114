import { useCallback } from 'react';
import { tokenStorage } from '../../auth/tokenStorage';
import { summaryThunk } from '../../store/reducers/thunks';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { useNextStep } from './useNextStep';
import * as Sentry from '@sentry/react';
import { ContactType } from '@getmo/onboarding/shared/endpoints/onboarding';

export const useSummary = () => {
  const { goToStep, isLastStep } = useNextStep();
  const { isFinished, isLoaded } = useAppSelector((state) => state.summary);
  const dispatch = useAppDispatch();

  const fetchSummary = useCallback(
    (onFetched?: VoidFunction) => {
      if (isFinished && !isLastStep) {
        tokenStorage.deleteAccess();
        window.location.href = '/';
      }

      if (isLoaded) {
        return;
      }

      dispatch(summaryThunk.get())
        .unwrap()
        .catch((e) => console.error(e))
        .then((summary) => {
          const stepName = summary?.step;
          if (stepName) {
            goToStep(stepName);
          }

          if (summary) {
            Sentry.setUser({
              id: summary.onboardingId,
              username: summary.contactData.find(({ type }) => type === ContactType.Applicant)?.phone ?? '',
            });
          }

          if (onFetched) {
            onFetched();
          }
        });
    },
    [dispatch, isLastStep, isFinished, isLoaded],
  );

  return { fetchSummary };
};
