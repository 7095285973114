import {
  Autocomplete,
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  TextField,
  createFilterOptions,
} from '@mui/material';
import { FieldProps, Field as FormikField } from 'formik';
import { Option } from './FormikSelectInput';
import { useMemo, useState } from 'react';
import { getUniqueId } from './util';

type Props = {
  label?: string;
  name: string;
  options: Option[];
  required?: boolean;
  fullWidth?: boolean;
};

const filter = createFilterOptions<Option>();

const createValue = (value: string) => ({
  label: `Other: ${value}`,
  value: value,
});

const FormikAutocompleteInput = ({ name, label, options, required, fullWidth }: Props) => {
  const uniqueId = useMemo(() => getUniqueId('autocomplete', 'name'), [name]);
  const [inputValue, setInputValue] = useState('');

  return (
    <FormikField name={name}>
      {({ field, meta, form }: FieldProps<Option>) => {
        const error = !!meta.touched && meta.error;

        return (
          <Stack>
            {label && (
              <FormLabel
                htmlFor={uniqueId}
                required={required}
                sx={{
                  mb: '5px',
                  display: 'inline-block',
                  fontSize: '1.1rem',
                  fontWeight: '500',
                }}
              >
                {label}
              </FormLabel>
            )}
            <FormControl error={!!error} fullWidth={fullWidth}>
              <Autocomplete
                disableClearable
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                freeSolo
                options={options}
                key={field.value?.value}
                defaultValue={field.value}
                onChange={(_, newValue) => {
                  if (typeof newValue === 'string') {
                    form.setFieldValue(field.name, createValue(newValue));
                  } else {
                    form.setFieldValue(field.name, newValue);
                  }
                }}
                inputValue={inputValue}
                onInputChange={(_, value) => {
                  setInputValue(value);
                }}
                onBlur={field.onBlur}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;
                  const isExisting = options.some((option) => option.label.includes(inputValue));
                  if (inputValue !== '' && !isExisting) {
                    filtered.push(createValue(inputValue));
                  }
                  return filtered;
                }}
                getOptionLabel={(value) => (value as Option)?.label || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!(meta.touched && meta.error)}
                    autoComplete="off"
                    inputProps={{ ...params.inputProps, 'data-testid': name }}
                  />
                )}
              />
              <FormHelperText>{error || ' '}</FormHelperText>
            </FormControl>
          </Stack>
        );
      }}
    </FormikField>
  );
};

export default FormikAutocompleteInput;
