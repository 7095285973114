import { CSSInterpolation, ThemeOptions } from '@mui/material';

const border: CSSInterpolation = {
  borderRadius: '12px',
};

export const themeSettings: ThemeOptions = {
  palette: {
    primary: {
      main: '#0d66ff',
      light: '#5D87FF',
    },
    text: {
      disabled: '#d3d3d3',
      secondary: '#7C8087',
    },
  },
  typography: {
    allVariants: {
      color: '#1d293f',
      fontFamily:
        "-apple-system, BlinkMacSystemFont, 'Inter', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    },
    h1: {
      fontSize: '3.25rem',
    },
    subtitle1: {
      lineHeight: '1.75rem',
      fontSize: '1.2rem',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          ...border,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          ...border,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        sizeSmall: {
          paddingTop: '5px',
          paddingBottom: '5px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...border,
          paddingTop: '12px',
          paddingBottom: '12px',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        text: {
          fontSize: '0.875rem',
          fontWeight: '600',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
  },
};

export const themeSettingsDanabijak = {
  ...themeSettings,
  palette: {
    ...themeSettings.palette,
    primary: {
      ...themeSettings.palette?.primary,
      main: '#238c86',
      light: '#2dafa7',
    },
  },
};

export const themeSettingsJuragan = {
  ...themeSettings,
  palette: {
    ...themeSettings.palette,
    primary: {
      ...themeSettings.palette?.primary,
      main: '#cc1100',
      light: '#f05554',
    },
  },
};

export const themeSettingsPondoPinoy = {
  ...themeSettings,
  palette: {
    ...themeSettings.palette,
    primary: {
      ...themeSettings.palette?.primary,
      main: '#3d811d',
      light: '#5ac527',
    },
  },
};
