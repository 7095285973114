export enum CurrencyCode {
  Rupiah = 'IDR',
  Dollar = 'USD',
}

interface FormatSettings {
  showCurrency?: boolean;
  isCompact?: boolean;
  currency?: CurrencyCode;
}

export const currency = {
  format: (value: number, formatSettings?: FormatSettings) => {
    const settings: FormatSettings = {
      showCurrency: true,
      isCompact: false,
      currency: CurrencyCode.Rupiah,
      ...formatSettings,
    };

    const currencyOpts: Intl.NumberFormatOptions = {
      style: 'currency',
      currency: settings.currency,
      currencyDisplay: 'narrowSymbol',
    };

    const opts: Intl.NumberFormatOptions = {
      notation: settings.isCompact ? 'compact' : 'standard',
      maximumFractionDigits: 0,
      ...(settings.showCurrency && currencyOpts),
    };

    return Intl.NumberFormat('en-US', opts).format(value);
  },
};
