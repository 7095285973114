import { Type, type StaticDecode } from '@getmo/common/vendor/typebox';
import {
  personUnverifiedDataReadEndpoint,
  personUnverifiedDataUpdateEndpoint,
} from '@getmo/onboarding/shared/endpoints/personVerification';
import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { StepContent } from '../../../../components/StepContent/StepContent';
import { StepSubmitButton } from '../../../../components/StepSubmitButton/StepSubmitButton';
import FormikTextInput from '../../../components/FormikTextInput';
import { useSnackbar } from '../../../components/snackbar/useSnackbar';
import { useApiErrors } from '../../../hooks/useApiErrors';
import { useNextStep } from '../../../hooks/useNextStep';
import { DF } from '@getmo/common/vendor/date-fns';
import { useAppSelector } from '../../../../store/store';
import { callApi } from '../../../../api';

const updateKycBody = Type.Composite([
  Type.Omit(personUnverifiedDataUpdateEndpoint.body, ['dob', 'phPersonalIdType']),
  Type.Object({ dob: Type.String() }),
]);
type Values = StaticDecode<typeof updateKycBody>;

export const KycInfo = () => {
  const summary = useAppSelector(({ summary }) => summary);
  const defaultKtpData = useMemo(
    () => ({
      personalIdNumber: summary.data.kyc.personalIdNumber ?? '',
      fullName: summary.data.kyc.fullName ?? '',
      dob: summary.data.kyc.dob ? DF.format(summary.data.kyc.dob, 'yyyy/MM/dd') : '',
      gender: null,
    }),
    [summary],
  );
  const [ktpData, setKtpData] = useState<Values | null>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { goToNextStep } = useNextStep();

  useEffect(() => {
    const getKyc = async () => {
      const kyc = await callApi(personUnverifiedDataReadEndpoint, {});
      if ('data' in kyc) {
        setKtpData({
          ...kyc.data,
          dob: kyc.data.dob ? DF.format(kyc.data.dob, 'dd-MM-yyyy') : '',
        });
      } else if ('ktpOcrData' in kyc) {
        setKtpData({
          ...kyc.ktpOcrData,
          personalIdNumber: kyc.ktpOcrData.nik,
          dob: kyc.ktpOcrData.dob ? DF.format(kyc.ktpOcrData.dob, 'dd-MM-yyyy') : '',
          gender: null,
        });
      }
    };

    getKyc();
  }, []);

  const title = ktpData ? <>Confirm Information</> : <>Enter Information</>;
  const subtitle = !ktpData ? (
    <>
      We couldn&apos;t recognize the data from the provided document. Please enter your personal data from your KTP
      manually to continue.
    </>
  ) : (
    <> Please check your personal data from your KTP to continue.</>
  );

  const initialValues: Values = {
    ...defaultKtpData,
    ...ktpData,
  };

  const validation = Yup.object({
    personalIdNumber: Yup.string().required().length(16),
    fullName: Yup.string().required(),
    dob: Yup.string()
      .required()
      .test({
        name: 'isDate1',
        message: 'This field is required',
        test: (value) => value.length >= 10,
      })
      .test({
        name: 'isDate2',
        message: 'Not a valid date',
        test: (value) => !isNaN(DF.parse(value, 'dd-MM-yyyy', 0).valueOf()),
      }),
  });

  const { showError } = useSnackbar();
  const onSubmit = async (data: Values) => {
    try {
      setIsSubmitting(true);
      await callApi(personUnverifiedDataUpdateEndpoint, {
        body: {
          ...data,
          dob: DF.parse(data.dob, 'dd-MM-yyyy', 0),
          phPersonalIdType: null,
        },
      });
      goToNextStep();
    } catch (e) {
      const { error } = useApiErrors(e);
      showError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <StepContent title={title} subtitle={subtitle} width="small">
      <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validation}>
        {({ handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Grid container direction="column">
                <Grid item>
                  <FormikTextInput
                    type="text"
                    name="personalIdNumber"
                    label="NIK"
                    required
                    transform={(v) => v.replace(/\D/g, '')}
                  />
                </Grid>
                <Grid item>
                  <FormikTextInput type="text" name="fullName" label="Name" required />
                </Grid>
                <Grid item>
                  <FormikTextInput
                    type="text"
                    name="dob"
                    label="Data of birth"
                    required
                    placeholder="DD-MM-YYYY"
                    transform={(d) => {
                      const parts = d.replace(/\D/g, '').split('');
                      if (parts.length > 2) {
                        parts.splice(2, 0, '-');
                      }
                      if (parts.length > 5) {
                        parts.splice(5, 0, '-');
                      }
                      return parts.slice(0, 10).join('');
                    }}
                  />
                </Grid>
                <Grid item display="flex" justifyContent="center" py={3}>
                  <StepSubmitButton isSubmitting={isSubmitting} />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </StepContent>
  );
};
