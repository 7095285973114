import { $Enums } from '@getmo/common/prisma';
import { Document, DocumentUnionType } from '@getmo/onboarding/shared/endpoints/documents';
import { BankStatement } from '@getmo/onboarding/shared/models/bankStatement';
import { createAction } from '@reduxjs/toolkit';
import { ShopInput } from './shopReducer';
import { Shop } from '@getmo/onboarding/shared/endpoints/shops';

export const updateCompletionByPath = createAction<string>('steps/updateCompletedSteps');

export const updatePhoneNumber = createAction<{ phoneNumber: string; sessionId?: string }>('account/updatePhoneNumber');

export const setAccessToken = createAction<string>('account/setAccessToken');

export const shopAction = {
  addInput: createAction('shop/addInput'),
  deleteInput: createAction<number>('shop/deleteInput'),
  updateInput: createAction<{ input: ShopInput; shop?: Shop }>('shop/linkInput'),
  setIsSyncFetching: createAction<{ id: number; isFetching: boolean }>('shop/setIsSyncFetching'),
  clearSyncLink: createAction<number>('shop/clearSyncLink'),
};

export const summaryAction = {
  setFiles: createAction<{
    type: DocumentUnionType;
    files: Document[];
    bank?: $Enums.Bank;
    coownerId?: number;
    bankStatements?: BankStatement[];
  }>('summary/setFiles'),
  deleteFile: createAction<{ document: Document }>('summary/deleteFile'),
  updateKYCStatus: createAction<$Enums.KycStatus>('summary/updateKycStatus'),
  setFinished: createAction('summary/setFinished'),
};

export const kycAction = {
  decrementAttemps: createAction('kyc/decrementAttemps'),
};
