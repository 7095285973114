import { Button, ButtonOwnProps, SxProps } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { ScrollToError } from '../ScrollToError';

type Props = {
  type?: 'submit' | 'button';
  text?: string;
  onClick?: VoidFunction;
  isSubmitting?: boolean;
  target?: string;
} & ButtonOwnProps;

export const StepSubmitButton = ({
  type = 'submit',
  text = 'Next',
  isSubmitting = false,
  onClick,
  sx,
  ...props
}: Props) => {
  const styles: SxProps = {
    maxWidth: '380px',
    ...(isSubmitting && {
      borderRadius: '165px',
      p: '15px 6px',
      width: '50px',
      height: '50px',
      minWidth: '0',
      transition: '0.3s ease',
      pointerEvents: 'none',
    }),
  };

  return (
    <>
      <Button
        {...props}
        type={type}
        variant="contained"
        color="primary"
        size="large"
        disableRipple
        data-testid={`btn-next`}
        sx={{ ...styles, ...sx }}
        fullWidth
        onClick={onClick}
      >
        {!isSubmitting && text}
        {isSubmitting && (
          <CircularProgress
            size={24}
            sx={{
              color: 'white',
            }}
          />
        )}
      </Button>
      {type === 'submit' && <ScrollToError />}
    </>
  );
};
