import { Prisma } from '@prisma/client';

// eslint-disable-next-line import/export
export type * from '@prisma/client';

// We can not export * here. Probably due to these issues:
// https://github.com/prisma/prisma/issues/21474
// https://github.com/prisma/prisma/issues/5030
export { $Enums, Prisma, PrismaClient } from '@prisma/client';
export const { Decimal } = Prisma;
export type Decimal = Prisma.Decimal;

declare global {
  namespace PrismaJson {}
}
