import { useFormikContext } from 'formik';
import { useEffect } from 'react';

export function ScrollToError() {
  const context = useFormikContext();
  const isSubmitting = context?.isSubmitting;

  useEffect(() => {
    const el = document.querySelector('.Mui-error, [data-error]');
    (el?.parentElement ?? el)?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [isSubmitting]);
  return null;
}
