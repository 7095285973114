import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { DocumentType } from "./DocumentType";
import { Bank } from "./Bank";

export const Document = OurType.PrismaModel("Document", {
  id: Type.Number(),
  createdAt: OurType.DateType(),
  fileName: Type.String(),
  filePath: Type.String(),
  type: DocumentType,
  onboardingId: Type.Number(),
  isDeleted: Type.Boolean({ default: false }),
  isUploadedByClient: Type.Boolean({ default: false }),
  originalId: OurType.Nullable(Type.Number()),
  md5Checksum: Type.String(),
  validationScore: OurType.Nullable(OurType.TypePercent()),
  coownerId: OurType.Nullable(Type.Number()),
  bank: OurType.Nullable(Bank),
  ocrStarted: Type.Boolean({ default: false }),
  ocrDocId: OurType.Nullable(Type.Number()),
  pdfCreator: OurType.Nullable(Type.String()),
  pdfProducer: OurType.Nullable(Type.String()),
});
