import { Box, FormLabel, TextField, TextFieldProps, Typography } from '@mui/material';
import { Field, FieldProps } from 'formik';
import { useMemo } from 'react';
import { IMaskMixin } from 'react-imask';
import { getUniqueId } from './util';

export type Props = {
  name: string;
  mask?: string;
  label?: string;
  subLabel?: string | JSX.Element;
} & Omit<TextFieldProps, 'ref'>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MaskedTextField = IMaskMixin(TextField as any);
const FormikMaskInput = ({ label, subLabel, name, required, ...fieldProps }: Props) => {
  if (!name) {
    throw Error('Input should not be empty');
  }

  const uniqueId = useMemo(() => getUniqueId('mask', name), [name]);

  return (
    <>
      <Field name={name}>
        {({ field, form, meta }: FieldProps<string>) => {
          const error = <>{(!!meta.touched && meta.error) || <>&nbsp;</>}</>;

          return (
            <Box>
              {label && (
                <FormLabel
                  htmlFor={uniqueId}
                  required={required}
                  data-testid={`label-${name}`}
                  sx={{
                    mb: '5px',
                    display: 'inline-block',
                    fontSize: '1.1rem',
                    fontWeight: '500',
                  }}
                >
                  {label}
                </FormLabel>
              )}
              {subLabel && (
                <Typography variant="subtitle2" color="text.secondary" mb={1} pt={0} sx={{ fontStyle: 'italic' }}>
                  {subLabel}
                </Typography>
              )}
              <MaskedTextField
                {...field}
                error={!!(meta.touched && meta.error)}
                onAccept={(e: string) => {
                  form.setFieldValue(name, e);
                }}
                onChange={undefined}
                ref={undefined}
                value={field.value}
                id={uniqueId}
                name={name}
                variant="outlined"
                fullWidth
                sx={{ marginRight: '5px', padding: 0 }}
                helperText={error}
                inputProps={{
                  'data-testid': `input-${name}`,
                }}
                {...fieldProps}
              />
            </Box>
          );
        }}
      </Field>
    </>
  );
};

export default FormikMaskInput;
