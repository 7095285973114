import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const PhCreditHistory = OurType.PrismaModel("PhCreditHistory", {
  id: Type.Number(),
  createdAt: OurType.DateType(),
  score: Type.Number(),
  reasons: Type.Array(Type.String()),
  rawResult: Type.Any(),
  onboardingId: Type.Number(),
});
