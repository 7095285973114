import { formatEnumValue } from '@getmo/common/utils/formats';
import { Document } from '@getmo/onboarding/shared/endpoints/documents';
import { Grid } from '@mui/material';
import { $Enums } from '@prisma/client';
import { createSelector } from '@reduxjs/toolkit';
import { Form, Formik } from 'formik';
import { FC, useState } from 'react';
import * as Yup from 'yup';
import { DocumentType } from '@getmo/onboarding/shared/endpoints/documents';
import { StepContent } from '../../../components/StepContent/StepContent';
import { StepSubmitButton } from '../../../components/StepSubmitButton/StepSubmitButton';
import { summaryAction } from '../../../store/reducers/actions';
import { findDocuments, selectSummaryData } from '../../../store/reducers/summaryReducer';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { yup } from '../../../utils/yup';
import FormikSelectInput from '../../components/FormikSelectInput';
import FormikMaskInput from '../../components/FormikMaskInput';
import { useSnackbar } from '../../components/snackbar/useSnackbar';
import { DocumentFileInput } from '../../components/upload/DocumentFileInput';
import { useApiErrors } from '../../hooks/useApiErrors';
import { useNextStep } from '../../hooks/useNextStep';
import { defaultMimeTypes as defaultMimes } from '../../types/mimeType';
import { DF } from '@getmo/common/vendor/date-fns';
import { PH_PERSONAL_ID_TYPES } from '@getmo/common/utils/phPersonalIdType';
import { ContactType } from '@getmo/onboarding/shared/endpoints/onboarding';
import { summaryThunk } from '../../../store/reducers/thunks';
import { Gender, PhPersonalIdType } from '@getmo/common/shared/models/onboarding';
import { R } from '@getmo/common/vendor/remeda';

const personalIdTypeOptions = R.map(R.values($Enums.PhPersonalIdType), (v) => ({
  label: formatEnumValue(PhPersonalIdType, v),
  value: v,
}));

const genderOptions = R.map(R.values($Enums.Gender), (v) => ({
  label: formatEnumValue(Gender, v),
  value: v,
}));

const selectInitialValues = createSelector([selectSummaryData], (summary): InitialValues => {
  return {
    phPersonalIdType: summary.kyc.phPersonalIdType ?? $Enums.PhPersonalIdType.umid,
    personalIdNumber: summary.kyc.personalIdNumber ?? '',
    dob: summary.kyc.dob ? DF.format(summary.kyc.dob, 'yyyy/MM/dd') : '',
    gender: summary.kyc.gender ?? ('' as never),

    files: {
      personalId: findDocuments(summary, DocumentType.personalId),
    },
  };
});

interface InitialValues {
  phPersonalIdType: $Enums.PhPersonalIdType;
  personalIdNumber: string;
  dob: string;
  gender: $Enums.Gender;
  files: {
    personalId: Document[];
  };
}

const PhIdentityVerification: FC = () => {
  const title = <>Identity Verification</>;
  const subtitle = (
    <>
      Please provide the following essential details to assist us in tailoring the perfect financing solution for your
      business.
    </>
  );

  const { goToNextStep } = useNextStep();
  const initialValues = useAppSelector((state) => selectInitialValues(state));

  const validation = Yup.object({
    phPersonalIdType: Yup.string().required(),
    personalIdNumber: Yup.string()
      .required()
      .test({
        name: 'isValid',
        message: 'Invalid format',
        test: (value, context) => {
          const docType = context.parent.phPersonalIdType as $Enums.PhPersonalIdType;
          return value.length === PH_PERSONAL_ID_TYPES[docType]?.length;
        },
      }),
    dob: Yup.string()
      .required()
      .test({
        name: 'isDate1',
        message: 'This field is required',
        test: (value) => value.length >= 10,
      })
      .test({
        name: 'isDate2',
        message: 'Not a valid date',
        test: (value) => !isNaN(DF.parse(value, 'yyyy/MM/dd', 0).valueOf()),
      })
      .test({
        name: 'isDateOfBirth1',
        message: 'Invalid date of birth',
        test: (value) =>
          DF.parse(value, 'yyyy/MM/dd', 0).valueOf() < Date.now() &&
          DF.differenceInYears(new Date(), DF.parse(value, 'yyyy/MM/dd', 0).valueOf()) <= 110,
      })
      .test({
        name: 'isDateOfBirth2',
        message: 'Unfortunately, we do not provide services to minors',
        test: (value) => DF.differenceInYears(new Date(), DF.parse(value, 'yyyy/MM/dd', 0).valueOf()) >= 18,
      }),
    gender: Yup.string().required(),
    files: Yup.object({
      personalId: Yup.array().min(1, yup.uploadMinFile(1)),
    }),
  });

  const [isSubmitting, setSubmitting] = useState(false);
  const [initialErrors, setInitialErrors] = useState({});
  const { showError } = useSnackbar();
  const fullName = useAppSelector(
    (state) => state.summary.data.contactData.find(({ type }) => type === ContactType.Applicant)?.name ?? '',
  );
  const dispatch = useAppDispatch();

  const onSubmit = async (values: typeof initialValues) => {
    setInitialErrors({});
    setSubmitting(true);

    try {
      await dispatch(
        summaryThunk.updateKyc({
          phPersonalIdType: values.phPersonalIdType,
          personalIdNumber: values.personalIdNumber,
          dob: DF.parse(values.dob, 'yyyy/MM/dd', 0),
          gender: values.gender,
          fullName,
        }),
      ).unwrap();
      dispatch(summaryAction.setFiles({ type: DocumentType.personalId, files: values.files.personalId }));
      await goToNextStep();
    } catch (e) {
      const { error, validationErrors } = useApiErrors(e);
      setInitialErrors(validationErrors);
      if (error) {
        showError(error);
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <StepContent title={title} subtitle={subtitle} width="medium">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        initialErrors={initialErrors}
        validationSchema={validation}
        onSubmit={onSubmit}
      >
        {({ values, handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Grid container direction="column" gap={0} pt={0}>
                <Grid item>
                  <FormikSelectInput
                    name="phPersonalIdType"
                    label="Type of Personal ID"
                    options={personalIdTypeOptions}
                    required
                  />
                </Grid>
                <Grid item sx={{ mb: 1 }}>
                  <FormikMaskInput
                    name="personalIdNumber"
                    type="text"
                    label="ID Number"
                    placeholder={PH_PERSONAL_ID_TYPES[values.phPersonalIdType]?.toUpperCase()}
                    mask={PH_PERSONAL_ID_TYPES[values.phPersonalIdType]}
                    required
                  />
                </Grid>
                <Grid item sx={{ mb: 1 }}>
                  <FormikMaskInput
                    name="dob"
                    type="text"
                    label="Date of birth"
                    placeholder="YYYY/MM/DD"
                    mask="0000/00/00"
                    required
                  />
                </Grid>
                <Grid item>
                  <FormikSelectInput name="gender" label="Sex" options={genderOptions} required />
                </Grid>
                <Grid item>
                  <DocumentFileInput
                    name="files.personalId"
                    label="Picture of Valid ID"
                    subtitle="Please upload a photo or a scan of the valid ID selected in the previous question"
                    docType={DocumentType.personalId}
                    mimeTypes={defaultMimes.personalId}
                    extractImageFromPdf="ktp"
                    required
                  />
                </Grid>
                <Grid item display="flex" justifyContent="center" pt={2}>
                  <StepSubmitButton isSubmitting={isSubmitting} />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </StepContent>
  );
};

export default PhIdentityVerification;
