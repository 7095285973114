import { EmailOutlined } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';

export const InputEmailIcon = () => {
  return (
    <InputAdornment position="start">
      <EmailOutlined />
    </InputAdornment>
  );
};
