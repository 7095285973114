import { Box, Stack, Typography } from '@mui/material';
import { Image } from '../../../components/Image';
import Danabijak from '../../../assets/logos/logoDanabijak.svg';

export const WelcomePartnerSign = () => {
  const text = (
    <>
      Licensed Financial Solutions Partner of <strong>PT Digital Micro Indonesia DanaBijak</strong>, which is licensed &
      supervised
    </>
  );
  const credits = <>by the OJK No KEP-92/D.05/2021</>;

  return (
    <Stack direction="row" gap={3} alignItems="center" maxWidth="600px">
      <Box flexDirection="column" width="100%">
        <Typography variant="body2" textAlign="end">
          {text}
        </Typography>
        <Typography variant="body2" textAlign="end">
          {credits}
        </Typography>
      </Box>
      <Box>
        <Image src={Danabijak} />
      </Box>
    </Stack>
  );
};
