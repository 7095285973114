import { type Path, PathType, type PrismaModel, sortOrder } from './typebox';
import {
  type StaticDecode,
  type TArray,
  type TInteger,
  type TLiteral,
  type TNever,
  type TNumber,
  type TObject,
  type TOptional,
  type TRecord,
  type TSchema,
  type TString,
  type TUnknown,
  Type,
} from '@sinclair/typebox';

export type EndpointResponse<T extends Endpoint<TSchema, TSchema, TSchema, TSchema>> =
  T extends Endpoint<TSchema, TSchema, TSchema, infer R> ? StaticDecode<R> : never;

export type Endpoint<
  ParamsSchema extends TSchema,
  QsSchema extends TSchema,
  BodySchema extends TSchema,
  ResponseSchema extends TSchema,
> = {
  method: 'get' | 'post' | 'patch' | 'put' | 'delete';
  consumes: string;
  anonymous: boolean;
  path: string;
  params: ParamsSchema;
  qs: QsSchema;
  body: BodySchema;
  contentType?: string;
  response: ResponseSchema;
};

export const createEndpoint = <
  QsSchema extends TSchema,
  BodySchema extends TSchema,
  ResponseSchema extends TSchema,
  ParamsSchema extends TSchema = TNever,
>(endpoint: {
  method: 'get' | 'post' | 'patch' | 'put' | 'delete';
  consumes?: string;
  anonymous?: boolean;
  path: string;
  params?: ParamsSchema;
  qs?: QsSchema;
  body?: BodySchema;
  contentType?: string;
  response: ResponseSchema;
}): Endpoint<ParamsSchema, QsSchema, BodySchema, ResponseSchema> => ({
  consumes: 'application/json',
  anonymous: false,
  params: Type.Any() as unknown as ParamsSchema,
  qs: Type.Any() as unknown as QsSchema,
  body: Type.Any() as unknown as BodySchema,
  ...endpoint,
});

export type BaseColumnProperties<T> = {
  slotName?: string;
  field?: Path<T>;
  header?: string;
  formatValue?: (item: T) => string | undefined;
};

export type ListEndpoint<
  FiltersSchema extends TObject = TObject,
  ItemSchema extends PrismaModel = PrismaModel,
  ParamsSchema extends TSchema = TNever,
  AggregationsSchema extends TSchema = TSchema,
> = Endpoint<
  ParamsSchema,
  TObject<
    FiltersSchema['properties'] & {
      page: TOptional<TInteger>;
      sortField: TOptional<TString>; // CustomType<ObjectOnlyPathForSchema<ItemSchema>>>;
      sortOrder: TOptional<typeof sortOrder>;
      pageSize: TOptional<TNumber>;
      include: TOptional<TRecord<TString, TUnknown>>;
      columns: TOptional<TArray<TString>>;
      format: TOptional<TLiteral<'xlsx'>>;
    }
  >,
  TNever,
  TObject<{ count: TNumber; results: TArray<ItemSchema>; aggregations: AggregationsSchema }>
> & {
  filters: FiltersSchema;
  item: ItemSchema;
  aggregations: AggregationsSchema;
  columns: BaseColumnProperties<TSchema>[];
};

export const createListEndpoint = <
  FiltersSchema extends TObject,
  ItemSchema extends PrismaModel,
  ParamsSchema extends TSchema = TNever,
  AggregationsSchema extends TSchema = TNever,
>(opts: {
  anonymous?: boolean;
  path: string;
  params?: ParamsSchema;
  filters: FiltersSchema;
  item: ItemSchema;
  columns?: NoInfer<BaseColumnProperties<StaticDecode<ItemSchema>>>[];
  aggregations?: AggregationsSchema;
}): ListEndpoint<FiltersSchema, ItemSchema, ParamsSchema, AggregationsSchema> =>
  Object.assign(
    createEndpoint({
      method: 'get',
      qs: Type.Composite([
        opts.filters,
        Type.Object({
          page: Type.Optional(Type.Integer()),
          sortField: Type.Optional(PathType(opts.item)),
          sortOrder: Type.Optional(sortOrder),
          pageSize: Type.Optional(Type.Number({ maximum: 1000 })),
          include: Type.Optional(Type.Record(Type.String(), Type.Unknown())),
          columns: Type.Optional(Type.Array(Type.String())),
          format: Type.Optional(Type.Literal('xlsx')),
        }),
      ]),
      response: Type.Object({
        ...(opts.aggregations && { aggregations: opts.aggregations }),
        count: Type.Number(),
        results: Type.Array(opts.item),
      }),
      ...opts,
    }),
    {
      filters: opts.filters,
      item: opts.item,
      aggregations: opts.aggregations as AggregationsSchema,
      columns: (opts.columns ?? []) as unknown as BaseColumnProperties<TSchema>[],
    },
  ) as never;
