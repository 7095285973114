import { createReducer } from '@reduxjs/toolkit';
import { setAccessToken, updatePhoneNumber } from './actions';
import { tokenStorage } from '../../auth/tokenStorage';
import { removeAuthorization, setAuthorization } from '../../api';

interface AccountState {
  phoneNumber: string;
  sessionId?: string;
  token?: string;
}

const initialState: AccountState = {
  phoneNumber: '',
  token: tokenStorage.getAccess(),
};

export default createReducer(initialState, (builder) => {
  builder.addCase(updatePhoneNumber, (state, { payload }) => {
    Object.assign(state, payload);
  });

  builder.addCase(setAccessToken, (state, { payload: accessToken }) => {
    accessToken ? setAuthorization(accessToken) : removeAuthorization();
    tokenStorage.setAccess(accessToken);
    state.token = accessToken;
  });
});
