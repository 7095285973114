import * as OurType from "../../typebox";

export const UnderwritingStatus = OurType.OurEnum({
  new: { title: "New application" },
  revision: {},
  fieldVisit: {},
  underwriting: {},
  creditCommittee: {},
  negotiation: {},
  offerAccepted: {},
  disbursed: {},
  declined: {},
});
