import { BaseVariant, useSnackbar as baseUseSnackbar, OptionsObject } from 'notistack';
import { ReactNode } from 'react';

export const useSnackbar = () => {
  const snackbar = baseUseSnackbar();

  const show = (message: string | ReactNode, variant: BaseVariant, options?: OptionsObject) =>
    snackbar.enqueueSnackbar(message, {
      ...options,
      variant,
    });

  return {
    closeSnackbar: snackbar.closeSnackbar,
    showInfo: (message: string | ReactNode, options?: OptionsObject) => show(message, 'info', options),
    showSuccess: (message: string | ReactNode, options?: OptionsObject) => show(message, 'success', options),
    showWarning: (message: string | ReactNode, options?: OptionsObject) => show(message, 'warning', options),
    showError: (message: string | ReactNode, options?: OptionsObject) => show(message, 'error', options),
  };
};
