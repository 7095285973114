import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const LoanDailyOverdue = OurType.PrismaModel("LoanDailyOverdue", {
  loanId: Type.Number(),
  date: OurType.DateType(),
  dpd: Type.Number(),
  amount: OurType.TypeMoney(),
  principalAmount: OurType.TypeMoney(),
  interestAmount: OurType.TypeMoney(),
  penaltyAmount: OurType.TypeMoney(),
});
