import { bankStatement } from '../models/bankStatement';
import { messageResponse } from './common';
import { createEndpoint } from '@getmo/common/endpoint';
import * as DB from '@getmo/common/prisma/typebox';
import { FileType, Nullable } from '@getmo/common/typebox';
import { type StaticDecode, Type } from '@getmo/common/vendor/typebox';

export const documentType = Type.Union([
  Type.Literal('nib'),
  Type.Literal('npwpPersonal'),
  Type.Literal('npwpCorporate'),
  Type.Literal('ktp'),

  Type.Literal('sk'),
  Type.Literal('saleChannelOwnershipProof'),
  Type.Literal('salesScreenshot'),
  Type.Literal('balance'),

  Type.Literal('trademarkCertificate'),
  Type.Literal('businessPermit'),
  Type.Literal('companyProfile'),
  Type.Literal('customerInvoice3m'),
  Type.Literal('salesData'),

  Type.Literal('accountsReceivableAgingReport'),

  Type.Literal('personalId'),
  Type.Literal('proofOfBilling'),
  Type.Literal('selfie'),
  Type.Literal('storePhoto'),
]);

export type DocumentType = StaticDecode<typeof documentType>;
export const DocumentType = {
  nib: 'nib',
  npwpPersonal: 'npwpPersonal',
  npwpCorporate: 'npwpCorporate',
  ktp: 'ktp',
  sk: 'sk',
  saleChannelOwnershipProof: 'saleChannelOwnershipProof',
  salesScreenshot: 'salesScreenshot',
  balance: 'balance',
  salesData: 'salesData',
  trademarkCertificate: 'trademarkCertificate',
  businessPermit: 'businessPermit',
  companyProfile: 'companyProfile',
  customerInvoice3m: 'customerInvoice3m',
  accountsReceivableAgingReport: 'accountsReceivableAgingReport',
  personalId: 'personalId',
  proofOfBilling: 'proofOfBilling',
  selfie: 'selfie',
  storePhoto: 'storePhoto',
} satisfies Record<DocumentType, DocumentType>;

const documentFile = Type.Object({
  id: Type.Number(),
});

export type DocumentFile = StaticDecode<typeof documentFile>;

export const documentDeleteEndpoint = createEndpoint({
  method: 'patch',
  path: '/user/document/delete/:documentId',
  params: Type.Object({
    documentId: Type.Number(),
  }),
  response: messageResponse,
});

export const bankDocumentType = Type.Union([
  Type.Literal('bankStatementPersonal'),
  Type.Literal('bankStatementCorporate'),
]);
export type BankDocumentType = StaticDecode<typeof bankDocumentType>;
export const BankDocumentType = {
  bankStatementPersonal: 'bankStatementPersonal',
  bankStatementCorporate: 'bankStatementCorporate',
} satisfies Record<BankDocumentType, BankDocumentType>;

export const otherDocumentType = Type.Union([
  Type.Literal('other'),
  Type.Literal('npwp'),
  Type.Literal('ocr'),
  Type.Literal('creditHistory'),
  Type.Literal('loanAgreement'),
  Type.Literal('loanPersonalGuaranteeMainApplicant'),
  Type.Literal('loanSecondPersonalGuarantee'),
  Type.Literal('loanBoardOfCommissionersApproval'),
  Type.Literal('loanCircularResolutionOfShareholders'),
  Type.Literal('loanPostDatedCheques'),
  Type.Literal('loanSiteVisitReport'),
]);

const docUnionType = Type.Union([documentType, bankDocumentType, otherDocumentType]);
export const document = Type.Object({
  id: Type.Number(),
  type: docUnionType,
  filename: Type.String(),
  link: Type.String(),
  coownerId: Type.Optional(Nullable(Type.Number())),
  bankStatements: Type.Optional(Type.Array(bankStatement)),
});
export type Document = StaticDecode<typeof document>;
export type DocumentUnionType = StaticDecode<typeof docUnionType>;

export const documentCreateEndpoint = createEndpoint({
  consumes: 'multipart/form-data',
  contentType: 'multipart/form-data',
  method: 'post',
  path: '/user/data/legal/documents/upload',
  body: Type.Object({
    file: FileType(),
    type: documentType,
    coownerId: Type.Optional(Type.Number()),
  }),
  response: document,
});

export const bankDocumentCreateEndpoint = createEndpoint({
  consumes: 'multipart/form-data',
  contentType: 'multipart/form-data',
  method: 'post',
  path: '/user/data/ocr/:bank/upload',
  params: Type.Object({ bank: DB.Bank }),
  body: Type.Object({
    file: FileType(),
    type: bankDocumentType,
  }),
  response: Type.Composite([
    document,
    Type.Object({
      pdfCreator: Nullable(Type.String()),
      pdfProducer: Nullable(Type.String()),
    }),
  ]),
});
