import { createEndpoint } from '@getmo/common/endpoint';
import { Type } from '@getmo/common/vendor/typebox';

export const feedbackEndpoint = createEndpoint({
  method: 'post',
  path: '/feedback',
  body: Type.Object({
    mark: Type.Integer(),
    text: Type.String(),
  }),
  response: Type.Void(),
});
