import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { summaryAction } from '../../../store/reducers/actions';
import { UploadedBankFile } from '../../../store/reducers/summaryReducer';
import { useAppDispatch } from '../../../store/store';
import { useApiErrors } from '../../hooks/useApiErrors';
import { useSnackbar } from '../snackbar/useSnackbar';
import { DeleteFunction, FileInputOptionProps, UploadFunction, useFileUpload } from './base/useFileUpload';
import { defaultMimeTypes } from '../../types/mimeType';
import { isPdfResaved } from '@getmo/common/utils/bankStatements';
import { callApi } from '../../../api';
import {
  BankDocumentType,
  bankDocumentCreateEndpoint,
  documentDeleteEndpoint,
} from '@getmo/onboarding/shared/endpoints/documents';
import { $Enums } from '.prisma/client';

interface Props extends FileInputOptionProps {
  inputName: string;
  label?: string;
  header?: JSX.Element;
  accountType: BankDocumentType;
}

export const useBankFileInput = ({ inputName, label, header, accountType, ...fileInputOpts }: Props) => {
  const { showError, showWarning, closeSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const upload: UploadFunction = async (file): Promise<UploadedBankFile | null> => {
    try {
      const doc = await callApi(bankDocumentCreateEndpoint, {
        params: { bank: $Enums.Bank.other },
        body: {
          type: accountType,
          file,
        },
      });

      if (!doc.bankStatements?.length && isPdfResaved(doc)) {
        showWarning(
          "You've uploaded a PDF file generated by editing tools\n" +
            'For best results, please provide the original PDF file.',
          {
            autoHideDuration: 15000,
            style: { whiteSpace: 'pre-line' },
            action: (id) => (
              <IconButton size="small" onClick={() => closeSnackbar(id)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            ),
          },
        );
      }

      dispatch(
        summaryAction.setFiles({
          type: accountType,
          files: [doc],
        }),
      );
      return doc;
    } catch (e) {
      const { error } = useApiErrors(e);
      showError(error);

      return null;
    }
  };

  const deleteFile: DeleteFunction = async (id) => {
    try {
      await callApi(documentDeleteEndpoint, { params: { documentId: id } });
    } catch (e) {
      const { error } = useApiErrors(e);
      showError(error);
      throw e;
    }
  };

  return useFileUpload({
    inputName: `${inputName}.${accountType}`,
    upload,
    deleteFile,
    label,
    header,
    mimeTypes: defaultMimeTypes.bank,
    multiple: true,
    maxFiles: 100,
    ...fileInputOpts,
  });
};
