export const useGoogleMapsCreds = () => {
  return window.location.hostname === 'onboarding.pondopinoy.ph'
    ? {
        // Prod creds
        apiKey: 'AIzaSyCoAya66r-4ILb8vCnVNMKZdpCczpa6ASk',
        mapId: '9869680464ae15d0',
      }
    : {
        // Stage creds
        apiKey: 'AIzaSyDWeon3YNR2xG3LiJvnt6lxhjKk9-HLyiQ',
        mapId: '391646a3f619ad84',
      };
};
