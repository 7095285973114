import { useEffect } from 'react';
import { shopAction } from '../../../store/reducers/actions';
import { shopThunk } from '../../../store/reducers/thunks';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { useSnackbar } from '../../components/snackbar/useSnackbar';

export const useConnectionStore = () => {
  const dispatch = useAppDispatch();
  const { shops, inputs } = useAppSelector(({ shop }) => shop);
  const { showSuccess } = useSnackbar();

  const addInput = () => dispatch(shopAction.addInput());
  const getShopList = () => dispatch(shopThunk.getList());
  const updateIsSyncedStatus = () => dispatch(shopThunk.updateIsSynced());

  useEffect(() => {
    getShopList();
  }, []);

  useEffect(() => {
    const refreshInterval = 1000;

    const hasUnlinkedShops = shops.some((shop) => !shop.isSynced);
    const intervalId = hasUnlinkedShops ? setInterval(() => updateIsSyncedStatus(), refreshInterval) : undefined;

    return () => {
      clearInterval(intervalId);
    };
  }, [shops]);

  const shopLinkedKey = 'shopLinked';
  const shopLinkedReceivedKey = 'shopLinkedReceived';
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const saleChannelId = Number(params.get('saleChannelId'));

    if (!Number.isNaN(saleChannelId)) {
      window.addEventListener('storage', ({ key }) => {
        if (key === shopLinkedReceivedKey) {
          window.close();
        }
      });

      localStorage.setItem(shopLinkedKey, String(saleChannelId));
      localStorage.removeItem(shopLinkedKey);
    }
  }, []);

  useEffect(() => {
    const handleStorage = ({ key, newValue }: StorageEvent) => {
      if (key === shopLinkedKey) {
        const saleChannelId = Number(newValue);
        const shop = shops.find((s) => s.id === saleChannelId);

        if (shop && !shop.isSynced) {
          showSuccess(`Shop "${shop.link}" linked`);
        }

        localStorage.setItem(shopLinkedReceivedKey, 'true');
        localStorage.removeItem(shopLinkedReceivedKey);
      }
    };

    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, [shops]);

  return { shops, inputs, addInput };
};
