import { GeoDivision } from '../models/dictionaries';
import { createListEndpoint } from '@getmo/common/endpoint';
import { Nullable } from '@getmo/common/typebox';
import { Type } from '@getmo/common/vendor/typebox';

export const geoDivisionListEndpoint = createListEndpoint({
  anonymous: true,
  path: '/references/geoDivisions',
  item: GeoDivision,
  filters: Type.Object({
    parentCode: Nullable(Type.String()),
  }),
});
