import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { RepaymentScheduleStatus } from "./RepaymentScheduleStatus";

export const RepaymentSchedule = OurType.PrismaModel("RepaymentSchedule", {
  id: Type.Number(),
  loanId: Type.Number(),
  date: OurType.LocalDate(),
  number: Type.Number(),
  amount: OurType.TypeMoney(),
  principalAmount: OurType.TypeMoney(),
  interestAmount: OurType.TypeMoney(),
  penaltyAmount: OurType.TypeMoney(),
  penaltyRebateAmount: OurType.TypeMoney(),
  interestRebateAmount: OurType.TypeMoney(),
  calculatedPenaltyAmount: OurType.TypeMoney(),
  paidAmount: OurType.TypeMoney({ default: 0 }),
  paymentDate: OurType.Nullable(OurType.DateType()),
  status: RepaymentScheduleStatus,
  isHiddenForCollection: Type.Boolean({ default: false }),
});
