import {
  DateGuard,
  EnumGuard,
  guardNullableSchemaValue,
  guardSchemaValue,
  LocalDateGuard,
  LocalDateTimeGuard,
  type Money,
  MoneyGuard,
  type Nullable,
  PercentGuard,
  type SchemaValue,
  unwrapNullable,
  unwrapNullableSchemaValue,
} from '../../typebox';
import { Format } from '../../typebox/formats';
import { currency, type CurrencyFormatOptions } from './currency';
import { phone } from './phone';
import { getUnitBySchema } from './units';
import { DF } from '@getmo/common/vendor/date-fns';
import { R } from '@getmo/common/vendor/remeda';
import {
  type StaticDecode,
  type TEnum,
  type TLiteral,
  type TNumber,
  type TSchema,
  type TString,
  TypeGuard,
} from '@sinclair/typebox';
import { titleCase } from 'scule';

export const Char = {
  NBSP: '\u00A0',
} as const;

export const genLiteralSchemaTitle = (s: TLiteral) =>
  s.title ?? (typeof s.const === 'string' ? titleCase(s.const) : s.const);

export const genFieldTitle = (path: string, schema: TSchema) => {
  let usedSchema = schema;
  if (!schema.title && TypeGuard.IsUnion(schema) && !EnumGuard(schema)) {
    usedSchema = schema.anyOf.find((s) => !!s.title) ?? schema;
  }

  return usedSchema.title ?? titleCase((R.stringToPath(path) as string[]).at(-1) ?? '');
};
export const genFieldTitleWithUnit = (path: string, schema: TSchema) => {
  const title = genFieldTitle(path, schema);
  const unit = getUnitBySchema(schema);
  return unit ? `${title}, ${unit}` : title;
};

export const formatEnumValue = <T extends Record<string, string | number>>(
  schema: TEnum<T>,
  value: StaticDecode<TEnum<T>>,
) => schema.anyOf.find((s) => s.const === value)?.title ?? titleCase(String(value));

export const dateFormat = Intl.DateTimeFormat(undefined, { day: '2-digit', month: 'short', year: 'numeric' });
export const dateTimeFormat = Intl.DateTimeFormat('en-US', {
  hourCycle: 'h23',
  dateStyle: 'medium',
  timeStyle: 'short',
});

const formatString = (schema: TString, value: StaticDecode<TString>): string => {
  switch (schema.format) {
    case Format.FullPhone:
      return phone.format(String(value));
    default:
      return value;
  }
};

export const numberFormatter = Intl.NumberFormat('en-US', { style: 'decimal', useGrouping: 'min2' });
export const percentFormatter = Intl.NumberFormat(undefined, { style: 'percent', maximumFractionDigits: 3 });

const formatNumber = (schemaValue: SchemaValue<TNumber>, options?: CurrencyFormatOptions): string => {
  if (guardSchemaValue(MoneyGuard, schemaValue)) {
    return currency.formatMoney(schemaValue.value, options);
  }

  if (guardSchemaValue(PercentGuard, schemaValue)) {
    return percentFormatter.format(schemaValue.value / 100);
  }

  return numberFormatter.format(schemaValue.value);
};

export type SchemaFormatOptions<T extends TSchema> =
  StaticDecode<T> extends Money | null ? CurrencyFormatOptions : unknown;

export const formatWithSchema = <T extends TSchema>(
  schema: T,
  value: StaticDecode<T>,
  options?: SchemaFormatOptions<T>,
): string | null => {
  const schemaValue: SchemaValue<T> = {
    schema,
    value,
  };

  if (guardNullableSchemaValue(TypeGuard.IsArray, schemaValue)) {
    const itemSchema: TSchema = schemaValue.schema.items;

    return schemaValue.value === null
      ? null
      : schemaValue.value.map((item) => formatWithSchema(itemSchema, item)).join(', ');
  }

  if (guardNullableSchemaValue(EnumGuard, schemaValue)) {
    return schemaValue.value === null
      ? null
      : formatEnumValue(unwrapNullable(schemaValue.schema) as unknown as TEnum, schemaValue.value);
  }

  if (guardNullableSchemaValue(LocalDateGuard, schemaValue)) {
    return schemaValue.value === null ? null : dateFormat.format(schemaValue.value);
  }

  if (guardNullableSchemaValue(DateGuard, schemaValue)) {
    return schemaValue.value === null ? null : dateTimeFormat.format(schemaValue.value);
  }

  if (guardNullableSchemaValue(LocalDateTimeGuard, schemaValue)) {
    return schemaValue.value === null ? null : dateTimeFormat.format(schemaValue.value);
  }

  if (guardNullableSchemaValue(TypeGuard.IsString, schemaValue)) {
    const extractedSchema: TString | Nullable<TString> = schemaValue.schema;
    return schemaValue.value === null ? null : formatString(unwrapNullable(extractedSchema), schemaValue.value);
  }

  if (guardNullableSchemaValue(TypeGuard.IsNumber, schemaValue)) {
    return schemaValue.value === null
      ? null
      : formatNumber(
          unwrapNullableSchemaValue({ schema: schemaValue.schema, value: schemaValue.value }),
          options as Intl.NumberFormatOptions,
        );
  }

  if (guardNullableSchemaValue(TypeGuard.IsBoolean, schemaValue)) {
    return schemaValue.value === false ? 'No' : schemaValue.value ? 'Yes' : null;
  }

  return R.isNonNullish(value) ? String(value) : null;
};

export const formatLocalDatePeriod = (period: readonly [Date, Date]) =>
  `${DF.format(period[0], 'dd MMM')} - ${DF.format(period[1], 'dd MMM yyyy')}`;

export const formatYearMonth = (date: Date) => DF.format(date, 'yyyy-MM');
