export enum MimeType {
  PDF = 'application/pdf',
  PNG = 'image/png',
  JPG = 'image/jpeg',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export const defaultMimeTypes = {
  nib: [MimeType.PDF, MimeType.JPG, MimeType.PNG],
  npwpCorporate: [MimeType.PDF, MimeType.JPG, MimeType.PNG],
  sk: [MimeType.PDF, MimeType.JPG, MimeType.PNG],
  proof: [MimeType.PDF, MimeType.JPG, MimeType.PNG],
  bank: [MimeType.PDF],
  sales: [MimeType.PDF, MimeType.JPG, MimeType.PNG, MimeType.XLSX],
  balance: [MimeType.PDF, MimeType.JPG, MimeType.PNG, MimeType.XLSX],
  invoice: [MimeType.PDF, MimeType.JPG, MimeType.PNG, MimeType.XLSX],
  trademarkCertificate: [MimeType.PDF, MimeType.JPG, MimeType.PNG],
  businessLicense: [MimeType.PDF, MimeType.JPG, MimeType.PNG],
  companyProfile: [MimeType.PDF, MimeType.JPG, MimeType.PNG],
  ktp: [MimeType.PDF, MimeType.JPG, MimeType.PNG],
  npwp_pers: [MimeType.PDF, MimeType.JPG, MimeType.PNG],
  accountsReceivableAgingReport: [MimeType.PDF, MimeType.JPG, MimeType.PNG, MimeType.XLSX],
  personalId: [MimeType.PDF, MimeType.JPG, MimeType.PNG],
  businessPermit: [MimeType.PDF, MimeType.JPG, MimeType.PNG],
  selfie: [MimeType.JPG, MimeType.PNG],
  storePhoto: [MimeType.PDF, MimeType.JPG, MimeType.PNG], // PDF forces Android to propose Camera for photo. Not just open Gallery
};
