export const TermsAndConditionsIndDanabijak = () => {
  return (
    <div>
      <h2>Ketentuan Penggunaan Website</h2>
      <p>
        Selamat datang di danabijak dan terimakasih telah mengunjungi&nbsp;
        <a href="http://www.danabijak.com">http://www.danabijak.com</a>
      </p>
      <p>Kami adalah Perusahaan yang mengoperasikan dan menyediakan layanan melalui website danabijak.</p>
      <p>
        Berikut adalah Syarat Penggunaan, Mengakses website dan layanannya. Syarat dan ketentuan ini mengatur mengenai
        panduan, aturan, kebijakan, pemberitahuan dan instruksi yang berkaitan dengan penggunaan website.
      </p>
      <p>
        Dengan mengakses website atau menggunakan jasa layanannya maka dengan ini Anda menyatakan bahwa Anda telah
        memahami, meyetujui dan terikat oleh Syarat Penggunaan yang berlaku. Kami dapat melakukan perubahan dan
        modifikasi tanpa harus ada pemberitahuan terlebih dahulu.
      </p>
      <p>
        Jika Anda tidak menyetujui Syarat penggunaan maka sebaiknya tidak mengakses website, menggunakan jasa
        layanannya.
      </p>
      <h4>Tentang Kami</h4>
      <p>
        Kami terdaftar sebagai Perusahaan dengan nama PT Digital Micro Indonesia yang berlokasi di Menara DEA Tower II,
        3rd Floor, Unit 02-B &amp; 03-B, Kawasan Mega Kuningan, Jl. Mega Kuningan Barat Kav. E4.3 No. 1-2, Jakarta
        Selatan 12950.
      </p>
      <p>
        Kami adalah Perusahaan Berbadan Hukum yang didirikan secara sah mengikuti ketentuan dan Hukum yang berlaku di
        Negara Republik Indonesia.
      </p>
      <h4>Ketentuan Umum</h4>
      <p>
        Dengan mengakses Situs Web atau menggunakan jasa layanannya maka dengan ini Anda menyatakan bahwa Anda telah
        memahami, menyetujui dan terikat oleh Syarat Penggunaan yang berlaku.
      </p>
      <h4>Panduan Penggunaan</h4>
      <ol>
        <li>
          Dengan mengakses dan menggunakan website maka Pengguna bertanggung jawab secara penuh atas penggunaan situs
          web.
        </li>
        <li>
          Website ini ditujukan untuk individu berumur minimal 22 tahun dan segala bentuk penggunaan website dan layanan
          oleh individu dibawah 22 tahun maka dianggap tidak sah dan kami berhak untuk tidak menyediakan jasa layanan
          website lebih lanjut
        </li>
        <li>Anda tidak diperkenankan menggunakan website untuk kepentingan yang bertentangan dengan hukum</li>
      </ol>
      <h4>Aturan Pengaksesan</h4>
      <ol>
        <li>
          Akses pada website ini diperbolehkan dengan persyaratan sementara dan kami berhak mengubah dan mencabut
          layanan yang kami sediakan tanpa pemberitahuan lebih dulu.
        </li>
        <li>
          Dengan adanya perubahan dalam website atau layanan maka dengan ini Anda akan mengikuti Syarat dan Ketentuan
          yang baru.
        </li>
        <li>
          Anda tidak diijinkan untuk mengubah tampilan situs web, membuat link dan tautan langsung tanpa izin tertulis
          dari kami.
        </li>
        <li>Anda tidak diijinkan melakukan hal-hal lain yang berdampak terhadap situs web.</li>
      </ol>
      <h4>Kebijakan Privasi</h4>
      <ul>
        <li>
          Kami bertanggung jawab, berkomitmen untuk senantiasa melindungi kerahasian dan keamanan bagi Anda. Dalam hal
          ini kami tidak akan menyebarluaskan dan menjual, informasi dan data Anda untuk kepentingan komersial kepada
          pihak lain.
        </li>
        <li>
          Untuk informasi lebih rinci dan lebih lanjut kami sarankan untuk meninjau kebijakan kerahasian kami atau
          menghubungi <a href="mailto:support@danabijak.com">support@danabijak.com</a>.
        </li>
      </ul>
      <h4>Penggunaan Transaksi Elektronik</h4>
      <p>Anda memahami, menyetujui bahwa:</p>
      <ul>
        <li>
          Segala bentuk komunikasi berupa elektronik baik melalui surat elektronik (e-mail) atau yang dipublikasikan
          pada website.
        </li>
        <li>
          Segala bentuk komunikasi dalam bentuk elektronik meliputi namun tidak terbatas untuk menerima dokumen,
          pemberitahuan, perjanjian, persetujuan secara elektronik dan dengan melakukan tanda tangan secara elektronik.
        </li>
        <li>
          Segala bentuk persetujuan yang telah diberikan secara elektronik sepenuhnya menjadi tanggung jawab Anda dan
          mengikat baik hak maupun kewajiban yang timbul atas persetujuan tersebut.
        </li>
        <li>
          Segala bentuk kerusakaan, kehilangan, dan perubahan, materi yang ditimbulkan sehubungan dengan komunikasi
          dalam bentuk elektronik bukan menjadi tanggung jawab kami secara material pada umumnya.
        </li>
      </ul>
      <h4>Pembatalan dan Penghentian</h4>
      <p>
        Jika terjadi pelanggaran yang dilakukan oleh pengguna website terhadap syarat dan ketentuan, maka kami dapat
        secara sepihak tanpa pemberitahuan terlebih dahulu membatalkan, menghapus, membatasi dan menghentikan layanan
        website.
      </p>
      <h4>Hak Kekayaan Intelektual</h4>
      <ul>
        <li>
          Website ini dimiliki oleh PT Digital Micro Indonesia. Keseluruhan hak kekayaan intelektual yang melekat pada
          website ini, termasuk namun tidak terbatas pada hak cipta, merek, logo dan segala sesuatu yang terdaftar
          maupun tidak sepenuhnya milik PT Digital Micro Indonesia dan tanpa batas waktu tertentu.
        </li>
        <li>
          Anda diperbolehkan untuk menyalin, mencetak ulang content yang ada pada website dan menjadi properti dari PT
          Digital Micro Indonesia dan menunjuk pihak ketiga menjadi mitra.
        </li>
      </ul>
      <h4>Batasan Tanggung Jawab</h4>
      <p>
        Dalam hal fungsi sebagai perusahaan yang beroperasi dan menyediakan layanan website hanya memfasilitasi hubungan
        inter-individual antara peminjam dan pemberi pinjaman.
      </p>
      <p>Layanan pelanggan kami pada hari kerja pukul 9:00 hingga 18:00.</p>
      <h4>Peraturan Pemerintah</h4>
      <p>
        Dengan ini menyatakan peminjam tunduk dan terikat persyaratan yang ada pada Syarat dan Ketentuan dan disesuaikan
        dengan Undang-Undang Republik Indonesia.
      </p>
      <p>
        Segala bentuk akibat yang timbul sehubungan dengan penggunaan website akan diselesaikan berdasarkan hukum
        Pengadilan Negeri Jakarta Pusat atau pengadilan negeri daerah lainnya di Indonesia.
      </p>
      <h4>Pernyataan (disclaimer) bagi Pemberi Pinjaman :</h4>
      <ol>
        <li>Bahwa Pemberi Pinjaman sudah mengetahui dan menanggung sepenuhnya risiko atas pemberian pinjaman;</li>
        <li>
          Bahwa tidak ada pihak yang menanggung risiko gagal bayar pinjaman maupun bertanggung jawab atas kerugian
          Penyelenggara, Pemberi Pinjaman dan/atau Penerima Pinjaman dari atau terkait dengan pemberian pinjaman atau
          kesepakatan apapun antara Penyelenggara, Pemberi Pinjaman dan/atau Penerima Pinjaman, kecuali pertanggungan
          asuransi sesuai dengan syarat dan ketentuan yang berlaku;
        </li>
        <li>Bahwa Pemberi Pinjaman bertanggung-jawab atas risiko gagal bayar;</li>
        <li>
          Bahwa Pemberi Pinjaman wajib mempelajari pengetahuan dasar mengenai Layanan Pinjam Meminjam Berbasis Teknologi
          Informasi (“LPMUBTI”) sebelum memberikan pinjaman;
        </li>
        <li>
          Bahwa setiap kecurangan dan tindakan ilegal dilaporkan sepenuhnya kepada Otoritas Jasa Keuangan (“OJK”) dan
          masyarakat luas sesuai peraturan perundang-undangan yang berlaku; dan
        </li>
        <li>Bahwa Pemberi Pinjaman sudah membaca dan mempelajari setiap syarat dan ketentuan.</li>
      </ol>
      <h4>Pernyataan (disclaimer) bagi Penerima Pinjaman :</h4>
      <ol>
        <li>Bahwa Penerima Pinjaman sudah mengetahui dan menanggung sepenuhnya risiko atas penerimaan pinjaman;</li>
        <li>
          Bahwa Penerima Pinjaman sudah mengetahui risiko kehilangan aset ataupun harta kekayaaan akibat gagal bayar;
        </li>
        <li>
          Bahwa Penerima Pinjaman sudah mengetahui, mempertimbangkan dan menyetujui segala tingkat biaya layanan dan
          biaya yang berlaku sesuai dengan kemampuan dalam melunasi pinjaman;
        </li>
        <li>
          Bahwa Penerima Pinjaman diwajibkan untuk mempelajari pengetahuan dasar mengenai LPMUBTI sebelum menerima
          pinjaman;
        </li>
        <li>
          Bahwa setiap kecurangan dan tindakan ilegal dilaporkan sepenuhnya kepada OJK dan masyarakat luas sesuai
          peraturan perundang-undangan yang berlaku;
        </li>
        <li>
          Bahwa catatan pinjaman Penerima Pinjaman akan dilaporkan secara berkala kepada OJK dan/atau Asosiasi Fintech
          Pendanaan Bersama Indonesia untuk kepentingan Pusat Data Fintech Lending atau Fintech Data Center yang akan
          dimanfaatkan bersama dengan para Penyelenggara, para pelaku industri perbankan nasional dan industri keuangan
          lainnya.
        </li>
        <li>Bahwa Penerima Pinjaman sudah membaca dan mempelajari setiap syarat dan ketentuan.</li>
      </ol>
      <h2>Kebijakan Privasi</h2>
      <p>
        Tiap Pengguna dianggap telah menyetujui ketentuan Kebijakan Kerahasiaan termasuk Pengumpulan, Proses,
        Penggunaan, Pembagian&amp;Pemberian dan Penyimpanan informasi data yang dilakukan oleh Kami dengan mengunjungi
        situs danabijak. danabijak bertanggung jawab, berkomitmen untuk senantiasa melindungi kerahasian dan keamanan
        bagi Pengguna Kami. Dalam hal ini kami tidak akan menyebarluaskan dan menjual, informasi, dan data dari Pengguna
        Kami baik yang bersifat pribadi maupun pekerjaan untuk kepentingan komersial seperti data pemasaran kepada pihak
        lain. danabijak senantiasa menjujung tinggi prinsip kerasahaian dan kehati-hatian bagi Pengguna. Sehubungan
        dengan peran kami sebagai penyedia jasa layanan keuangan dengan ini kami berkomitmen untuk selalu mematuhi
        aturan dan hukum yang berlaku di Indonesia.
      </p>
      <p>
        Kami hanya akan membuka atau memberikan informasi kepada pihak ketiga yang memiliki kewenangan yang diatur dalam
        perundangan-undangan di Indonesia. Di sisi lain kami tetap mengutamakan prinsip kehati-hatian dalam menjaga
        kerahasian data Pengguna.
      </p>
      <p>
        <strong>Pengumpulan Informasi</strong> dan Data yang kami peroleh pada saat proses pengajuan pinjaman dengan
        cara mengunjungi Situs Kami akan di pergunakan dalam proses pinjaman seperti data personal,alamat IP, tautan,
        komentar, tinjauan, preferensi pengguna, atau alamat email.
      </p>
      <p>
        <strong>Penggunaan Informasi</strong> dan Data yang tersedia atau diminta oleh Kami akan dibatasi untuk keamanan
        dan perlindungan dari pihak lain.
      </p>
      <p>
        <strong>Pembagian dan Pemberian</strong> Informasi dan Data dapat kami lakukan dalam proses pengajuan pinjaman
        khususnya untuk kegiatan verifikasi, validasi dan konfirmasi data. Hal ini kami berlakukan hanya kepada
        sub-kontraktor kami, penyedia jasa layanan , agen, peminjam anggota dari perusahaan dan pihak berwenang yang
        diatur dalam perundang-undangan Indonesia.
      </p>
      <p>
        Dalam hal terdapat permintaan informasi Anda, kami akan memberitahukan kepada pinjaman terlebih dahulu dan
        pinjaman dapat memberikan tanggapan atau keberatan atas pemberitahuan tersebut. Dalam hal ini jika kami tidak
        menerima tanggapan dari pinjaman dalam kurun 3 hari sejak tanggal pemberitahukan maka kami anggap pinjaman
        setuju.
      </p>
      <p>
        <strong>Penyimpanan informasi</strong> dan data pinjaman disimpan dengan aman dalam system dan Kami senantiasa
        melakukan pemeliharaan dalam system kami secara berkala untuk menjaga dan memastikan sistem keamanan Kami
        terjaga dengan baik dan melebihi standard.
      </p>
    </div>
  );
};

export default TermsAndConditionsIndDanabijak;
