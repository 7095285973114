import { ArrowForward, Refresh } from '@mui/icons-material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { Alert, Box, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { CSSProperties } from 'styled-components';
import { Image } from '../../../../components/Image';
import { StepContent } from '../../../../components/StepContent/StepContent';
import { StepSubmitButton } from '../../../../components/StepSubmitButton/StepSubmitButton';
import { kycAction } from '../../../../store/reducers/actions';
import { useAppDispatch, useAppSelector } from '../../../../store/store';
import { VerifiedBy } from '../../../components/VerificationPoweredBy';
import { useSnackbar } from '../../../components/snackbar/useSnackbar';
import { useApiErrors } from '../../../hooks/useApiErrors';
import { useNextStep } from '../../../hooks/useNextStep';
import { PersonVerificationReason, useVerificationModal } from '../useVerificationModal';
import SelfiePreview from './../../../../assets/kyc-selfie-preview.png';
import Vida from './../../../../assets/vida.svg';
import { useBrandSettings } from '../../../hooks/useBrandSettings';
import { callApi } from '../../../../api';
import { personVerificationEndpoint } from '@getmo/onboarding/shared/endpoints/personVerification';

export const KycSelfie = () => {
  const title = [<>Verify Your Identity</>];
  const subtitle = (
    <>
      To help us process your application efficiently, we ask you for a selfie.
      <br />
      It&apos;s secure and will only take a few minutes.
    </>
  );

  const brandSettings = useBrandSettings();

  const [isStarted, setIsStarted] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selfie, setSelfie] = useState('');
  const [reasons, setReasons] = useState<PersonVerificationReason[]>([]);
  const [isKycPassed, setIsKycPassed] = useState<boolean | null>(null);
  const attempts = useAppSelector((state) => state.kyc.attemps);

  const { goToNextStep, goToPrevStep } = useNextStep();
  const dispatch = useAppDispatch();

  const webcamRef = useRef<Webcam>(null);

  const capture = useCallback(() => {
    setSelfie(webcamRef.current?.getScreenshot() ?? '');
  }, [webcamRef]);

  const refresh = useCallback(() => {
    setIsLoaded(false);
    setSelfie('');
  }, [selfie]);

  const { showError } = useSnackbar();
  const { modal, openModal } = useVerificationModal(reasons, goToPrevStep, refresh);

  const successContent = (
    <Alert severity="success" sx={{ alignItems: 'center', mb: '2rem' }}>
      <Typography variant="body1">We have successfully verified your identity.</Typography>
    </Alert>
  );
  const failedContent = (
    <Alert severity="warning" sx={{ alignItems: 'center', mb: '2rem' }}>
      <Typography variant="body1">We couldn&apos;t verify your identity. You may skip this step for now.</Typography>
    </Alert>
  );

  useEffect(() => {
    setIsSubmitting(false);
    if (attempts <= 0) {
      setIsKycPassed(false);
    } else if (isStarted && isKycPassed === null) {
      openModal();
    }
  }, [attempts]);

  const checkVerification = async () => {
    try {
      setIsSubmitting(true);
      const { data } = await callApi(personVerificationEndpoint, {
        qs: {},
        body: {
          selfiePhoto: selfie.replace(/^data:\w+\/\w+;base64,/, ''),
        },
      });
      if (!data || !data.result) {
        setReasons(data?.reasons ?? []);
        dispatch(kycAction.decrementAttemps());
        return;
      }

      await goToNextStep(); // setIsKycPassed(true);
    } catch (e) {
      const { error } = useApiErrors(e);
      showError(error);
      dispatch(kycAction.decrementAttemps());
    }
  };

  const handleNext = async () => {
    try {
      await goToNextStep();
    } catch (e) {
      const { error } = useApiErrors(e);
      showError(error);
    }
  };

  const Loading = (
    <Box
      width="100%"
      height="100%"
      sx={{ backgroundColor: 'rgba(0,0,0, 0.8)' }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress sx={{ color: 'white' }} size="4rem" thickness={2} />
    </Box>
  );

  const imgStyles: CSSProperties = {
    objectFit: 'cover',
    position: 'absolute',
    inset: '0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  };

  return (
    <StepContent title={title} subtitle={subtitle} width="medium">
      <Grid container direction="column" gap={2} flex={1} alignItems="center">
        {isKycPassed === null && (
          <>
            <Box
              overflow="hidden"
              position="relative"
              left={0}
              top={0}
              width="360px"
              height="360px"
              borderRadius="16px"
              sx={{ backgroundColor: '#363636' }}
            >
              {!isStarted && <Image src={SelfiePreview} />}
              {isStarted && (
                <>
                  {!isLoaded && Loading}
                  <Box
                    visibility={isLoaded ? 'visible' : 'hidden'}
                    display="flex"
                    alignSelf="center"
                    justifyContent="center"
                  >
                    <Box
                      sx={{
                        position: 'relative',
                        overflow: 'hidden',
                        borderRadius: '50%',
                        marginTop: '10px',
                        border: '2px solid #a2a2a2',
                        width: '250px',
                        height: '250px',
                        '&::before': {
                          height: '0px',
                          content: '""',
                          display: 'block',
                          paddingBottom: '100%',
                        },
                      }}
                    >
                      {selfie && <Image src={selfie} sx={imgStyles} />}
                      {!selfie && (
                        <Webcam
                          ref={webcamRef}
                          audio={false}
                          disablePictureInPicture={true}
                          width={360}
                          height={360}
                          screenshotQuality={1}
                          forceScreenshotSourceSize={true}
                          videoConstraints={{ facingMode: 'user', aspectRatio: 1.3333333, width: 640, height: 480 }}
                          imageSmoothing={false}
                          mirrored={true}
                          screenshotFormat="image/jpeg"
                          onUserMedia={() => setIsLoaded(true)}
                          style={imgStyles}
                        />
                      )}
                    </Box>

                    {!selfie && (
                      <IconButton
                        sx={{
                          backgroundColor: 'white',
                          position: 'absolute',
                          left: '154.5px',
                          bottom: '30px',
                          '&:hover': { backgroundColor: 'white' },
                        }}
                        data-testid="btn-capture"
                        onClick={capture}
                      >
                        <PhotoCameraIcon
                          fontSize="large"
                          color="primary"
                          sx={{ '&:hover': { filter: 'brightness(70%)' } }}
                        />
                      </IconButton>
                    )}
                    {!!selfie && (
                      <>
                        <IconButton
                          sx={{
                            backgroundColor: 'white',
                            position: 'absolute',
                            left: '119px',
                            bottom: '30px',
                            '&:hover': { backgroundColor: 'white' },
                          }}
                          data-testid="btn-refresh"
                          onClick={refresh}
                        >
                          <Refresh fontSize="large" color="primary" sx={{ '&:hover': { filter: 'brightness(70%)' } }} />
                        </IconButton>

                        <IconButton
                          sx={{
                            backgroundColor: 'primary.main',
                            position: 'absolute',
                            left: '190px',
                            bottom: '30px',
                            '&:hover': { backgroundColor: 'primary.main', filter: 'brightness(90%)' },
                            '&.Mui-disabled': { backgroundColor: 'action.disabledBackground' },
                          }}
                          disabled={isSubmitting}
                          data-testid="btn-verify"
                          onClick={checkVerification}
                        >
                          {isSubmitting && <CircularProgress size="35px" sx={{ color: 'white' }} />}
                          {!isSubmitting && <ArrowForward fontSize="large" sx={{ color: 'white' }} />}
                        </IconButton>
                      </>
                    )}
                  </Box>
                </>
              )}
            </Box>
            <Typography visibility={isLoaded && !selfie ? 'visible' : 'hidden'}>
              Position your face within the frame
            </Typography>
          </>
        )}
        {isKycPassed && successContent}
        {isKycPassed === false && failedContent}
        <StepSubmitButton
          type="button"
          text={isKycPassed === null ? 'Start' : 'Next'}
          onClick={() => (isKycPassed === null ? setIsStarted(true) : handleNext())}
          sx={{ visibility: !isStarted || isKycPassed !== null ? 'visible' : 'hidden' }}
        />
        {modal}
        {brandSettings.name === 'GetMo' && (
          <Box pt={4}>
            <VerifiedBy text={'Verification Powered by'} src={Vida} isCenter />
          </Box>
        )}
      </Grid>
    </StepContent>
  );
};
