import * as OurType from "../../typebox";

export const GeoDivisionType = OurType.OurEnum({
  region: {},
  province: {},
  city: {},
  municipality: {},
  subMunicipality: {},
  barangay: {},
});
