import { FormControl, FormHelperText, FormLabel, Slider, SliderProps, Typography } from '@mui/material';
import { FieldProps, Field as FormikField } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { getUniqueId } from './util';

type Props = {
  label?: string;
  name: string;
  initialValue: number;
  valueLabel?: (value: number) => string;
  required?: boolean;
} & SliderProps;

const FormikSliderInput = ({ name, label, initialValue, valueLabel, required, ...sliderProps }: Props) => {
  const uniqueId = useMemo(() => getUniqueId('slider', name), [name]);
  const [value, setValue] = useState(initialValue);

  useEffect(() => setValue(initialValue), [initialValue]);

  return (
    <FormikField name={name}>
      {({ field, meta, form }: FieldProps<number>) => {
        const error = !!meta.touched && meta.error;

        return (
          <FormControl error={!!error} fullWidth>
            {label && (
              <FormLabel
                htmlFor={uniqueId}
                required={required}
                data-testid={`label-${name}`}
                sx={{
                  mb: '5px',
                  display: 'inline-block',
                  fontSize: '1.1rem',
                  fontWeight: '500',
                }}
              >
                {label}
              </FormLabel>
            )}
            <Slider
              id={uniqueId}
              name={field.name}
              onChange={(_, value) => setValue(Number(value))}
              onChangeCommitted={(_, value) => {
                form.setFieldValue(field.name, value);
              }}
              value={value}
              valueLabelDisplay="auto"
              {...sliderProps}
              color="primary"
              data-testid={`input-${name}`}
            />
            <FormHelperText>{error}</FormHelperText>
            {valueLabel && <Typography variant="subtitle2">{valueLabel(value)}</Typography>}
          </FormControl>
        );
      }}
    </FormikField>
  );
};

export default FormikSliderInput;
