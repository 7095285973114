import { Box, Checkbox, FormControl, FormHelperText, FormLabel, Stack } from '@mui/material';
import { FieldProps, Field as FormikField } from 'formik';
import { getUniqueId } from './util';
import { useMemo } from 'react';

export type Option = {
  label: string;
  value?: string;
};

type Props = {
  label?: string | JSX.Element;
  name: string;
};

const FormikCheckbox = ({ name, label }: Props) => {
  const uniqueId = useMemo(() => getUniqueId('checkbox', name), [name]);

  return (
    <FormikField name={name}>
      {({ field, meta }: FieldProps<unknown>) => {
        const error = !!meta.touched && meta.error;

        return (
          <FormControl error={!!error}>
            <Stack direction="row" gap={1} alignItems="center">
              <Checkbox {...field} checked={field.value as boolean} id={uniqueId}></Checkbox>
              {label && (
                <FormLabel
                  htmlFor={uniqueId}
                  data-testid={`label-${name}`}
                  sx={{
                    mb: '5px',
                    display: 'inline-block',
                    fontSize: '1.1rem',
                    fontWeight: '500',
                  }}
                >
                  {label}
                </FormLabel>
              )}
            </Stack>
            <Box pl={5}>
              <FormHelperText>{error || ' '}</FormHelperText>
            </Box>
          </FormControl>
        );
      }}
    </FormikField>
  );
};

export default FormikCheckbox;
