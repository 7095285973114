import { Box, Paper, Typography } from '@mui/material';

export const PaperCard = ({ title, children }: { title: string; children: JSX.Element | JSX.Element[] }) => (
  <Box display="flex" flexDirection="column">
    <Typography variant="h5" fontWeight={600} mb={1}>
      {title}
    </Typography>
    <Paper
      elevation={0}
      variant="outlined"
      sx={(theme) => ({
        padding: '0.75rem',
        border: `1.5px solid ${theme.palette.grey}`,
        borderRadius: '0.5rem',
      })}
    >
      {children}
    </Paper>
  </Box>
);
