import { usePhoneCode } from '../onboarding/hooks/useBrandSettings';

export const phone = {
  prettify: (value: string) => {
    const code = usePhoneCode();
    const removedRe = code === '63' ? /^[^9]|[^\d]/g : /^0+|[^\d]/g;
    const phoneNums = value.replace(removedRe, '');
    if (phoneNums.length < 7) return phoneNums;

    const areaCode = phoneNums.slice(0, 3);
    const middlePart = code === '63' ? phoneNums.slice(3, 6) : phoneNums.slice(3, 7);
    const lastPart = code === '63' ? phoneNums.slice(6, 10) : phoneNums.slice(7, 12);

    if (phoneNums.length === 3) {
      return `${areaCode} ${middlePart}`;
    }

    if (phoneNums.length === (code === '63' ? 6 : 7)) {
      return `${areaCode} ${middlePart}`;
    }

    return `${areaCode} ${middlePart}-${lastPart}`;
  },

  depretty: (value: string) => value.replace(/\D/g, '').trim(),

  isValid: (value: string) => {
    const re = usePhoneCode() === '63' ? /^\d{10}$/ : /^\d{9,12}$/;
    return re.test(value);
  },

  toApiValue: <T extends string | null | undefined>(phoneNumber: T): T => {
    if (!phoneNumber) {
      return null as T;
    }
    const phoneCode = usePhoneCode();
    return `+${phoneCode}${phoneNumber.replaceAll(/\D/g, '')}` as T;
  },
};
