import * as OurType from "../../typebox";

export const BankStatementParser = OurType.OurEnum({
  bca: {},
  bcaBlu: {},
  bni: {},
  bniTi: {},
  bniMobile: {},
  bniIb: {},
  bri: {},
  mandiri: {},
  mandiriRk: {},
  mandiriTi: {},
  mandiriPie: {},
  mandiriNew: {},
  seabank: {},
  ocbc: {},
  ocbcCas: {},
  cimbNiaga: {},
});
