import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const JointeffPayment = OurType.PrismaModel("JointeffPayment", {
  id: Type.Number(),
  index: Type.Number(),
  refDate: OurType.DateType(),
  merchantId: Type.String(),
  amount: OurType.TypeMoney(),
  currency: Type.String(),
  description: Type.String(),
  procId: Type.String(),
  category: Type.String(),
  paymentHistoryId: Type.Number(),
});
