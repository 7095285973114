import { ArrowBack } from '@mui/icons-material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Link, MobileStepper, Stack, Typography } from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { updateCompletionByPath } from '../../store/reducers/actions';
import { useAppSelector } from '../../store/store';
import { useThemeQuery } from '../hooks/useThemeQuery';
import { useNextStep } from '../hooks/useNextStep';

const StepperHeader = () => {
  const { steps } = useAppSelector((state) => state.steps);
  const activeStep = useMemo(() => steps.findIndex((step) => !step.completed), [steps]);

  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateCompletionByPath(pathname));
  }, [pathname]);

  const { isSmall, isExtraSmall } = useThemeQuery();
  const { goToPrevStep } = useNextStep();

  if (isSmall) {
    return (
      <>
        <Box
          width={isExtraSmall ? '75px' : '90px'}
          padding={'5px 10px'}
          display="flex"
          alignItems={'center'}
          height={34}
        >
          {activeStep > 0 && <ArrowBack sx={{ fontSize: '30px', cursor: 'pointer' }} onClick={() => goToPrevStep()} />}
        </Box>
        <Stack width="100%" alignItems="center">
          <Typography variant="h6" fontWeight="600">
            {steps[activeStep].label}
          </Typography>
          <MobileStepper
            steps={steps.length}
            activeStep={activeStep}
            position="static"
            sx={{ width: '100%' }}
            variant="progress"
            nextButton={<></>}
            backButton={<></>}
            LinearProgressProps={{ sx: { width: '100%' } }}
          />
        </Stack>
      </>
    );
  }

  return (
    <Box width="100%" display="flex" justifyContent="center" pl="110px">
      <Stepper
        activeStep={activeStep}
        connector={<NavigateNextIcon />}
        sx={{
          display: 'flex',
          padding: '8px 0px',
          rowGap: '1rem',
          flexWrap: 'wrap',
        }}
      >
        {steps.map(({ label, completed, pages }, idx) => {
          const step = (
            <StepLabel>
              <Typography fontWeight={600} fontSize="0.815rem">
                {label}
              </Typography>
            </StepLabel>
          );

          return (
            <Step key={`${label}-${idx}`} completed={completed}>
              {!completed ? (
                step
              ) : (
                <Link component={RouterLink} to={pages[0].path} underline="none">
                  {step}
                </Link>
              )}
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};

export default StepperHeader;
