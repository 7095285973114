import { ClientModel } from '../../typebox';
import * as DB from '@getmo/common/prisma/typebox';

export enum LeadSource {
  Danabijak = 'danabijak',
  Juragan = 'juragan',
}

export { Gender, PhBusinessCategory, PhCity, PhPersonalIdType } from '@getmo/common/prisma/typebox';

export const Person = ClientModel(DB.Person, [
  'id',
  'name',
  'phone',
  'email',
  'dob',
  'gender',
  'photoId',
  'personalIdNumber',
  'phPersonalIdType',
]);

export const Onboarding = ClientModel(DB.Onboarding, [
  'id',
  'region',
  'name',
  'email',
  'phone',
  'createdAt',
  'submittedAt',
  'status',
  'shopName',
  'legalType',
  'typeOfSalesChannel',
  'idBusinessCategory',
  'idCity',
  'yearsBusinessOperation',
  'revenue',
  'loanAmount',
  'loanPurpose',
  'loanDuration',
  'hubspotContactId',
  'outstandingDebt',
  'top5Suppliers',
  'slackReportThreadId',
  'phBusinessCategory',
  'phCity',
  'location',
  'landmark',
  'geoPos',
  'phCheckingAccount',
  'phHasAfs',
  'phIsBusinessOwner',
  'phIsReadyForVisit',
  'qualification',
  'vidaKycStatus',
]);
