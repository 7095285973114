import * as OurType from "../../typebox";

export const IdCity = OurType.OurEnum({
  bali: {},
  bandarLampung: {},
  bandung: {},
  jabodetabek: {},
  malang: {},
  medan: {},
  palembang: {},
  semarang: {},
  surabaya: {},
  surakarta: {},
  yogyakarta: {},
  other: {},
});
