import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

Sentry.init({
  dsn: 'https://91ea78f86a909cfb7265e659dcc89e0f@sentry.getmo.id/3',
  environment:
    process.env.NODE_ENV === 'production' ? (/stage/.test(window.location.hostname) ? 'stage' : 'prod') : 'dev',

  tracesSampleRate: 1,
  normalizeDepth: 6,
  allowUrls: [window.location.origin],
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.extraErrorDataIntegration({ depth: 5 }),
    Sentry.reportingObserverIntegration(),
  ],
});

Sentry.addEventProcessor((ev, hint) => {
  let ex = hint.originalException;
  while (ex instanceof Error) {
    if ('fingerprint' in ex && ex.fingerprint) {
      ev.fingerprint = ex.fingerprint as string[];
      break;
    }
    ex = ex.cause;
  }

  ex = hint.originalException;
  while (ex instanceof Error) {
    if ('level' in ex && ex.level) {
      ev.level = ex.level as Sentry.SeverityLevel;
      break;
    }
    ex = ex.cause;
  }

  return ev;
});
