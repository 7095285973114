import { FormControl, FormHelperText, FormLabel, MenuItem, Select, SelectProps, Typography } from '@mui/material';
import { Field as FormikField, FieldProps } from 'formik';
import { useThemeQuery } from '../hooks/useThemeQuery';
import { useMemo } from 'react';
import { getUniqueId } from './util';

export type Option<TValue extends string = string> = {
  label: string;
  value?: TValue;
};

export const findOption = <TValue extends string>(
  options: Option<TValue>[],
  value: string,
): Option<TValue> | undefined => options.find((o) => o.value === value);

export const findOptions = <TValue extends string>(options: Option<TValue>[], values: string[]): Option<TValue>[] =>
  options.filter((o) => values.find((v) => v === o.value));

export const booleanSelectOptions = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

type Props<TValue extends string> = {
  label?: string;
  subLabel?: string;
  name: string;
  options: Option<TValue>[];
  required?: boolean;
} & Omit<SelectProps<TValue>, 'variant'>;

const FormikSelectInput = <TValue extends string>({
  name,
  label,
  subLabel,
  options,
  required,
  ...selectProps
}: Props<TValue>) => {
  const uniqueId = useMemo(() => getUniqueId('select', name), [name]);

  const { isSmall } = useThemeQuery();

  const opts = isSmall && !selectProps.multiple ? [{ label: '', value: '' }, ...options] : options;

  return (
    <FormikField name={name}>
      {({ field, meta }: FieldProps<TValue>) => {
        const error = !!meta.touched && meta.error;

        return (
          <>
            {label && (
              <FormLabel
                htmlFor={uniqueId}
                required={required}
                data-testid={`label-${name}`}
                sx={{
                  mb: '5px',
                  display: 'inline-block',
                  fontSize: '1.1rem',
                  fontWeight: '500',
                }}
              >
                {label}
              </FormLabel>
            )}
            {subLabel && (
              <Typography variant="subtitle2" color="text.secondary" mb={1} pt={0} sx={{ fontStyle: 'italic' }}>
                {subLabel}
              </Typography>
            )}
            <FormControl error={!!error} fullWidth>
              <Select<TValue>
                {...field}
                {...selectProps}
                native={isSmall}
                placeholder="Select Options"
                error={!!(meta.touched && meta.error)}
                data-testid={`select-${name}`}
              >
                {opts.map(({ value, label }, idx) =>
                  isSmall ? (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ) : (
                    <MenuItem key={`${uniqueId}-item-${idx}`} value={value} sx={{ width: '100%' }}>
                      {label}
                    </MenuItem>
                  ),
                )}
              </Select>
              <FormHelperText>{error || ' '}</FormHelperText>
            </FormControl>
          </>
        );
      }}
    </FormikField>
  );
};

export default FormikSelectInput;
