import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { useState } from 'react';
import { VidaTermsAndConditions } from './termsTexts/VidaTermsAndConditions';

export const useVidaTermsAndConditionsModal = () => {
  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };

  const modal = (
    <Dialog keepMounted open={open} onClose={closeModal} fullWidth={true} maxWidth={'sm'}>
      <DialogContent>
        <VidaTermsAndConditions />
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={closeModal}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  return { modal, openModal, closeModal };
};
