import { Container } from '@mui/material';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import AppHeader from '../components/Header';
import StepperHeader from '../components/StepperHeader';
import { useEffect, useRef } from 'react';

const Wrapper = styled('div')(() => ({
  display: 'flex',
  minHeight: '100vh',
  width: '100%',
}));

const PageWrapper = styled('div')(() => ({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column' as const,
  paddingBottom: '3rem',
  zIndex: 1,
  backgroundColor: 'transparent',
}));

export interface LayoutProps {
  size?: 'large' | 'medium';
}

const StepLayout = ({ size = 'medium' }: LayoutProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (contentRef.current) {
      window.parent.postMessage(['setIframeHeight', contentRef.current.scrollHeight + 250], '*');
    }
  });

  return (
    <Wrapper className={'mainwrapper'}>
      <PageWrapper className="page-wrapper">
        <AppHeader>
          <StepperHeader />
        </AppHeader>
        <Container
          maxWidth={size === 'large' ? 'lg' : size === 'medium' ? 'md' : 'md'}
          sx={{
            minHeight: 'calc(100% - 66px - 3em)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div ref={contentRef}>
            <Outlet />
          </div>
        </Container>
      </PageWrapper>
    </Wrapper>
  );
};

export default StepLayout;
