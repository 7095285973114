import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { useRef, useState } from 'react';

export const useFilePasswordModal = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadingFileName, setUploadingFileName] = useState('');
  const [isFirstTry, setIsFirstTry] = useState(true);

  const resolveRef = useRef<(value: string | null) => void>(() => {});

  const open = (fileName: string, isFirstTry: boolean) => {
    setPassword('');
    setIsFirstTry(isFirstTry);
    setUploadingFileName(fileName);
    setIsModalOpen(true);

    return new Promise<string | null>((resolve) => {
      resolveRef.current = resolve;
    });
  };

  const onConfirm = () => {
    resolveRef.current(password);
    setIsModalOpen(false);
  };

  const onCancel = () => {
    resolveRef.current(null);
    setIsModalOpen(false);
  };

  const modal = (
    <Dialog open={isModalOpen} onClose={onConfirm} maxWidth={'xs'}>
      <DialogTitle textAlign="center">Password protected file</DialogTitle>
      <DialogContent>
        <Typography mb={2}>
          It seems that you are trying to upload a password protected file <strong>{uploadingFileName}</strong>. For us
          to be able to process your document, we kindly ask you to provide the password below.
        </Typography>

        <OutlinedInput
          autoFocus
          type={showPassword ? 'text' : 'password'}
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={!isFirstTry}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              onConfirm();
            }
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword((prev) => !prev)} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </DialogContent>
      <Box mb={1}>
        <DialogActions>
          <Button variant="contained" color="primary" size="small" onClick={onConfirm} disabled={!password}>
            Upload
          </Button>
          <Button size="small" onClick={onCancel}>
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );

  return { modal, open };
};
