import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const UnderwritingPhSalesReport = OurType.PrismaModel(
  "UnderwritingPhSalesReport",
  {
    underwritingId: Type.Number(),
    month: OurType.YearMonth({ title: "Reporting month" }),
    sales: OurType.TypeMoney(),
    cogs: OurType.TypeMoney({ title: "COGS" }),
    opex: OurType.TypeMoney(),
    payroll: OurType.TypeMoney(),
    rentalAndUtilities: OurType.TypeMoney({ title: "Rental & Utilities" }),
    otherExpenses: OurType.TypeMoney(),
  }
);
