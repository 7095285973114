import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const UnderwritingPhAfs = OurType.PrismaModel("UnderwritingPhAfs", {
  id: Type.Number(),
  underwritingId: Type.Number(),
  endingPeriod: OurType.LocalDate(),
  revenue: OurType.Nullable(OurType.TypeMoney()),
  costOfGoodsSold: OurType.Nullable(OurType.TypeMoney()),
  netIncome: OurType.Nullable(
    OurType.TypeMoney({ title: "Net Income / Profit" })
  ),
  cash: OurType.Nullable(OurType.TypeMoney()),
  accountsReceivables: OurType.Nullable(OurType.TypeMoney()),
  inventory: OurType.Nullable(OurType.TypeMoney()),
  totalCurrentAssets: OurType.Nullable(OurType.TypeMoney()),
  totalAssets: OurType.Nullable(OurType.TypeMoney()),
  tradePayables: OurType.Nullable(OurType.TypeMoney()),
  totalShortTermLiabilities: OurType.Nullable(OurType.TypeMoney()),
  totalLiabilities: OurType.Nullable(OurType.TypeMoney()),
});
