import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router';
import { setAuthorization } from '../api';
import { useSummary } from '../onboarding/hooks/useSummary';
import { Path } from '../onboarding/routes/path';
import { AppState, useAppSelector } from '../store/store';

export const AuthGuard = ({ children }: { children: JSX.Element }) => {
  const { token } = useAppSelector((state: AppState) => state.account);
  const location = useLocation();

  if (!token) {
    return <Navigate to={Path.AccountPhoneStarter} state={{ from: location }} replace />;
  }

  setAuthorization(token);
  const { fetchSummary } = useSummary();

  useEffect(() => {
    fetchSummary();
  }, [fetchSummary]);

  return children;
};
