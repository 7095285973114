import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { UnderwritingPhReferenceCheckValidation } from "./UnderwritingPhReferenceCheckValidation";
import { UnderwritingPhReferenceCheckFeedbackQuality } from "./UnderwritingPhReferenceCheckFeedbackQuality";

export const UnderwritingPhReferenceCheck = OurType.PrismaModel(
  "UnderwritingPhReferenceCheck",
  {
    id: Type.Number(),
    underwritingId: Type.Number(),
    name: Type.String(),
    phone: Type.String(),
    email: Type.String(),
    validation: UnderwritingPhReferenceCheckValidation,
    feedback: Type.String(),
    feedbackQuality: UnderwritingPhReferenceCheckFeedbackQuality,
  }
);
