import { Stack, Typography } from '@mui/material';
import { Image } from '../../../components/Image';
import { useThemeQuery } from '../../hooks/useThemeQuery';

import Blibli from '@getmo/common/assets/stores/blibli.svg';
import Facebook from '@getmo/common/assets/stores/facebook.svg';
import Instagram from '@getmo/common/assets/stores/instagram.svg';
import Lazada from '@getmo/common/assets/stores/lazada.svg';
import Shopee from '@getmo/common/assets/stores/shopee.svg';
import Tiktok from '@getmo/common/assets/stores/tiktok.svg';
import Tokopedia from '@getmo/common/assets/stores/tokopedia.svg';
import Twitter from '@getmo/common/assets/stores/twitterx.svg';
import Website from '@getmo/common/assets/stores/website.svg';
import Youtube from '@getmo/common/assets/stores/youtube.svg';
import { $Enums } from '@getmo/common/prisma';

export const SHOP_ICONS: Record<$Enums.ShopType, string> = {
  [$Enums.ShopType.blibli]: Blibli,
  [$Enums.ShopType.facebook]: Facebook,
  [$Enums.ShopType.instagram]: Instagram,
  [$Enums.ShopType.lazada]: Lazada,
  [$Enums.ShopType.shopee]: Shopee,
  [$Enums.ShopType.tiktok]: Tiktok,
  [$Enums.ShopType.tokopedia]: Tokopedia,
  [$Enums.ShopType.twitter]: Twitter,
  [$Enums.ShopType.website]: Website,
  [$Enums.ShopType.youtube]: Youtube,
};

interface StoreIconProps {
  src: string;
  title: string;
}

export const StoreIcon = ({ src, title }: StoreIconProps) => {
  const { isSmall } = useThemeQuery();

  return (
    <Stack direction="column" rowGap={1} alignItems="center" justifyContent="center">
      <Image src={src} />
      {!isSmall && <Typography variant="body1">{title}</Typography>}
    </Stack>
  );
};
