import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { ShopType } from "./ShopType";

export const OnboardingSaleChannel = OurType.PrismaModel(
  "OnboardingSaleChannel",
  {
    id: Type.Number(),
    onboardingId: Type.Number(),
    type: ShopType,
    name: OurType.Nullable(Type.String()),
    link: OurType.Uri(),
    isSynced: Type.Boolean({ default: false }),
    isDataLoaded: Type.Boolean({ default: false }),
  }
);
