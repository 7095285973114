import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const UnderwritingRequestDescriptionData = OurType.PrismaModel(
  "UnderwritingRequestDescriptionData",
  {
    underwritingId: Type.Number(),
    organizationName: OurType.Nullable(
      Type.String({ title: "Name of organization" })
    ),
    npwp: OurType.Nullable(OurType.Npwp({ title: "NPWP" })),
    businessRegistrationDate: OurType.Nullable(
      OurType.LocalDate({ title: "Date of business registration" })
    ),
    generalDirectorAssumptionDate: OurType.Nullable(
      OurType.LocalDate({
        title: "Date of assumption of the position of General Director",
      })
    ),
    foundersJoiningDate: OurType.Nullable(
      OurType.LocalDate({ title: "Date of joining the founders" })
    ),
    founderFirstBusinessRegistrationDate: OurType.Nullable(
      OurType.LocalDate({
        title: "Date of registration of the founder's first business",
      })
    ),
    businessRegistrationPlace: OurType.Nullable(
      Type.String({ title: "Place of business registration" })
    ),
    kbliText: OurType.Nullable(Type.String()),
    affilatedCompanies: OurType.Nullable(
      Type.String({ title: "Affiliated companies" })
    ),
  }
);
