import { Grow } from '@mui/material';
import { SnackbarProvider as BaseSnackbarProvider } from 'notistack';

interface Props {
  children: JSX.Element | null;
}

export const SnackbarProvider = ({ children }: Props) => {
  return (
    <BaseSnackbarProvider
      TransitionComponent={Grow}
      preventDuplicate
      variant="info"
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {children}
    </BaseSnackbarProvider>
  );
};
