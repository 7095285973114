import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const NpwpOcrData = OurType.PrismaModel("NpwpOcrData", {
  documentId: Type.Number(),
  npwp: Type.String(),
  name: Type.String(),
  nik: Type.String(),
  address: Type.String(),
});
