import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { UnderwritingPipeline } from "./UnderwritingPipeline";
import { UnderwritingStatus } from "./UnderwritingStatus";

export const Underwriting = OurType.PrismaModel("Underwriting", {
  id: Type.Number(),
  createdAt: OurType.DateType(),
  updatedAt: OurType.DateType(),
  visitedAt: OurType.Nullable(OurType.DateType()),
  order: Type.Number(),
  pipeline: UnderwritingPipeline,
  status: UnderwritingStatus,
  fieldAgentId: OurType.Nullable(Type.Number()),
  underwriterId: OurType.Nullable(Type.Number()),
  selectedTermsId: OurType.Nullable(Type.Number()),
});
