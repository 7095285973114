import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { NotificationType } from "./NotificationType";
import { MessageStatus } from "./MessageStatus";
import { MessageDirection } from "./MessageDirection";
import { MessageChannel } from "./MessageChannel";

export const Message = OurType.PrismaModel("Message", {
  id: Type.Number(),
  externalId: Type.String(),
  channel: MessageChannel,
  notificationType: OurType.Nullable(NotificationType),
  direction: MessageDirection,
  status: MessageStatus,
  createdAt: OurType.DateType(),
  updatedAt: OurType.DateType(),
  text: Type.String(),
  destination: Type.String(),
  price: OurType.Nullable(OurType.TypeMoney()),
  errorCode: OurType.Nullable(Type.Number()),
  errorMessage: OurType.Nullable(Type.String()),
});
