import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { UnderwritingPhLoanPurposeStatus } from "./UnderwritingPhLoanPurposeStatus";
import { UnderwritingPhInventoryAssets } from "./UnderwritingPhInventoryAssets";
import { UnderwritingPhUnderwriterOpinion } from "./UnderwritingPhUnderwriterOpinion";

export const UnderwritingPhOverall = OurType.PrismaModel(
  "UnderwritingPhOverall",
  {
    underwritingId: Type.Number(),
    operationStart: OurType.Nullable(
      OurType.LocalDate({ title: "Year of operation start" })
    ),
    loanPurpose: OurType.Nullable(Type.String()),
    loanPurposeStatus: OurType.Nullable(UnderwritingPhLoanPurposeStatus),
    inventoryAssets: OurType.Nullable(UnderwritingPhInventoryAssets),
    underwriterOpinion: OurType.Nullable(UnderwritingPhUnderwriterOpinion),
  }
);
