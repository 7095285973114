import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { GeoDivisionType } from "./GeoDivisionType";

export const GeoDivision = OurType.PrismaModel("GeoDivision", {
  code: Type.String(),
  type: GeoDivisionType,
  name: Type.String(),
  parentCode: OurType.Nullable(Type.String()),
});
