import { CoownerContactData } from '@getmo/onboarding/shared/endpoints/onboarding';
import { HighlightOffSharp } from '@mui/icons-material';
import { Box, Button, Card, CardContent, CardHeader, Grid, IconButton, Paper, Typography } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import { FieldArray, Form, Formik } from 'formik';
import { FC, useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { ContactType, EmergencyContactData } from '@getmo/onboarding/shared/endpoints/onboarding';
import { DocumentType } from '@getmo/onboarding/shared/endpoints/documents';
import { StepContent } from '../../../components/StepContent/StepContent';
import { StepSubmitButton } from '../../../components/StepSubmitButton/StepSubmitButton';
import { summaryAction } from '../../../store/reducers/actions';
import {
  findContact,
  findCoownerDocuments,
  findCoowners,
  findDocuments,
  selectSummaryData,
} from '../../../store/reducers/summaryReducer';
import { summaryThunk } from '../../../store/reducers/thunks';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { phone } from '../../../utils/phone';
import { yup } from '../../../utils/yup';
import FormikAutocompleteInput from '../../components/FormikAutocompleteInput';
import { Option } from '../../components/FormikSelectInput';
import FormikTextInput from '../../components/FormikTextInput';
import { InputPhoneIcon } from '../../components/input/icons/InputPhoneIcon';
import { useSnackbar } from '../../components/snackbar/useSnackbar';
import { DocumentFileInput } from '../../components/upload/DocumentFileInput';
import { Document } from '@getmo/onboarding/shared/endpoints/documents';
import { useApiErrors } from '../../hooks/useApiErrors';
import { useNextStep } from '../../hooks/useNextStep';
import { defaultMimeTypes } from '../../types/mimeType';

type CoownerValue = {
  data: CoownerContactData;
  npwp: Document[];
  ktp: Document[];
};
interface InitialValues {
  relation: Option | undefined;
  name: string;
  phone: string;
  files: {
    ktp: Document[];
    npwp: Document[];
  };
  coowners: CoownerValue[];
}

const selectInitialValues = createSelector([selectSummaryData], (summary): InitialValues => {
  const contact = findContact<EmergencyContactData>(summary, ContactType.Emergency);
  const coownerContacts = findCoowners(summary);

  const type = contact ? { label: contact.relation, value: contact.relation } : undefined;

  return {
    name: contact?.name || '',
    phone: contact?.phone.replace(/^\+?62/, '') || '',
    relation: type,
    files: {
      ktp: findDocuments(summary, DocumentType.ktp),
      npwp: findDocuments(summary, DocumentType.npwpPersonal),
    },
    coowners: coownerContacts.map((coowner) => ({
      data: {
        ...coowner,
        phone: coowner.phone.replace(/^\+?62/, '') || '',
      },
      ktp: findCoownerDocuments(summary, DocumentType.ktp, coowner.id),
      npwp: findCoownerDocuments(summary, DocumentType.npwpPersonal, coowner.id),
    })),
  };
});

const selectCoowners = createSelector([selectSummaryData], (summary) => findCoowners(summary));

const selectApplicantPhone = createSelector(
  [selectSummaryData],
  (summary) => summary.contactData.find((c) => c.type === 'applicant')?.phone,
);

const relationOptions: Option[] = [
  { label: 'Spouse', value: 'Spouse' },
  { label: 'Parent', value: 'Parent' },
  { label: 'Sibling', value: 'Sibling' },
  { label: 'Child', value: 'Child' },
  { label: 'Friend', value: 'Friend' },
];

const IdentityVerification: FC = () => {
  const title = <>Identity Verification</>;
  const subtitle = <>For your personal verification, we kindly request the following information.</>;

  const summary = useAppSelector((state) => selectInitialValues(state));
  const applicantPhone = useAppSelector(selectApplicantPhone);
  const [initialValues, setInitialValues] = useState<InitialValues>(summary);
  const isSummaryLoaded = useAppSelector((state) => state.summary.isLoaded);

  useEffect(() => {
    setInitialValues(summary);
  }, [isSummaryLoaded]);

  const validation = Yup.object({
    name: Yup.string().required(),
    phone: yup.validatePhone(),
    relation: Yup.object().required(),
    files: Yup.object({
      ktp: Yup.array().min(1, yup.uploadFile()),
      npwp: Yup.array().min(1, yup.uploadFile()),
    }),
    coowners: Yup.array().of(
      Yup.object({
        data: Yup.object({
          name: Yup.string().required(),
          phone: yup
            .validatePhone()
            .test(
              'matchApplicantPhone',
              "Coowner's phone shouldn't be the same as your phone number. Please do not add yourself as a coowner.",
              (v) => v !== applicantPhone?.replace(/^\+62/, ''),
            )
            .test('uniq', "Coowners' phone numbers must be different.", (v, ctx) => {
              const coowners: CoownerValue[] = ctx.from?.at(-1)?.value?.coowners ?? [];
              const phoneCount = coowners.filter((c) => c.data.phone === v).length;

              return phoneCount <= 1;
            }),
          email: yup.validateEmail(),
        }),
        ktp: Yup.array().min(1, yup.uploadFile()),
        npwp: Yup.array().min(1, yup.uploadFile()),
      }),
    ),
  });

  const [initialErrors, setInitialErrors] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const dispatch = useAppDispatch();
  const { showError } = useSnackbar();
  const existingCoowners = useAppSelector((state) => selectCoowners(state));

  const { goToNextStep } = useNextStep();
  const onSubmit = useCallback(
    async ({ name, phone, relation: type, files, coowners }: InitialValues) => {
      setInitialErrors({});
      setSubmitting(true);

      const data: EmergencyContactData = {
        name,
        phone,
        relation: type?.value || '',
        type: ContactType.Emergency,
      };

      dispatch(summaryThunk.updateEmergencyContactData(data))
        .unwrap()
        .then(() => {
          dispatch(summaryAction.setFiles({ type: DocumentType.ktp, files: files.ktp }));
          dispatch(summaryAction.setFiles({ type: DocumentType.npwpPersonal, files: files.npwp }));
          return goToNextStep();
        })
        .catch((e) => {
          const { error, validationErrors } = useApiErrors(e);
          setInitialErrors(validationErrors);
          if (error) {
            showError(error);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });

      existingCoowners.forEach((existingCoowner) => {
        if (!coowners.some(({ data: coowner }) => coowner.id === existingCoowner.id)) {
          dispatch(summaryThunk.deleteCoowner(existingCoowner));
        }
      });

      const uploadedToId = (file: Document) => file.id;
      coowners.map(({ data: coowner, ktp, npwp }) => {
        dispatch(
          summaryThunk.updateContactData({
            ...coowner,
            documentIds: [...ktp.map(uploadedToId), ...npwp.map(uploadedToId)],
          }),
        )
          .unwrap()
          .then(({ id }) => {
            dispatch(summaryAction.setFiles({ type: DocumentType.ktp, files: ktp, coownerId: id }));
            dispatch(summaryAction.setFiles({ type: DocumentType.npwpPersonal, files: npwp, coownerId: id }));
          });
      });
    },
    [existingCoowners],
  );

  return (
    <StepContent title={title} subtitle={subtitle}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        initialErrors={initialErrors}
        validationSchema={validation}
        onSubmit={onSubmit}
      >
        {({ values, handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Grid container direction="column">
                <DocumentFileInput
                  name="files.ktp"
                  label="KTP photo"
                  docType={DocumentType.ktp}
                  mimeTypes={defaultMimeTypes.ktp}
                  multiple={false}
                  extractImageFromPdf="ktp"
                />
                <DocumentFileInput
                  name="files.npwp"
                  label="NPWP photo"
                  docType={DocumentType.npwpPersonal}
                  mimeTypes={defaultMimeTypes.npwp_pers}
                  multiple={false}
                  extractImageFromPdf="npwp"
                />
                <Grid item>
                  <FormikTextInput name="name" type="text" label="Emergency Contact Full Name" required />
                </Grid>
                <Grid item>
                  <FormikTextInput
                    name="phone"
                    type="text"
                    label="Emergency Contact Phone Number"
                    required
                    transform={(v) => phone.prettify(v)}
                    untransform={(v) => phone.depretty(v)}
                    InputProps={{ startAdornment: <InputPhoneIcon /> }}
                  />
                </Grid>
                <FormikAutocompleteInput
                  name="relation"
                  options={relationOptions}
                  label="Your Relation with Emergency Contact"
                  required
                />
                <Grid item sx={{ backgroundColor: 'info' }} py={2}>
                  <Typography fontSize="1.1rem" fontWeight="500">
                    Multiple Business Owners?{' '}
                  </Typography>
                  <Typography color="text.secondary">
                    Verification is mandatory to all business owner. Please click &apos;Fill Another Owner Data&apos;
                    button below to fill in another owner&apos;s data for identity verification.
                  </Typography>
                </Grid>

                <FieldArray
                  name="coowners"
                  render={({ push, remove }) => (
                    <>
                      {values.coowners.map((_, idx) => {
                        return (
                          <Grid item key={idx}>
                            <Card component={Paper} elevation={0}>
                              <CardHeader
                                titleTypographyProps={{
                                  variant: 'h6',
                                }}
                                sx={{ pr: 1, pl: 0, pb: 2 }}
                                action={
                                  <IconButton onClick={() => remove(idx)}>
                                    <HighlightOffSharp />
                                  </IconButton>
                                }
                                title={`Owner ${idx + 2} Personal Data`}
                              />
                              <CardContent sx={{ p: 0 }}>
                                <Grid item>
                                  <FormikTextInput
                                    type="text"
                                    name={`coowners.${idx}.data.name`}
                                    label="Full Name"
                                    required
                                  />
                                </Grid>
                                <Grid item>
                                  <FormikTextInput type="text" name={`coowners.${idx}.data.role`} label="Role" />
                                </Grid>
                                <FormikTextInput
                                  name={`coowners.${idx}.data.phone`}
                                  type="text"
                                  label="Phone Number"
                                  required
                                  transform={(v) => phone.prettify(v)}
                                  untransform={(v) => phone.depretty(v)}
                                  InputProps={{
                                    startAdornment: <InputPhoneIcon />,
                                  }}
                                />
                                <Grid item>
                                  <FormikTextInput
                                    type="text"
                                    name={`coowners.${idx}.data.email`}
                                    label="Email"
                                    transform={(v) => v.trim()}
                                    placeholder="example@gmail.com"
                                  />
                                </Grid>
                                <Grid item>
                                  <DocumentFileInput
                                    name={`coowners.${idx}.ktp`}
                                    label="KTP photo"
                                    docType={DocumentType.ktp}
                                    mimeTypes={defaultMimeTypes.ktp}
                                    multiple={false}
                                    coownerId={values.coowners[idx].data.id}
                                    extractImageFromPdf="ktp"
                                  />
                                </Grid>

                                <Grid item>
                                  <DocumentFileInput
                                    name={`coowners.${idx}.npwp`}
                                    label="NPWP photo"
                                    docType={DocumentType.npwpPersonal}
                                    mimeTypes={defaultMimeTypes.npwp_pers}
                                    multiple={false}
                                    coownerId={values.coowners[idx].data.id}
                                    extractImageFromPdf="npwp"
                                  />
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        );
                      })}

                      {values.coowners.length < 4 && (
                        <Box pb={2}>
                          <Button
                            size="small"
                            disableRipple
                            variant="outlined"
                            onClick={() => {
                              const emptyCoowner = {
                                name: '',
                                phone: '',
                                email: '',
                                type: ContactType.Coowner,
                              };
                              dispatch(summaryThunk.updateContactData(emptyCoowner))
                                .unwrap()
                                .then((res) =>
                                  push({
                                    data: res,
                                    ktp: [],
                                    npwp: [],
                                  }),
                                );
                            }}
                            sx={{
                              maxWidth: 'fit-content',
                            }}
                          >
                            + Fill Another Owner Data
                          </Button>
                        </Box>
                      )}
                    </>
                  )}
                />

                <Grid item display="flex" justifyContent="center" py={3}>
                  <StepSubmitButton isSubmitting={isSubmitting} />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </StepContent>
  );
};

export default IdentityVerification;
