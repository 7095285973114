import * as OurType from "../../typebox";

export const DocumentType = OurType.OurEnum({
  ktp: { title: "KTP" },
  npwp: { title: "NPWP" },
  npwpPersonal: { title: "Personal NPWP" },
  npwpCorporate: { title: "Corporate NPWP" },
  bankStatementPersonal: { title: "Personal bank statement" },
  bankStatementCorporate: { title: "Corporate bank statement" },
  sk: { title: "SK" },
  trademarkCertificate: {},
  businessPermit: {},
  companyProfile: {},
  customerInvoice3m: { title: "Customer invoice (3 months)" },
  balance: {},
  nib: { title: "NIB" },
  saleChannelOwnershipProof: {},
  salesData: {},
  salesScreenshot: {},
  ocr: { title: "OCR" },
  creditHistory: {},
  accountsReceivableAgingReport: {},
  other: {},
  personalId: {},
  proofOfBilling: {},
  selfie: {},
  storePhoto: {},
  loanAgreement: {},
  loanPersonalGuaranteeMainApplicant: {
    title: "Personal guarantee of main applicant",
  },
  loanSecondPersonalGuarantee: { title: "Second personal guarantee" },
  loanBoardOfCommissionersApproval: { title: "BOC approval" },
  loanCircularResolutionOfShareholders: {
    title: "Circular resolution of shareholders",
  },
  loanPostDatedCheques: { title: "Post dated cheques" },
  loanSiteVisitReport: { title: "Site visit report" },
});
