import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import { FC, useState } from 'react';
import * as Yup from 'yup';
import { StepContent } from '../../../components/StepContent/StepContent';
import { StepSubmitButton } from '../../../components/StepSubmitButton/StepSubmitButton';
import { summaryThunk } from '../../../store/reducers/thunks';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { useSnackbar } from '../../components/snackbar/useSnackbar';
import { useApiErrors } from '../../hooks/useApiErrors';
import { useNextStep } from '../../hooks/useNextStep';
import FormikTextInput from '../../components/FormikTextInput';
import FormikSelectInput, { booleanSelectOptions } from '../../components/FormikSelectInput';

const PhBusinessLocation: FC = () => {
  const title = <>Business Location</>;
  const subtitle = (
    <>Please provide details on locating your store. Rest assured, your information is kept strictly confidential.</>
  );

  const initialValues = useAppSelector((state) => {
    const {
      data: { business },
    } = state.summary;

    return {
      landmark: business.landmark || '',
      phIsReadyForVisit: business.phIsReadyForVisit?.toString() ?? '',
    };
  });

  const [initialErrors, setInitialErrors] = useState({});
  const { showError } = useSnackbar();
  const { goToNextStep } = useNextStep();
  const [isSubmitting, setSubmitting] = useState(false);
  const dispatch = useAppDispatch();

  const validation = Yup.object({
    landmark: Yup.string().required(),
    phIsReadyForVisit: Yup.string().required(),
  });

  const onSubmit = async (values: typeof initialValues) => {
    setInitialErrors({});
    setSubmitting(true);

    const phIsReadyForVisit = values.phIsReadyForVisit === 'true';

    dispatch(
      summaryThunk.updateBusinessData({
        ...values,
        phIsReadyForVisit,
      }),
    )
      .unwrap()
      .then(() => {
        goToNextStep();
      })
      .catch((e) => {
        const { error, validationErrors } = useApiErrors(e);
        setInitialErrors(validationErrors);
        if (error) {
          showError(error);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <StepContent title={title} subtitle={subtitle} width="standard">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        initialErrors={initialErrors}
        validationSchema={validation}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <Grid container direction="column" width="100%">
              <Grid item sx={{ mb: 1 }}>
                <FormikTextInput
                  name="landmark"
                  type="text"
                  label="How to find your store?"
                  subLabel="Please provide instructions and/or landmarks that will help us locate the store"
                  required
                />
              </Grid>

              <Grid item sx={{ mb: 1 }}>
                <FormikSelectInput
                  name="phIsReadyForVisit"
                  label="Are you willing to be visited at your store/home by one of our agents?"
                  options={booleanSelectOptions}
                  required
                />
              </Grid>

              <Grid item display="flex" justifyContent="center" py={3}>
                <StepSubmitButton isSubmitting={isSubmitting} />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </StepContent>
  );
};

export default PhBusinessLocation;
