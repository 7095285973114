import { ChangeEventHandler, ClipboardEventHandler, KeyboardEventHandler, RefObject } from 'react';
import FormikTextInput from './FormikTextInput';
import { useThemeQuery } from '../hooks/useThemeQuery';

interface Props {
  name: string;
  inputRef: RefObject<HTMLInputElement>;
  onChange: ChangeEventHandler;
  onPaste: ClipboardEventHandler;
  onKeyDown: KeyboardEventHandler;
  disabled: boolean;
  autofocus: boolean;
}

const PhoneCodeCell = ({ name, onChange, onPaste, onKeyDown, disabled, autofocus, ...props }: Props) => {
  const { isExtraSmall } = useThemeQuery();
  return (
    <FormikTextInput
      type="tel"
      name={name}
      variant="outlined"
      sx={{
        width: isExtraSmall ? '50px' : '56px',
        minWidth: isExtraSmall ? '50px' : '56px',
        padding: '0',
      }}
      onKeyDown={onKeyDown}
      onChange={onChange}
      onPaste={onPaste}
      autoFocus={autofocus}
      disabled={disabled}
      inputMode="numeric"
      autoComplete="off"
      inputRef={props.inputRef}
      inputProps={{
        sx: {
          height: isExtraSmall ? '50px' : '56px',
          padding: '0',
          fontSize: '1.5rem',
          fontWeight: '600',
          textAlign: 'center',
        },
        maxLength: 1,
      }}
    />
  );
};

export default PhoneCodeCell;
