import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Image } from '../../../components/Image';
import { getShopType } from '@getmo/common/utils/shops';
import { noTranslateClassName } from '../../../utils/weglot';
import { SHOP_ICONS } from './StoreIcon';
import { Shop } from '@getmo/onboarding/shared/endpoints/shops';

export const useConnectStoresModal = (shops: Shop[], goToNextStep: VoidFunction) => {
  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };

  const text = shops.length
    ? 'You may want to connect the following stores:'
    : 'Would you like to return and connect your stores?';

  const modal = (
    <Dialog keepMounted open={open} onClose={closeModal} maxWidth={'sm'}>
      <DialogTitle textAlign="center">Connect your stores</DialogTitle>
      <DialogContent>
        <Alert severity="info" sx={{ mb: '0.5rem' }}>
          You can increase your chances of getting approved for a loan if you connect your stores.
        </Alert>
        <Typography textAlign="center" fontWeight={500}>
          {text}
        </Typography>
        {!!shops.length && (
          <List>
            {shops.map(({ link }, idx) => {
              const shopType = getShopType(link);
              const image = shopType && <Image src={SHOP_ICONS[shopType]} width={30} height={30} />;

              return (
                <ListItem key={idx} sx={{ display: 'flex', alignItems: 'center' }}>
                  <ListItemIcon>{image}</ListItemIcon>
                  <ListItemText className={noTranslateClassName}>{link}</ListItemText>
                </ListItem>
              );
            })}
          </List>
        )}
      </DialogContent>
      <Box>
        <DialogActions>
          <Button variant="contained" color="primary" size="small" onClick={closeModal}>
            Connect
          </Button>
          <Button size="small" onClick={goToNextStep}>
            Skip
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );

  return { modal, openModal, closeModal };
};
