export const TermsAndConditions = () => {
  return (
    <div>
      <center>
        <h2>Privacy Policy</h2>
      </center>
      <h3>Introduction</h3>
      <p>
        Pondo Pinoy Lending Corp. (PPLC) values the confidentiality of personal data and we are committed to protect
        your privacy and we also ensure that all sensitive and confidential information collected from you are acquired,
        stored, and processed in accordance with the safeguards set by Republic Act 10173 otherwise known as the “Data
        Privacy Act of 2012”.As your trusted financing company, the confidentiality of your information is our utmost
        priority. We shall exert all reasonable efforts to protect your information from unauthorized disclosure or
        use.As a potential customer of the company, you are considered a data subject. In relation to this, the company
        will collect personal data from you for it to carry out its business processes and transaction effectively and
        efficiently. Thus, please read this document carefully to ensure informed consent.
      </p>
      <h3>1) What Data will be Collected</h3>
      <p>We will be collecting personal data from you such as, but not limited to, the following:</p>
      <ol>
        <li>Full name</li>
        <li>Email address</li>
        <li>Contact number/s</li>
        <li>Home ownership</li>
        <li>Current and/or permanent addresses</li>
        <li>
          Financial information such as income, expenses, deposits, investments, or/and existing loan from other
          financial institution.
        </li>
        <li>Voice recordings of our conversation with you</li>
        <li>
          Information provided your electronic device you are using such as the IP address, operating system, browser
          type and version, other machine-related identifiers
        </li>
        <li>Business/Entrepreneurial details</li>
        <li>Other documents in support of the business</li>
        <li>Government issued identification cards</li>
        <li>Images via CCTV and other similar recording when visiting any of our offices</li>
        <li>Signatures executed by electronic means or by using wet-ink</li>
        <li>Other information necessary to verify your identity and the transactions.</li>
      </ol>
      <h3>2) How is Data Collected</h3>
      <p>
        Your personal data may be collected, automatically or manually, from you through any of the following means:
      </p>
      <ol>
        <li>
          When you visit our websites, and social media account - If we collect cookies, kindly indicate brief
          description
        </li>
        <li>When you enter into a transaction with us</li>
        <li>When you file complaints, inquires, or requests through any of our recognized platforms.</li>
        <li>When you download and register through our app (_____)</li>
        <li>When you submit or fill-out an application form to avail of any of our products and/or services</li>
        <li>
          When you provide us with information when we conduct background and credit investigation in relation to a
          prospective business relationship with us
        </li>
        <li>Filing complaint, inquires, or request through any of our platforms</li>
        <li>When you submit your personal data to us for any other reason</li>
      </ol>
      <h3>3) Data Storage, Retention, and Disposal</h3>
      <p>
        We store, use, share and disclose your personal data to authorized members of PPLC, as well as third parties in
        accordance with the following purposes and subject to the limitations provided by law:
      </p>
      <ol>
        <li>
          For transaction related acts to provide services or to implement or complete the transactions which you have
          requested, allowed, or authorized
        </li>
        <li>
          For implementation of our internal policies and reportorial obligations of PPLC to government authorities
          under applicable laws.
        </li>
        <li>
          For research or studies for the purpose of reviewing, developing and improving our products and services
        </li>
        <li>
          For promotion purposes regarding services information, including offers, promotions, discounts, rewards of
          PPLC and its Partners; and for personalizing your experience with our various channels such as branches,
          telemarketing, email, messaging and other medium that we may introduce.
        </li>
        <li>Other purposes necessitated by third parties or member of PPLC, such as but not limited to:</li>
        <li>Supports in delivering our services and products to you</li>
        <li>Compliance with existing laws, rules, and legal processes</li>
        <li>
          Implementation of our policies and terms of use including among others, our rights as lender to client
          availing of our loan and other credit products, or such other applicable policies with respect to the products
          and services that we provide
        </li>
        <li>Addressing fraud, security, or technical issues</li>
        <li>Protection of our rights, interests, property or security, and that of our clients and third parties.</li>
      </ol>
      <h3>4) Data Privacy and Protection</h3>
      <p>
        PPLC retains and stores your personal data for a period of five (5) years or until the expiration of this
        retention limit set by law. However, we may retain your data when necessary to establish, exercise, and/or
        defend legal claims, for legitimate business purposes, or when required by law.PPLC assures you that we set up
        necessary security and control measure to ensure the confidentiality, protection, and integrity of your personal
        information.
      </p>
      <h3>5) Your Rights as a Data Subject</h3>
      <p>
        Subject to the provisions, conditions, requirements, and exemption under Data Privacy Act of 2012, its
        implementing rules and regulation and other applicable laws, you are entitled to the following:
      </p>
      <ol>
        <li>To be informed about processes and purposes pertaining to the usage of your personal information.</li>
        <li>To be informed about the recipients of your personal data including their names and addresses</li>
        <li>To be informed when your personal data was last accessed or modified.</li>
        <li>To be furnished you a copy of your personal data in any appropriate format, for further use.</li>
        <li>
          To dispute any error or inaccuracy in your personal data and require us to revise, change or correct your
          affected personal data.
        </li>
        <li>
          To suspend, withdraw or order the blocking, removal, or destruction of his or her personal data from our
          filing system except if:
        </li>
        <li>Your personal data is needed pursuant to a subpoena;</li>
        <li>
          The collection and processing are for obvious purposes, including, when it is necessary for the performance of
          or in relation to a contract or service to which you are a party, or when necessary or desirable in the
          context of an employer-employee relationship between you and PPLC; or
        </li>
        <li>The information is being collected and processed as a result of a legal obligation.</li>
        <li>
          The retention of your personal data is required by applicable laws and regulations to maintain the products
          and services which you have availed.
        </li>
        <li>
          To file a Complaint before the National Privacy Commission for any violation of your rights as data subject in
          accordance with existing laws.
        </li>
      </ol>
      <p>
        For your other rights available under Data Privacy Act of 2012. you may inquire before the National Privacy
        Commission through its website:{' '}
        <a href="http://www.privacy.gov.ph/" target="_blank" rel="noreferrer">
          www.privacy.gov.ph
        </a>
      </p>
      <h3>6) Data Privacy and Protection</h3>
      <p>
        We strictly enforce our Privacy Policies through the implementation of technological, organizational, and
        physical security measures to protect personal data from loss, misuse, unauthorized modification, unauthorized
        or accidental access or disclosure, alteration or destruction.We will not use any of our platforms to obtain
        your mobile application&apos;s One-Time-Password (OTP) including other information such as your bank account
        numbers, passwords, and credit card numbers.Our authorized representative will get in touch with you if we
        require confidential or other types of information. We will never ask you to confirm any such information by
        clicking on a link in an email.
      </p>
      <h3>7) Changes in Data Privacy Statement</h3>
      <p>
        We may amend this Data Privacy Statement depending on the applicable laws and regulations and policies and
        procedures of Fundline Finance Corporation. All updates affecting your personal information will be posted in
        our website.
      </p>
      <h3>8) Data Access and Correction</h3>
      <p>
        We ensure the privacy and protection of your personal data, thus, we request that you likewise ensure that the
        personal data submitted to us is complete, accurate, true, and correct. You have the right to ask for a copy of
        any personal information we hold in relation to you, as well as ask for it to be corrected or updated if you
        think that there is a need to do so.Our decision to provide you access and correction or erasure personal data
        as it appears in our records are always subject to applicable internal policies, relevant laws, and regulations.
      </p>
      <h3>9) Consent</h3>
      <p>
        By proceeding with your registration/application, you hereby signify your consent for us to collect, generate,
        use, process, store, and retain your personal information for the purpose(s) described herein. Please ensure
        that you have completely read and understood the terms above before signing.You likewise authorize use to
        disclose your information to our affiliates and third-party service provides or independent non-affiliated third
        parties, whether local or foreign as necessary for the proper execution of the transaction and or business
        related to the declared purpose and that the use and disclosure is reasonably necessary, required or authorized
        by or under the law.You will be notified of any changes made to this Data Privacy Statement through posting in
        our website, in the app, or via email. The changes will not be applied and will not alter how we handle
        previously collected personal data without obtaining your consent, unless required by law.
      </p>
      <h3>10) Contact Us</h3>
      <p>
        Should you have any concerns or complaints regarding the foregoing, you may reach our Data Privacy Office (DPO)
        through the following contact details: <a href="http://nikita@pondopinoy.ph">nikita@pondopinoy.ph</a>
      </p>
      <p>
        Pondo Pinoy Lending Corp is bound by, and shall comply with, the Data Privacy Act of 2012 (“Republic Act No.
        10173”), other applicable Philippine laws on data privacy, as well as rules and regulations issued by the
        Securities and Exchange Commission, National Privacy Commission other regulatory bodies, that uphold data
        privacy.This Data Privacy Statement has been updated as of November 14 2023
      </p>
    </div>
  );
};

export default TermsAndConditions;
