import { Typography } from '@mui/material';
import { useThemeQuery } from '../../onboarding/hooks/useThemeQuery';

export interface StepContentHeaderProps {
  title: string | JSX.Element | JSX.Element[];
  subtitle?: string | JSX.Element | JSX.Element[];
  subtitleHeading?: string | JSX.Element;
}

export const StepContentHeader = ({ title, subtitle, subtitleHeading }: StepContentHeaderProps) => {
  const { isMedium, isSmall } = useThemeQuery();

  return (
    <>
      {(Array.isArray(title) ? title : Array(title)).map((t, idx) => (
        <Typography
          key={`t-${idx}`}
          variant={isSmall ? 'h5' : isMedium ? 'h4' : 'h3'}
          mb={2}
          textAlign="center"
          fontWeight={700}
        >
          {t}
        </Typography>
      ))}

      {subtitleHeading && (
        <Typography variant="h6" fontWeight={500} mt={4} mb={1}>
          {subtitleHeading}
        </Typography>
      )}
      {(Array.isArray(subtitle) ? subtitle : Array(subtitle)).map((st, idx) => (
        <Typography
          key={`st-${idx}`}
          variant={isMedium ? 'body1' : 'subtitle1'}
          mb={2}
          color="textSecondary"
          textAlign={subtitleHeading ? 'start' : 'center'}
        >
          {st}
        </Typography>
      ))}
    </>
  );
};
