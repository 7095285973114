import React, { ReactNode, useEffect, useState } from 'react';
import { useApiErrors } from '../onboarding/hooks/useApiErrors';
import { useSnackbar } from '../onboarding/components/snackbar/useSnackbar';

interface ErrorBoundaryProps {
  children: ReactNode;
}

const useErrorBoundary = () => {
  const [error, setError] = useState('');

  useEffect(() => {
    const handleErrors = (error: PromiseRejectionEvent) => {
      setError(error.reason);
    };

    window.addEventListener('unhandledrejection', handleErrors);

    return () => {
      window.removeEventListener('unhandledrejection', handleErrors);
    };
  }, []);

  return { error };
};

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  const { showError } = useSnackbar();
  const { error } = useErrorBoundary();

  useEffect(() => {
    if (error) {
      const { error: errorMsg } = useApiErrors(error);
      showError(errorMsg || 'An unexpected error has occurred');
    }
  }, [error]);

  return <>{children}</>;
};

export default ErrorBoundary;
