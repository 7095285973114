import { createReducer } from '@reduxjs/toolkit';
import { shopAction } from './actions';
import { shopThunk } from './thunks';
import { Shop } from '@getmo/onboarding/shared/endpoints/shops';

export interface ShopInput {
  id: number;
  isFetching: boolean;
  syncLink?: string;
  shop?: Shop;
  placeholder: string;
}

interface State {
  shops: Shop[];
  inputs: ShopInput[];
  isLoading: boolean;
  isInitiated: boolean;
}

interface CreateShopInput {
  shop?: Shop;
  placeholder?: string;
}
let shopId = 0;
const createShopInput = ({ shop, placeholder }: CreateShopInput = {}): ShopInput => ({
  id: shopId++,
  isFetching: false,
  shop,
  placeholder: placeholder || 'https://your-website.com/',
});

const placeholders = [
  'https://www.tokopedia.com/your-store-name',
  'https://www.shopee.co.id/your-store-name',
  'https://www.lazada.co.id/your-store-name',
];

const initialState: State = {
  shops: [],
  inputs: placeholders.map((placeholder) => createShopInput({ placeholder })),
  isLoading: true,
  isInitiated: false,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(shopThunk.getList.fulfilled, (state, { payload: shops }) => {
    state.isLoading = false;

    if (!state.isInitiated) {
      state.shops = shops;
      state.inputs = shops.map((shop) => createShopInput({ shop }));

      for (let i = 0; i < 3 - shops.length; i++) {
        state.inputs.push(createShopInput({ placeholder: placeholders[i] }));
      }
      state.isInitiated = true;
      return;
    }
  });

  builder.addCase(shopThunk.updateIsSynced.fulfilled, (state, { payload: shops }) => {
    shops.forEach((shop) => {
      const input = state.inputs.find(({ shop: s }) => s?.id === shop.id);
      if (input && input.shop) {
        input.shop.isSynced = shop.isSynced;
      }
    });
  });

  builder.addCase(shopThunk.create.fulfilled, (state, { payload: shop }) => {
    state.shops.push(shop);
  });

  builder.addCase(shopThunk.update.fulfilled, (state, { payload: shop }) => {
    const index = state.shops.findIndex(({ id }) => id === shop.id);
    if (index >= 0) {
      state.shops[index] = shop;
    }
  });

  builder.addCase(shopThunk.delete.fulfilled, (state, { payload: shopId }) => {
    const index = state.shops.findIndex(({ id }) => id === shopId);
    state.shops.splice(index, 1);
  });

  builder.addCase(shopAction.addInput, (state) => {
    state.inputs.push(createShopInput({ placeholder: placeholders[state.inputs.length] }));
  });

  builder.addCase(shopAction.deleteInput, (state, { payload: inputId }) => {
    const index = state.inputs.findIndex(({ id }) => id === inputId);
    state.inputs.splice(index, 1);
  });

  builder.addCase(shopAction.updateInput, (state, { payload }) => {
    const input = state.inputs.find(({ id }) => id === payload.input.id);
    if (input) {
      input.shop = payload.shop;
    }
  });

  builder.addCase(shopAction.setIsSyncFetching, (state, { payload }) => {
    const input = state.inputs.find(({ shop }) => shop?.id === payload.id);
    if (input) {
      input.isFetching = payload.isFetching;
    }
  });

  builder.addCase(shopAction.clearSyncLink, (state, { payload: shopId }) => {
    const input = state.inputs.find(({ shop }) => shop?.id === shopId);
    if (input) {
      input.syncLink = undefined;
    }
  });

  builder.addCase(shopThunk.getSyncLink.fulfilled, (state, { payload: { id, syncLink } }) => {
    const input = state.inputs.find(({ shop }) => shop?.id === id);
    if (input) {
      input.syncLink = syncLink;
    }
  });
});
