import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector, useStore } from 'react-redux';
import { combineReducers } from 'redux';
import thunk from 'redux-thunk';
import accountReducer from './reducers/accountReducer';
import dictionariesReducer from './reducers/dictionariesReducer';
import loanReducer from './reducers/loanReducer';
import shopReducer from './reducers/shopReducer';
import stepsReducer from './reducers/stepsReducer';
import summaryReducer from './reducers/summaryReducer';
import kycReducer from './reducers/kycReducer';

const rootReducer = combineReducers({
  steps: stepsReducer,
  account: accountReducer,
  shop: shopReducer,
  loanPreview: loanReducer,
  summary: summaryReducer,
  kyc: kycReducer,
  dictionaries: dictionariesReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(thunk),
});

export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
export const useAppStore = useStore<AppState>;
