import { messageResponse } from './common';
import { createEndpoint } from '@getmo/common/endpoint';
import { type StaticDecode, Type } from '@getmo/common/vendor/typebox';

const financialData = Type.Object({
  topSuppliers: Type.String(),
  outstandingDebt: Type.Optional(Type.String()),
});
export type FinancialData = StaticDecode<typeof financialData>;

export const financialSaveEndpoint = createEndpoint({
  method: 'post',
  path: '/user/data/financial/save',
  body: financialData,
  response: messageResponse,
});
