import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const RepaymentAssignment = OurType.PrismaModel("RepaymentAssignment", {
  repaymentId: Type.Number(),
  loanId: Type.Number(),
  repaymentScheduleDate: OurType.LocalDate(),
  principalAmount: OurType.TypeMoney(),
  interestAmount: OurType.TypeMoney(),
  penaltyAmount: OurType.TypeMoney(),
  amount: OurType.TypeMoney(),
});
