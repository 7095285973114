import { Box, FormLabel, TextField, TextFieldProps, Typography } from '@mui/material';
import { Field, FieldProps } from 'formik';
import { useMemo } from 'react';
import { number } from '../../utils/number';
import { getUniqueId } from './util';

type Props = {
  name: string;
  label?: string;
  subLabel?: string | JSX.Element;
  isNumeric?: boolean;
  transform?: (value: string) => string;
  untransform?: (value: string) => string;
} & TextFieldProps;

const FormikTextInput = ({
  label,
  subLabel,
  name,
  onChange,
  isNumeric,
  required,
  transform = (value) => value,
  untransform = (value) => value,
  ...fieldProps
}: Props) => {
  if (!name) {
    throw Error('Input should not be empty');
  }

  const uniqueId = useMemo(() => getUniqueId('text', name), [name]);

  return (
    <>
      <Field name={name}>
        {({ field, meta, form }: FieldProps<string>) => {
          const error = <>{(!!meta.touched && meta.error) || <>&nbsp;</>}</>;

          return (
            <Box>
              {label && (
                <FormLabel
                  htmlFor={uniqueId}
                  required={required}
                  data-testid={`label-${name}`}
                  sx={{
                    mb: '5px',
                    display: 'inline-block',
                    fontSize: '1.1rem',
                    fontWeight: '500',
                  }}
                >
                  {label}
                </FormLabel>
              )}
              {subLabel && (
                <Typography variant="subtitle2" color="text.secondary" mb={1} pt={0} sx={{ fontStyle: 'italic' }}>
                  {subLabel}
                </Typography>
              )}
              <TextField
                {...field}
                error={!!(meta.touched && meta.error)}
                onChange={(e) => {
                  Object.assign(e.target, {
                    value: untransform(transform(e.target.value)),
                  });

                  field.onChange(e);
                  if (isNumeric) {
                    form.setFieldValue(name, String(parseInt(number.filterStr(e.target.value), 10) || ''));
                    return;
                  }
                  onChange && onChange(e);
                }}
                value={transform(String(field.value || ''))}
                id={uniqueId}
                name={name}
                variant="outlined"
                fullWidth
                sx={{ marginRight: '5px', padding: 0 }}
                helperText={error}
                inputProps={{
                  maxLength: isNumeric ? '15' : '',
                  'data-testid': `input-${name}`,
                }}
                {...fieldProps}
              />
            </Box>
          );
        }}
      </Field>
    </>
  );
};

export default FormikTextInput;
