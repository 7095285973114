import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { Region } from "./Region";
import { PhCity } from "./PhCity";
import { UserRole } from "./UserRole";
import { EmployeeTag } from "./EmployeeTag";
import { UnderwritingPipeline } from "./UnderwritingPipeline";

export const Employee = OurType.PrismaModel("Employee", {
  id: Type.Number(),
  isActive: Type.Boolean({ default: true }),
  tags: Type.Array(EmployeeTag),
  email: OurType.Nullable(Type.String()),
  phone: OurType.Nullable(Type.String()),
  name: OurType.Nullable(Type.String()),
  role: UserRole,
  region: OurType.Nullable(Region),
  pipelines: Type.Array(UnderwritingPipeline),
  phCities: Type.Array(PhCity),
  hubspotId: OurType.Nullable(Type.String()),
});
