import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { ProductType } from "./ProductType";
import { PaymentFrequency } from "./PaymentFrequency";

export const TermsSuggestion = OurType.PrismaModel("TermsSuggestion", {
  id: Type.Number(),
  underwritingId: Type.Number(),
  date: OurType.DateType(),
  productType: ProductType,
  rate: OurType.TypePercent(),
  amount: OurType.TypeMoney(),
  term: Type.Number(),
  originationFee: OurType.TypePercent(),
  paymentFrequency: PaymentFrequency,
  holdback: OurType.TypePercent(),
  minPayment: OurType.TypeMoney(),
  comment: OurType.Multiline({ title: "Pre-disbursement conditions" }),
  nplRate: OurType.TypePercent({ title: "NPL rate", default: 5 }),
  pdcAccount: OurType.Nullable(Type.String({ title: "Account for PDC" })),
  employeeId: Type.Number(),
});
