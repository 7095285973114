import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const DisbursementState = OurType.PrismaModel("DisbursementState", {
  underwritingId: Type.Number(),
  destAccountType: Type.String({ default: "" }),
  destAccountNumber: Type.String({ default: "" }),
  plannedDate: OurType.LocalDate(),
  slackThreadId: OurType.Nullable(Type.String()),
  loanAgreementNumber: OurType.Nullable(Type.String()),
  signature: OurType.Nullable(Type.String()),
  disbursementRequested: Type.Boolean({ default: false }),
  applicantDateOfBirth: OurType.Nullable(OurType.LocalDate()),
});
