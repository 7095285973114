import * as OurType from "../../typebox";

export const SaleChannelType = OurType.OurEnum(
  {
    offline: {},
    online: {},
    OnlineOffline: { title: "Online&Offline" },
  },
  { title: "Type of sales channel" }
);
