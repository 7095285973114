import * as OurType from "../../typebox";

export const SaleChannel = OurType.OurEnum({
  shopee: {},
  tokopedia: {},
  tikTok: {},
  lazada: {},
  blibli: {},
  zalora: {},
  b2b: {},
  offline: {},
  reseller: {},
  others: {},
});
