import { R } from '../../vendor/remeda';
import { type StaticDecode, Type } from '../../vendor/typebox';

export const UTM_KEYS = ['source', 'campaign', 'medium', 'term', 'content'] as const;

export const UtmKey = Type.Union(R.map(UTM_KEYS, (k) => Type.Literal(k)));

export const Utm = Type.Partial(Type.Record(UtmKey, Type.String()));
export type Utm = StaticDecode<typeof Utm>;

export const UtmSourceFieldSales = 'fieldSales';
