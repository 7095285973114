import { CoownerContactData } from '@getmo/onboarding/shared/endpoints/onboarding';
import { createReducer } from '@reduxjs/toolkit';
import { ContactType, ContactData, EmergencyContactData, Summary } from '@getmo/onboarding/shared/endpoints/onboarding';
import { BankDocumentType, DocumentType } from '@getmo/onboarding/shared/endpoints/documents';
import { AppState } from '../store';
import { summaryAction } from './actions';
import { UpdateContactData, summaryThunk } from './thunks';
import { $Enums } from '@getmo/common/prisma';
import { bankDocumentCreateEndpoint, Document } from '@getmo/onboarding/shared/endpoints/documents';
import { StaticDecode } from '@sinclair/typebox';

const isCoownerContact = (contact: ContactData): contact is CoownerContactData => {
  return contact.type === ContactType.Coowner;
};

const updateContactData = (state: State, data: UpdateContactData) => {
  const contact = isCoownerContact(data) ? findCoowner(state.data, data.id) : findContact(state.data, data.type);

  if (contact) {
    Object.assign(contact, data);
    return;
  }

  state.data.contactData.push(data);
};

interface State {
  data: Summary;
  isLoading: boolean;
  isLoaded: boolean;
  isFinished: boolean;
}

const initialState: State = {
  isLoading: true,
  isLoaded: false,
  isFinished: false,
  data: {
    onboardingId: -1,
    contactData: [],
    business: {
      name: '',
      legalType: $Enums.LegalType.personal,
      typeOfSalesChannel: $Enums.SaleChannelType.offline,
      category: [],
      idBusinessCategory: null,
      idCity: null,
      yearsBusinessOperation: null,
      revenue: 0,
      loanAmount: 0,
      loanPurpose: '',
      loanDuration: 0,
      phBusinessCategory: null,
      phCity: null,
      location: null,
      landmark: null,
      geoPos: [],
      phCheckingAccount: null,
      phHasAfs: null,
      phIsBusinessOwner: null,
      phIsReadyForVisit: null,
      qualification: null,
      geoDivision: null,
    },
    financial: {
      topSuppliers: '',
      outstandingDebt: '',
    },
    documents: [],
    kyc: {
      status: '',
    },
    shops: [],
  },
};

export default createReducer(initialState, (builder) => {
  builder.addCase(summaryThunk.get.fulfilled, (state, { payload }) => {
    state.isLoading = false;
    state.isLoaded = true;
    state.data = payload;
  });

  builder.addCase(summaryThunk.updateContactData.fulfilled, (state, { payload }) => {
    updateContactData(state, payload);
  });

  builder.addCase(summaryThunk.updateEmergencyContactData.fulfilled, (state, { payload }) => {
    updateContactData(state, payload);
  });

  builder.addCase(summaryThunk.deleteCoowner.fulfilled, (state, { payload: id }) => {
    const coowner = findCoowner(state.data, id);
    const idx = state.data.contactData.indexOf(coowner as ContactData);

    if (idx >= 0) {
      state.data.contactData.splice(idx, 1);
    }
  });

  builder.addCase(summaryThunk.updateBusinessData.fulfilled, (state, { payload }) => {
    Object.assign(state.data.business, payload);
  });

  builder.addCase(summaryThunk.updateFinancialData.fulfilled, (state, { payload }) => {
    state.data.financial = {
      ...payload,
      outstandingDebt: payload.outstandingDebt || null,
    };
  });

  builder.addCase(summaryThunk.updateKyc.fulfilled, (state, { payload }) => {
    Object.assign(state.data.kyc, payload);
  });

  builder.addCase(summaryAction.setFiles, (state, { payload }) => {
    const docs = state.data.documents.filter((doc) => {
      const isSameType = doc.type === payload.type;
      const hasCoownerId = !!payload.coownerId;

      if (hasCoownerId) {
        // leave documents without coownerId
        return !doc.coownerId || doc.coownerId !== payload.coownerId || !isSameType;
      }

      if (doc.coownerId) {
        return true;
      }

      if (!payload.bank) {
        return !isSameType;
      }

      return !isSameType;
    });

    state.data.documents = [...docs, ...payload.files];
  });

  builder.addCase(summaryAction.deleteFile, (state, { payload }) => {
    const docs = state.data.documents.filter((d) => d.id !== payload.document.id);
    state.data.documents = [...docs];
  });

  builder.addCase(summaryAction.updateKYCStatus, (state, { payload }) => {
    state.data.kyc.status = payload;
  });

  builder.addCase(summaryAction.setFinished, (state) => {
    state.isFinished = true;
  });
});

export const selectSummaryData = (state: AppState) => state.summary.data;

export const findContact = <T extends ContactData | EmergencyContactData>(
  summary: Summary | undefined,
  type: ContactType,
) => {
  if (!summary) {
    return null;
  }

  return summary.contactData.find((el) => el.type === type) as T;
};

const findCoowner = (summary: Summary | undefined, id: number): CoownerContactData | null => {
  if (!summary) {
    return null;
  }
  return (summary.contactData as CoownerContactData[]).find((el) => el.id === id) as CoownerContactData | null;
};

export const findCoowners = (summary: Summary | undefined): CoownerContactData[] => {
  if (!summary) {
    return [];
  }
  const coowners = summary.contactData.filter((el) => el.type === ContactType.Coowner) as CoownerContactData[];

  return coowners.sort((c1, c2) => c1.id - c2.id);
};

export const findDocuments = (summary: Summary, type: DocumentType): Document[] =>
  summary?.documents.filter((doc) => doc.type === type && !doc.coownerId) || [];

export const findCoownerDocuments = (summary: Summary, type: DocumentType, coownerId?: number): Document[] =>
  summary?.documents.filter((doc) => doc.type === type && doc.coownerId === coownerId) || [];

export type UploadedBankFile = StaticDecode<typeof bankDocumentCreateEndpoint.response>;
export const UploadedBankFileGuard = (file: Document): file is UploadedBankFile => 'bankStatements' in file;
export type UploadedBankStatements = Record<BankDocumentType, UploadedBankFile[]>;

export const findBankStatements = (summary: Summary): UploadedBankStatements => {
  const getDocuments = (type: BankDocumentType): UploadedBankFile[] =>
    (summary?.documents.filter((doc) => doc.type === type && UploadedBankFileGuard(doc)) || []) as UploadedBankFile[];

  return {
    [BankDocumentType.bankStatementCorporate]: getDocuments(BankDocumentType.bankStatementCorporate),
    [BankDocumentType.bankStatementPersonal]: getDocuments(BankDocumentType.bankStatementPersonal),
  };
};
