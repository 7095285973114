import { useState } from 'react';
import TermsAndConditionsEng from './termsTexts/TermsAndConditionsEng';
import TermsAndConditionsInd from './termsTexts/TermsAndConditionsInd';
import { Stack, Switch, Typography } from '@mui/material';

export const TermsAndConditions = () => {
  const [isInd, setIsInd] = useState(false);

  const switchLang = () => {
    setIsInd((prev) => !prev);
  };
  return (
    <div>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography color={isInd ? '' : 'primary.main'}>English</Typography>
        <Switch checked={isInd} onChange={switchLang} />
        <Typography color={isInd ? 'primary.main' : ''}>Bahasa Indonesia</Typography>
      </Stack>
      {isInd ? <TermsAndConditionsInd /> : <TermsAndConditionsEng />}
    </div>
  );
};
