export const TermsAndConditionsEng = () => {
  return (
    <div>
      <h3>Privacy Policy GetMo – English Version</h3>
      <p>
        <em>Last update: 19 October 2023</em>
      </p>
      <p>
        1. We, Getmo Pte. Ltd (“<strong>GetMo</strong>”), care about your personal data protection and are fully
        committed to protecting your personal data. This privacy policy is aimed for all of the users and visitors of
        GetMo and its affiliates, in accordance with the applicable laws and regulations of personal data protection in
        the Republic of Indonesia.{' '}
      </p>
      <p>
        2. By accessing and using our platform, including but not limited to our or our affiliates’ web pages, you
        hereby understand, willing and acknowledge the use of your personal data by GetMo from time to time in
        accordance with your usage. Hereby all of your personal data that you provide to GetMo is accurate and true.
        <br />
      </p>
      <p>
        3. The data we collect is related to our services, including but not limited to the type of information when
        registering a user account including name, email address, telephone number, identity card information, NPWP,
        photo, banking and financial information, e-commerce store URL /website, business sales data, company legal
        documents and other required documents.
        <br />
      </p>
      <p>
        4. We only collect your data solely for the purpose of our service as a financial services aggregator. The data
        that we have collected will be directly assigned to our financial service provider partner for their further
        actions.
        <br />
      </p>
      <p>
        5. While you are in correspondence with us, we will collect and process your personal data for the purposes of
        providing services to you, verifying user identity, monitoring, detecting and preventing use or misuse of
        services by unauthorized parties, communicating with you, providing products and /or services to you, respond to
        your inquiries, provide you with the latest news regarding products, services and/or promotions offered by GetMo
        as well as other purposes necessary to operate and maintain our business as stated in our Privacy Policy.
        <br />
      </p>
      <p>
        6. We and financial services institution partners that we work with may use cookies, web beacons, tags, scripts,
        local shared objects, namely HTML5 and Flash (commonly referred to as “flash cookies”), advertising identifiers
        (including mobile identifiers such as Apple&apos;s IDFA or Google&apos;s Marketing Identity) and similar
        technologies (“
        <strong>Cookies</strong>”) in connection with your use of the Website. Cookies have a unique identifier, and are
        stored on your computer or mobile device, the electronic mail we send to you, and on our platforms. Cookies can
        transmit data about you and your use of services, including browser type, your search preferences, IP address,
        data related to marketing that has been displayed and selected by you, the date and time of your use. Cookies
        can be stored only during a personal use session.
        <br />
      </p>
      <p>
        7. If you do not want your data to be collected through Cookies on our platform, you can disable Cookies by
        adjusting your internet software settings to disable, block or disable Cookies, by deleting your browsing
        history and clearing the cache of your internet software. You can also limit the sharing of some of this
        information through your mobile device settings.
        <br />
      </p>
      <p>
        8. We will not disclose your personal data under process to any third party, unless it is expressly required to
        do so by applicable laws and regulations. We should take into account the suggestions of the other party’s Data
        Protection Officer if they are lawful, recognizing in advance that such suggestions are aimed at the unimpeded
        cooperation of any party. We are solely responsible for receiving and processing personal data that has come to
        its knowledge at the exercise of our business activity.
        <br />
      </p>
      <p>
        9. We are not entitled to use or process your personal data, except for the purpose specified in this in our
        platform including but not limited to our web pages, applications or our partners. If any such processing does
        not fall clearly within the scope of this platform, we shall not process such treatment unless there is a prior
        written instruction from the Party collecting the personal data and provided that it is permissible by the Law
        No. 27 of 2022 on Personal Data Protection and other applicable laws and regulations on the personal data
        protection.
        <br />
      </p>
      <p>
        10. We shall implement appropriate technical measures in accordance with applicable best practice in the area of
        activity concerned to protect the confidential information under process from accidental or unlawful destruction
        or accidental loss (including deletion), alteration (including destruction), unauthorized disclosure, use or
        access and any other illegal form of processing the confidential information.
        <br />
      </p>
      <p>
        11. We are fully committed to ensure that your personal data will be stored safely in accordance with the
        requirements provided under the applicable laws and regulations in the Republic of Indonesia. You have the right
        to request for access, copy, update, or correct your personal data stored by us. You have the right from time to
        time to request us to limit the use of your personal data. Such a written request can be sent to email{' '}
        <a href="mailto:customersupport@getmo.id">customersupport@getmo.id</a>
        <br />
      </p>
      <p>
        12. We are fully committed to ensure the safety of your personal data, and we will be fully responsible for your
        loss that is caused by the fault, negligence, and/or any acts that are against the laws and regulations in the
        financial services sector, that is being committed by our Board of Directors, Board of Commissioners, Employees,
        and/or any third parties that works for us or represents us.
        <br />
      </p>
      <p>
        13. We reserve the right to update, amend, and change the Privacy Policy from time to time, that we will update
        from our webpage, application, or other methods we deem necessary.
        <br />
      </p>
      <p>
        14. This Privacy Policy is governed by and interpreted in accordance with the laws of the Republic of Indonesia.
        You hereby agree that any legal action or dispute that may arise from, relate to the site and/or this Privacy
        Policy will be resolved exclusively in the jurisdiction of the courts of the Republic of Indonesia.
      </p>
      <p></p>
      <h3>Terms and Conditions GetMo – English Version</h3>
      <p>
        <em>Last update: 19 October 2023</em>
      </p>
      <p></p>
      <p>
        Welcome to <a href="https://www.getmo.tech/">https://www.getmo.tech/</a> (“<strong>Website</strong>”), the Terms
        and Conditions set out below govern the use of the services offered by GetmoPte. Ltd. (“
        <strong>GetMo</strong>”) and all Service on our Website (“<strong>T&amp;C</strong>”).
      </p>
      <p>‍</p>
      <h4>
        <strong>1.&nbsp;&nbsp;&nbsp;INTRODUCTION</strong>
      </h4>
      <p>
        1.1&nbsp;&nbsp;&nbsp;&nbsp; GetMo owns and operates services through the GetMo website by providing business
        analysis and evaluation services to e-commerce by giving funding options to accelerate business growth with a
        Revenue-Based Financing and Fixed Loan systems.
      </p>
      <p>
        1.2&nbsp;&nbsp;&nbsp;&nbsp; The purpose of these T&amp;C is to regulate the use of the Service,therefore GetMo
        has all the rights to make changes to the applicable T&amp;C atany time and will always be attached to the GetMo
        Website. Any changes to the T&amp;C that occur, GetMo will always provide notification via email and on
        ourwebsite. We urge that it is your responsibility to follow the changes to the T&amp;C from time to time by
        reading, understanding and agreeing to the applicable T&amp;C. By registering yourself with the GetMo service,
        we assume that you have read, understood and agreed to all the T&amp;Cs consciously and without coercion from
        any party.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <h4>
        <strong>2.&nbsp;&nbsp;&nbsp;DEFINITION</strong>
      </h4>
      <p>
        2.1&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;In these T&amp;C, unless otherwise expressly provided, several terms contained
        in these T&amp;C have the following meanings:
      </p>
      <p>
        a.&nbsp;&nbsp;&nbsp; T&amp;C is an agreement between GetMo and you that contains a set of rules that regulate
        rights, obligations and responsibilities.
      </p>
      <p>b.&nbsp;&nbsp;&nbsp; Loans are funds or facilities lent by Financial Services Institution Partner to you.</p>
      <p>
        c.&nbsp;&nbsp;&nbsp;&nbsp;<strong>Financial Services Institution Partner </strong>is a financial service
        institution namely PT Digital Micro Indonesia or Danabijak that is established and registered under the
        Financial Services Authority <em>(Otoritas Jasa </em>Keuangan or“<strong>OJK”</strong>) as Information
        Technology Based Peer-to-Peer Lending Service Provider through Member of Commissioner of OJK Decree No. KEP
        92/D.05/2021 dated 8 September 2021, that collaborate with GetMo to provide Loan disbursement facilities to
        Borrower.
      </p>
      <p>
        d.&nbsp;&nbsp;&nbsp;<strong>Confidential Information</strong> is all information communicated between parties to
        these T&amp;C, whether in writing,electronically or orally, including this Service, but does not include
        information that has become or will be made public, except for that which has been revealed and disclosed
        without authorization or by the party users or others unauthorizedly.
      </p>
      <p>
        e.&nbsp;&nbsp;&nbsp;<strong>Data</strong> is any data that you enter or being entered with your authority on the
        Website.
      </p>
      <p>
        f.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Intellectual Property Rights </strong>means patents, trademarks, service
        marks, copyrights, rights to designs, know-how, or other intellectual or industrial property rights, whether
        registered or unregistered.
      </p>
      <p>
        g.&nbsp;&nbsp;&nbsp;<strong>Service </strong>is a Peer-to-Peer lending platform owned, created and managed by
        Financial Services Institution Partner.{' '}
      </p>
      <p>
        h.&nbsp;&nbsp;&nbsp;<strong>Website</strong> is an internet site at the domain{' '}
        <a href="https://www.getmo.tech/">https://www.getmo.tech/</a> or another internet site managed by GetMo.{' '}
      </p>
      <p>
        i.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Borrower </strong>is a person, or on behalf of an individual,
        organization or other entity who registers to use the Service.
      </p>
      <p>
        j.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>You </strong>are the Borrower.
      </p>
      <p>
        k.&nbsp;&nbsp;&nbsp;&nbsp;<strong>Customer Support</strong> is the customer service center that we provide for
        you and can be contacted via telephone+6281337428084 and/or email customersupport@getmo.id and/or live chaton
        the GetMo platform and can be used when you experience problems using GetMoor when you use the Service.
      </p>
      <p>&nbsp;&nbsp;</p>
      <h4>
        <strong>3.&nbsp;&nbsp;&nbsp;USE OF SERVICE</strong>
      </h4>
      <p>
        3.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; GetMo grants you the right to access and use the Service. The rights
        listed are non-exclusive, do not apply to other parties and are limited by the party signing the agreement.
      </p>
      <p>
        3.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You acknowledge and agree, subject to any written agreement or other
        applicable law:
      </p>
      <p>a.&nbsp;&nbsp;&nbsp;Whereas any use of the service is your responsibility. </p>
      <p>
        b.&nbsp;&nbsp;&nbsp;Whereas we only act as financial service aggregator platform by providing funding options
        from our Financial Service Institution Partner.Therefore, we collect data only for the purposes of providing the
        Service. Wedo not store this data, however we forward it to our Financial Services Institution Partner for
        further process.
      </p>
      <p>
        c.&nbsp;&nbsp;&nbsp;&nbsp;Whereas the Financial Services Institution Partner has the right to accept or reject
        prospective Borrower regarding Loan applications.
      </p>
      <p>&nbsp;&nbsp;</p>
      <h4>
        <strong>4.&nbsp;&nbsp;&nbsp;YOUR OBLIGATION</strong>
      </h4>
      <p>
        4.1&nbsp;&nbsp;&nbsp;&nbsp; General Obligation: You must ensure to only use the Service and Website for your
        proper and lawful internal business purposes, in accordance with the T&amp;C and notices published by GetMo or
        contained on the Website. GetMo is not responsible if you do things that violate the laws and regulations in
        force in the Republic of Indonesia while using the Service.
      </p>
      <p>
        4.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Access Obligations: You are obliged to ensure that the username and
        password required to access the Service are kept securely and confidentially. You are not permitted to
        misuse,use unauthorized access, modify, copy, imitate, disassemble, or manipulate the Service or the GetMo
        website in any way that may interfere with the continuity of the Service.
      </p>
      <p>&nbsp;&nbsp;</p>
      <h4>
        <strong>5.&nbsp;&nbsp;&nbsp;PREVAILING LAW</strong>
      </h4>
      <p>
        5.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The provisions of these T&amp;C are regulated and interpreted and
        implemented based on the laws in force in the Republic of Indonesia.
      </p>
      <p>
        5.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; All disputes related to these T&amp;C, shall be resolved by deliberation
        to reach consensus or through the Central Jakarta District Court.
      </p>
      <p>&nbsp;</p>
      <h4>
        <strong>6.&nbsp;&nbsp;&nbsp;GENERAL PROVISIONS</strong>
      </h4>
      <p>
        6.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; GetMo may change these Service T&amp;C at any time by posting revised
        T&amp;C on this Website. Your use of the Website after such changes are posted shall be deemed to constitute
        your acceptance of such revised Service T&amp;C.
      </p>
      <p>
        6.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You may not assign, sublicense or transfer any of the rights granted to
        you herein or subcontract any of your obligations.
      </p>
      <p>
        6.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You agree to comply with all applicable laws, statutes,regulations and
        codes, including any amendments (if any), relating to anti-bribery and corruption including without limitation
        OJK Regulation No.77/POJK.01/2016 concerning Information Technology Based Money Lending and Borrowing Services,
        Law No. 11 of 1980 concerning the Crime of Bribery, Law No.31 of 1999 concerning the Eradication of Corruption
        Crimes, as most recently amended by Law No. 20 of 2001 (as may be amended from time to time) and other relevant
        regulations.
      </p>
      <p>
        6.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; GetMo upholds that all acts or attempts at manipulation, crime,fraud,
        and/or acts of violation of rules committed by Borrower that occur in orin the use of the Website constitute a
        serious violation (&quot;Violation&quot;).
      </p>
      <p>
        6.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Borrower agrees that GetMo has the absolute right to take all
        necessary actions to restore and protect its good name and request compensation from the Borrower who committed
        the Violation for the losses suffered by GetMo including but not limited to carrying out examinations or
        investigations(either alone or in collaboration with the authorities), block accounts, deletecontent and/or
        accounts, reprimand the relevant Borrower, freeze accounts,compensate for losses suffered by GetMo.
      </p>
      <p>
        6.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; If you have any questions or concerns about these T&amp;C or any issues
        arising in these T&amp;C or on the Website, please contact our Customer Support.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <h4>
        <strong>7.&nbsp;&nbsp;&nbsp;CLOSING</strong>
      </h4>
      <p>
        7.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; These T&amp;C are a form of binding agreement between you and GetMo and
        come into effect when you start accessing the Website services.
      </p>
    </div>
  );
};

export default TermsAndConditionsEng;
