import { useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { StepContentHeader, StepContentHeaderProps } from './StepContentHeader';

type StepContentWidth = 'small' | 'standard' | 'medium' | 'extended' | 'large';

const widths: Record<StepContentWidth, string> = {
  small: '380px',
  standard: '540px',
  medium: '560px',
  extended: '720px',
  large: '860px',
};

interface Props extends StepContentHeaderProps {
  children: JSX.Element;
  width?: StepContentWidth;
}

export const StepContent = ({ title, subtitle, subtitleHeading, children, width = 'standard' }: Props) => {
  const maxWidth = widths[width];
  const isSmall = useMediaQuery(`@media (max-width:${maxWidth})`);

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb={6}>
      <Box sx={{ py: 4 }}>
        <StepContentHeader title={title} subtitle={subtitle} subtitleHeading={subtitleHeading} />
      </Box>
      <Box maxWidth={!isSmall ? maxWidth : '100vw'} width="100%">
        {children}
      </Box>
    </Box>
  );
};
