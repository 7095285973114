import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { LoanStatus } from "./LoanStatus";

export const Loan = OurType.PrismaModel("Loan", {
  id: Type.Number(),
  businessId: Type.Number(),
  createdAt: OurType.DateType(),
  orderNumber: Type.Number(),
  name: Type.String(),
  appealName: Type.String(),
  date: OurType.LocalDate(),
  specialFirstRepaymentDate: OurType.Nullable(OurType.LocalDate()),
  loanAgreementNumber: Type.String(),
  restructureId: OurType.Nullable(Type.Number()),
  writeOffDate: OurType.Nullable(OurType.LocalDate()),
  termsId: Type.Number(),
  vaNumber: Type.String({ title: "Virtual account" }),
  employeeId: Type.Number(),
  personInChargeId: OurType.Nullable(Type.Number()),
  assignmentId: OurType.Nullable(Type.Number()),
  endDate: OurType.LocalDate(),
  status: LoanStatus,
  repaymentAmount: OurType.TypeMoney(),
  repaidAmount: OurType.TypeMoney(),
  lastPaymentDate: OurType.Nullable(OurType.LocalDate()),
  lastInvoiceDate: OurType.Nullable(OurType.LocalDate()),
  paymentOverdueDate: OurType.Nullable(OurType.LocalDate()),
});
