import { Box, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { ContactData, ContactType } from '@getmo/onboarding/shared/endpoints/onboarding';
import { StepContent } from '../../components/StepContent/StepContent';
import { StepSubmitButton } from '../../components/StepSubmitButton/StepSubmitButton';
import { UpdateContactData, summaryThunk } from '../../store/reducers/thunks';
import { useAppDispatch, useAppSelector } from '../../store/store';
import FormikTextInput from '../components/FormikTextInput';
import { useSnackbar } from '../components/snackbar/useSnackbar';
import { useApiErrors } from '../hooks/useApiErrors';
import { useNextStep } from '../hooks/useNextStep';
import { findContact, selectSummaryData } from '../../store/reducers/summaryReducer';
import { yup } from '../../utils/yup';
import { useBrandSettings } from '../hooks/useBrandSettings';
import { createSelector } from '@reduxjs/toolkit';

interface InitialValues {
  name: string;
  email: string;
}

export const ContactInformation = () => {
  const title = [<>Please fill out your</>, <>contact information below</>];
  const subtitle = (
    <>
      <Box>{`Rest assured, we won't send any advertising.`}</Box>
      <Box>{`We'll only send you important updates and information.`}</Box>
    </>
  );

  const selectInitialValues = createSelector([selectSummaryData], (summary): InitialValues => {
    const contact = findContact<ContactData>(summary, ContactType.Applicant);
    return {
      name: contact?.name || '',
      email: contact?.email || '',
    };
  });

  const initialValues: InitialValues = useAppSelector(selectInitialValues);
  const [initialErrors, setInitialErrors] = useState<Partial<InitialValues>>({});
  const [submitting, setSubmitting] = useState(false);

  const validation = Yup.object({
    name: Yup.string().required(),
    email: yup.validateEmail().required(),
  });

  const { showError } = useSnackbar();
  const { goToNextStep } = useNextStep();
  const dispatch = useAppDispatch();
  const { source } = useBrandSettings();

  const onSubmit = async (values: InitialValues) => {
    setInitialErrors({});
    setSubmitting(true);

    const data: UpdateContactData = {
      ...values,
      leadSource: source,
      type: ContactType.Applicant,
    };

    dispatch(summaryThunk.updateContactData(data))
      .unwrap()
      .then(() => {
        goToNextStep();
      })
      .catch((e) => {
        const { error, validationErrors } = useApiErrors(e);
        setInitialErrors(validationErrors);
        if (error) {
          showError(error);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <StepContent title={title} subtitle={subtitle} width="small">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        initialErrors={initialErrors}
        onSubmit={onSubmit}
        validationSchema={validation}
      >
        {({ handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Grid container direction="column">
                <Grid item>
                  <FormikTextInput type="text" name="name" label="Full Name" required />
                </Grid>
                <Grid item>
                  <FormikTextInput
                    type="text"
                    name="email"
                    label="Email"
                    placeholder="example@gmail.com"
                    required
                    transform={(v) => v.trim()}
                  />
                </Grid>
                <Grid item display="flex" justifyContent="center" py={3}>
                  <StepSubmitButton isSubmitting={submitting} />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </StepContent>
  );
};
