import Cookies from 'js-cookie';

const AccessToken = 'access_token';

const TokenTTL = {
  access: 7,
};

export const tokenStorage = {
  getAccess(): string | undefined {
    return Cookies.get(AccessToken);
  },

  setAccess(token: string, ttl: number = TokenTTL.access) {
    Cookies.set(AccessToken, token, {
      expires: ttl,
    });
  },

  deleteAccess() {
    Cookies.remove(AccessToken);
  },
};
