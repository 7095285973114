import { Box, Stack } from '@mui/material';
import { ChangeEventHandler, ClipboardEventHandler, KeyboardEventHandler, useRef } from 'react';
import { number } from '../../utils/number';
import { useThemeQuery } from '../hooks/useThemeQuery';
import PhoneCodeCell from './PhoneCodeCell';

type ChangeHandler = <T = string | React.ChangeEvent<HTMLInputElement>>(
  field: T,
) => T extends React.ChangeEvent<HTMLInputElement> ? void : (e: string | React.ChangeEvent<HTMLInputElement>) => void;

interface PhoneCodeProps {
  name: string;
  handleChange: ChangeHandler;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
  disabled: boolean;
}

const PhoneCode = ({ name, handleSubmit, handleChange: changeValue, disabled }: PhoneCodeProps) => {
  const refs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  const getPrevOrNextRef = (target: EventTarget, isPrev: boolean = false) => {
    const refIdx = refs.findIndex((ref) => ref.current === target);
    return isPrev ? refs[refIdx - 1] : refs[refIdx + 1];
  };
  const focusPrevOrNextRef = (target: EventTarget, isPrev: boolean = false) => {
    const ref = getPrevOrNextRef(target, isPrev);
    if (ref) {
      ref.current?.focus();
    }
  };

  const onChange: ChangeEventHandler = (e) => {
    focusPrevOrNextRef(e.target);

    if (refs.every((ref) => !!ref.current?.value)) {
      handleSubmit();
    }
  };

  const onPaste: ClipboardEventHandler = (e) => {
    e.preventDefault();

    const pastedText = number.filterStr(e.clipboardData.getData('text/plain'));
    refs.forEach((ref, idx) => {
      if (!ref.current) {
        return;
      }
      const num = pastedText[idx];
      if (num) {
        const inputName = `${name}[${idx}]`;
        changeValue({ target: { name: inputName, value: num } });
      }
    });

    const lastRefIdx = Math.min(refs.length, pastedText.length) - 1;
    refs[lastRefIdx].current?.focus();

    if (pastedText.length >= 6) {
      handleSubmit();
    }
  };

  const { isSmall } = useThemeQuery();

  return (
    <Box>
      <Stack spacing={isSmall ? 0.5 : 2} direction="row">
        {Array(6)
          .fill('')
          .map((_, idx) => {
            const inputName = `${name}[${idx}]`;
            const changeToEmpty = () => ({
              target: { name: inputName, value: '' },
            });

            const onKeyDown: KeyboardEventHandler = (e) => {
              if ((e.ctrlKey || e.metaKey) && e.key === 'v') {
                return;
              }

              // e.code can be undefined in Android Facebook browser
              if (e.code?.startsWith('Key')) {
                e.preventDefault();
                return;
              }

              if (e.code === 'Backspace') {
                changeValue(changeToEmpty());
                focusPrevOrNextRef(e.target, true);
                e.preventDefault();
                return;
              }

              if (e.code?.startsWith('Digit')) {
                changeValue(changeToEmpty());
              }
            };

            return (
              <PhoneCodeCell
                key={`cell-${idx}`}
                name={inputName}
                inputRef={refs[idx]}
                onChange={onChange}
                autofocus={idx === 0}
                onPaste={onPaste}
                onKeyDown={onKeyDown}
                disabled={disabled}
              />
            );
          })}
      </Stack>
    </Box>
  );
};

export default PhoneCode;
