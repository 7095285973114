export const number = {
  filterStr: (value: string) => String(value).replaceAll(/\D/g, ''),
  prettifyMoney: (value: string): string => {
    const num = parseFloat(number.filterStr(value));
    if (isNaN(num)) {
      return value;
    }
    return num.toLocaleString();
  },
  deprettifyMoney: (value: string) => number.filterStr(value),
};
