import { ValidationErrorResponse } from '../api';

export const formik = {
  parseApiErrors: (errors: ValidationErrorResponse) => {
    return Object.entries(errors).reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key]: value[0],
      };
    }, {});
  },
  getError: (error?: unknown, touched?: unknown) => {
    return error && touched ? String(error) : '';
  },
};
