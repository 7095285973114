import { FinancialData, financialSaveEndpoint } from '@getmo/onboarding/shared/endpoints/financialSave';
import {
  BusinessInfoData,
  businessSaveEndpoint,
  contactInfoEndpoint,
  CoownerContactData,
  coownerDeleteEndpoint,
  EmergencyContactData,
  finishEndpoint,
  loanGetEndpoint,
  stepSetEndpoint,
  Summary,
  summaryEndpoint,
} from '@getmo/onboarding/shared/endpoints/onboarding';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { callApi, ErrorResponse } from '../../api';
import { ContactType, LoanPreviewInfo } from '@getmo/onboarding/shared/endpoints/onboarding';
import { phone } from '../../utils/phone';
import { shopAction } from './actions';
import {
  PersonUnverifiedData,
  personUnverifiedDataUpdateEndpoint,
} from '@getmo/onboarding/shared/endpoints/personVerification';
import {
  Shop,
  shopCreateEndpoint,
  ShopData,
  shopDeleteEndpoint,
  shopListEndpoint,
  shopSyncEndpoint,
  ShopSyncLink,
  shopUpdateEndpoint,
} from '@getmo/onboarding/shared/endpoints/shops';
import { GeoDivision } from '@getmo/onboarding/shared/models/dictionaries';
import { AppState } from '../store';
import { StaticDecode } from '@sinclair/typebox';
import { geoDivisionListEndpoint } from '@getmo/onboarding/shared/endpoints/dictionaries';

type Reject = { rejectValue: ErrorResponse };

export const stepThunk = {
  setStep: createAsyncThunk<void, { step: string }, Reject>('steps/setStep', async ({ step }, { rejectWithValue }) => {
    return await callApi(stepSetEndpoint, { body: { step } }).catch(rejectWithValue);
  }),
};

export const shopThunk = {
  getList: createAsyncThunk<Shop[], void, Reject>('shop/getShopList', async (data, { rejectWithValue }) => {
    return await callApi(shopListEndpoint, {}).catch(rejectWithValue);
  }),
  updateIsSynced: createAsyncThunk<Shop[], void, Reject>('shop/updateIsSynced', async (data, { rejectWithValue }) => {
    return await callApi(shopListEndpoint, {}).catch(rejectWithValue);
  }),
  create: createAsyncThunk<Shop, ShopData, Reject>('shop/create', async (data, { rejectWithValue }) => {
    return await callApi(shopCreateEndpoint, { body: data }).catch(rejectWithValue);
  }),
  update: createAsyncThunk<Shop, { id: number; data: ShopData }, Reject>(
    'shop/update',
    async ({ id, data }, { rejectWithValue }) => {
      return await callApi(shopUpdateEndpoint, {
        params: { shopId: id },
        body: data,
      }).catch(rejectWithValue);
    },
  ),
  delete: createAsyncThunk<number, number, Reject>('shop/delete', async (id, { rejectWithValue }) => {
    await callApi(shopDeleteEndpoint, {
      params: { shopId: id },
    }).catch(rejectWithValue);

    return id;
  }),
  getSyncLink: createAsyncThunk<ShopSyncLink & { id: number }, { id: number; controller?: AbortController }, Reject>(
    'shop/getSyncLink',
    async ({ id, controller }, { rejectWithValue, dispatch }) => {
      dispatch(shopAction.setIsSyncFetching({ id, isFetching: true }));

      try {
        return {
          id,
          ...(await callApi(shopSyncEndpoint, {
            params: { saleChannelId: id },
            signal: controller?.signal,
          })),
        };
      } catch (error) {
        return rejectWithValue(error as ErrorResponse);
      } finally {
        dispatch(shopAction.setIsSyncFetching({ id, isFetching: false }));
      }
    },
  ),
};

export const loanThunk = {
  get: createAsyncThunk<LoanPreviewInfo, void, Reject>('loan/getInfo', async (_, { rejectWithValue }) => {
    return await callApi(loanGetEndpoint, {}).catch(rejectWithValue);
  }),
};

export type UpdateContactData = StaticDecode<typeof contactInfoEndpoint.body> & {
  type: ContactType;
};
export const summaryThunk = {
  get: createAsyncThunk<Summary, void, Reject>('summary/getInfo', async (_, { rejectWithValue }) => {
    return await callApi(summaryEndpoint, {}).catch(rejectWithValue);
  }),

  updateContactData: createAsyncThunk<UpdateContactData, UpdateContactData, Reject>(
    'summary/updateContactData',
    async (data, { rejectWithValue }) => {
      const body = data.phone ? { ...data, phone: phone.toApiValue(data.phone) } : data;
      return callApi(contactInfoEndpoint, { body, params: { type: data.type } })
        .then((result) => ({
          ...result,
          type: data.type,
          documentIds: data.documentIds || [],
        }))
        .catch(rejectWithValue);
    },
  ),

  deleteCoowner: createAsyncThunk<number, CoownerContactData, Reject>(
    'summary/deleteCoowner',
    async (data, { rejectWithValue }) => {
      await callApi(coownerDeleteEndpoint, {
        params: { coownerId: data.id },
      }).catch(rejectWithValue);
      return data.id;
    },
  ),

  updateEmergencyContactData: createAsyncThunk<UpdateContactData, EmergencyContactData, Reject>(
    'summary/updateEmergencyContactData',
    async (data, { rejectWithValue }) => {
      return callApi(contactInfoEndpoint, {
        params: { type: data.type },
        body: {
          ...data,
          phone: phone.toApiValue(data.phone),
        },
      })
        .then((result) => ({
          ...result,
          type: data.type,
        }))
        .catch(rejectWithValue);
    },
  ),

  updateBusinessData: createAsyncThunk<Partial<BusinessInfoData>, Partial<BusinessInfoData>, Reject>(
    'summary/updateBusinessData',
    async (data, { rejectWithValue }) => {
      return callApi(businessSaveEndpoint, { body: data })
        .then(() => data)
        .catch(rejectWithValue);
    },
  ),

  updateFinancialData: createAsyncThunk<FinancialData, FinancialData, Reject>(
    'summary/updateFinancialData',
    async (data, { rejectWithValue }) => {
      return callApi(financialSaveEndpoint, { body: data })
        .then(() => data)
        .catch(rejectWithValue);
    },
  ),

  finish: createAsyncThunk('summary/finish', async (_, { rejectWithValue }) =>
    callApi(finishEndpoint, {}).catch(rejectWithValue),
  ),

  updateKyc: createAsyncThunk<PersonUnverifiedData, PersonUnverifiedData, Reject>(
    'summary/kyc',
    async (data, { rejectWithValue }) => {
      return callApi(personUnverifiedDataUpdateEndpoint, { body: data })
        .then(() => data)
        .catch(rejectWithValue);
    },
  ),
};

export const dictionariesThunk = {
  loadGeoDivisions: createAsyncThunk<
    GeoDivision[],
    { parentDivision: GeoDivision | null },
    Reject & { state: AppState }
  >(
    'dictionaries/geoDivisions',
    ({ parentDivision }, { rejectWithValue }) =>
      callApi(geoDivisionListEndpoint, { qs: { parentCode: parentDivision?.code ?? 'null' } })
        .then((data) => data.results)
        .catch((e) => rejectWithValue(e)),
    {
      condition: ({ parentDivision }, api) => {
        const state = api.getState();
        return (
          !state.dictionaries.isLoadingGeoDivisions && !state.dictionaries.geoDivisions[parentDivision?.code ?? '']
        );
      },
    },
  ),
};
