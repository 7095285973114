export const TermsAndConditionsInd = () => {
  return (
    <div>
      <h3>Ketentuan Penggunaan Website - Versi Bahasa Indonesia</h3>
      <p>
        <em>Pembaharuan terakhir: 19 Oktober 2023</em>
      </p>
      <p>
        1.<span> </span>Kami, Getmo Pte. Ltd (“<b>GetMo</b>”) peduli atas kerahasiaan data pribadi anda dan berkomitmen
        penuh untuk memberikan perlindungan terbaik atas data pribadi anda. Kebijakan Privasi ini ditujukan untuk
        seluruh pengguna jasa dan pengunjung GetMo serta afiliasi dari GetMo, sesuai dengan ketentuan peraturan
        perundang-undangan yang berlaku di Republik Indonesia untuk perlindungan data pribadi.{' '}
      </p>
      <p>
        2. Dengan mengakses dan menggunakan platform kami, termasuk namun tak terbatas pada laman web, aplikasi kami
        ataupun rekanan kami, anda dengan ini telah mengerti, bersedia, dan setuju untuk penggunaan data pribadi anda
        oleh GetMo dari waktu ke waktu sesuai dengan penggunaan anda. Dengan ini anda memberikan konfirmasi kepada kami
        bahwa seluruh data pribadi yang anda berikan atau akan berikan kepada GetMo adalah benar dan tepat.
        <br />
      </p>
      <p>
        3. Data-data yang kami kumpulkan terkait dengan layanan kami, termasuk namun tidak terbatas pada jenis informasi
        saat pendaftaran akun pengguna termasuk nama, alamat email, nomor telepon informasi kartu identitas, NPWP, foto,
        informasi perbankan dan keuangan, URL toko e-commerce/website, data penjualan bisnis, dokumen legalitas
        perusahaan dan dokumen lainnya yang diperlukan.
        <br />
      </p>
      <p>
        4. Pengumpulan data yang kami lakukan hanya semata-mata ditujukan untuk kepentingan penyelenggaraan layanan kami
        sebagai agregasi jasa keuangan. Data-data yang kami kumpulkan tidak akan kami simpan dan langsung diteruskan
        kepada rekanan lembaga jasa keuangan untuk dapat diproses lebih lanjut.
        <br />
      </p>
      <p>
        5. Selama anda berhubungan dengan kami, kami akan mengumpulkan dan memproses data pribadi anda untuk tujuan
        menyediakan layanan kepada anda, melakukan verifikasi identitas pengguna, memantau, mendeteksi dan mencegah
        penggunaan atau penyalahgunaan layanan oleh pihak yang tidak sah, berkomunikasi dengan anda, menyediakan produk
        dan/atau layanan kepada anda, memberi tanggapan terhadap pertanyaan anda, memberi anda kabar terkini mengenai
        produk, layanan dan/atau promosi yang ditawarkan oleh GetMo serta tujuan lain yang diperlukan untuk
        mengoperasikan dan mempertahankan usaha kami sebagaimana yang tercantum pada Kebijakan Privasi kami.
        <br />
      </p>
      <p>
        6. Kami dan rekanan lembaga jasa keuangan yang bekerjasama dengan kami, dapat menggunakan cookies, web beacons,
        tags, scripts, local shared objects yaitu HTML5 dan Flash, advertising identifiers (termasuk pengenal seluler
        seperti IDFA milik Apple atau Identitas Pemasaran milik Google) dan teknologi serupa (“<b>Cookies</b>“)
        sehubungan dengan penggunaan platform oleh anda. Cookies memiliki pengidentifikasi unik, dan tersimpan dalam,
        komputer atau perangkat bergerak Anda, surat elektronik yang kami kirimkan kepada Anda, dan pada platform kami.
        Cookies dapat mengirimkan data-data anda dan penggunaan layanan anda, termasuk tipe browser, preferensi
        pencarian Anda, alamat IP, data terkait pemasaran yang telah ditampilkan dan dipilih oleh Anda, tanggal serta
        waktu penggunaan anda. Cookies dapat tersimpan hanya selama sesi penggunaan pribadi.
        <br />
      </p>
      <p>
        7. Apabila anda tidak ingin data-data anda dikumpulkan melalui Cookies di platform kami, Anda dapat
        menonaktifkan Cookies dengan menyesuaikan pengaturan perangkat lunak internet anda untuk menonaktifkan,
        memblokir atau menonaktifkan Cookies, dengan menghapus riwayat penjelajahan Anda dan membersihkan cache dari
        perangkat lunak internet Anda. Anda juga dapat membatasi pembagian beberapa informasi ini melalui pengaturan
        perangkat seluler Anda.
        <br />
      </p>
      <p>
        8. Kami tidak akan mengungkapkan data-data pribadi anda yang sedang diproses kepada pihak ketiga mana pun,
        kecuali secara tegas diharuskan melakukannya oleh hukum dan peraturan yang berlaku. Kami juga akan
        mempertimbangkan saran dari Petugas Perlindungan Data dari pihak terkait jika itu sah, dengan mengakui
        sebelumnya bahwa saran tersebut ditujukan untuk kerja sama tanpa hambatan dari pihak tersebut. Kami akan
        bertanggung jawab penuh untuk menerima dan memproses data pribadi yang diketahui dan diterima saat menjalankan
        kegiatan usaha kami.
        <br />
      </p>
      <p>
        9. Kami tidak berhak untuk menggunakan atau memproses data pribadi kecuali untuk tujuan yang ditentukan dalam
        platform kami termasuk namun tak terbatas pada laman web, aplikasi kami ataupun rekanan kami. Jika pemrosesan
        tersebut tidak secara jelas termasuk dalam ruang lingkup platform ini, Kami tidak akan memproses tindakan
        tersebut kecuali terdapat instruksi tertulis sebelumnya yang mengumpulkan data pribadi dan dengan ketentuan
        bahwa hal itu diizinkan oleh Undang-Undang No. 27 Tahun 2022 tentang Perlindungan Data Pribadi dan peraturan
        perundang-undangan lainnya yang berlaku terkait perlindungan data pribadi.
        <br />
      </p>
      <p>
        10. Kami akan menerapkan langkah-langkah yang tepat sesuai dengan praktik terbaik yang berlaku di bidang
        kegiatan terkait untuk melindungi informasi rahasia yang sedang diproses dari kehancuran yang tidak disengaja
        atau tidak sah atau kerugian yang tidak disengaja (termasuk penghapusan), perubahan (termasuk penghancuran),
        pengungkapan, penggunaan atau akses yang tidak sah dan segala bentuk pemrosesan ilegal lainnya atas data pribadi
        anda.
        <br />
      </p>
      <p>
        11. Kami berkomitmen penuh untuk memastikan bahwa data pribadi anda akan diproses pada server yang telah
        terjamin keamanannya sesuai dengan ketentuan peraturan perundang-undangan yang berlaku di Republik Indonesia.
        Anda berhak untuk meminta akses, salinan, memperbaharui atau memperbaiki data pribadi anda yang disimpan oleh
        kami. Anda mempunyai hak dari waktu ke waktu untuk meminta kami membatasi pemrosesan dan penggunaan data pribadi
        anda. Permintaan tertulis dapat anda tujukan kepada kami melalui email{' '}
        <a href="mailto:customersupport@getmo.id">customersupport@getmo.id</a>
        <br />
      </p>
      <p>
        12. Kami berkomitmen penuh untuk memastikan keamanan data pribadi anda, dan kami akan bertanggung jawab penuh
        atas kerugian anda yang timbul akibat kesalahan, kelalaian, dan/atau perbuatan yang bertentangan dengan
        ketentuan peraturan perundang-undangan di sektor jasa keuangan, yang dilakukan oleh Direksi, Dewan Komisaris,
        Pegawai, dan/atau pihak ketiga yang bekerja untuk atau mewakili kepentingan kami.
        <br />
      </p>
      <p>
        13. Kami berhak untuk memperbarui, mengubah, dan menambahkan Kebijakan Privasi dari waktu ke waktu, yang akan
        kami beritahukan melalui laman web, aplikasi, atau cara-cara lainnya yang kami anggap tepat.
        <br />
      </p>
      <p>
        14. Kebijakan Privasi ini diatur oleh dan ditafsirkan sesuai dengan hukum Republik Indonesia. Dengan ini anda
        setuju bahwa tindakan hukum atau sengketa apapun yang mungkin timbul dari, berhubungan dengan situs dan/atau
        Kebijakan Privasi ini akan diselesaikan secara eksklusif dalam yurisdiksi pengadilan Republik Indonesia.
      </p>
      <br />
      <h3>Syarat dan Ketentuan GetMo - Versi Bahasa Indonesia</h3>
      <p>
        <em>Pembaharuan terakhir: 19 Oktober 2023</em>
      </p>
      <p>
        Selamat datang di <a href="https://id.getmo.tech/">https://www.getmo.tech/</a> (“<b>Situs Web</b>”), Syarat dan
        Ketentuan yang ditetapkan di bawah ini mengatur pemakaian jasa yang ditawarkan oleh Getmo Pte. Ltd. (“
        <b>GetMo</b>”) dan seluruh Layanan di dalam Situs Web kami (“<b>S&amp;K</b>”).
      </p>
      <p>‍</p>
      <h4>
        <strong>1. PENDAHULUAN</strong>
      </h4>
      <p>
        1.1 GetMo memiliki dan mengoperasikan layanan melalui website GetMo dengan memberikan layanan&nbsp; analisa dan
        evaluasi bisnis kepada e-commerce dengan memberikan opsi pendanaan untuk mempercepat pertumbuhan bisnis dengan
        sistem Revenue-Based Financing dan Pinjaman Tetap.
      </p>
      <p>
        1.2 Tujuan dari S&amp;K ini adalah untuk mengatur penggunaan dari Layanan sehingga GetMo memiliki segala hak
        untuk melakukan perubahan terhadap S&amp;K yang berlaku kapan saja dan akan selalu terlampir pada Situs Web
        GetMo. Pada saat terjadi perubahan S&amp;K, GetMo akan selalu memberikan pemberitahuan melalui email dan website
        kami. Kami menghimbau agar menjadi tanggung jawab Anda untuk mengikuti perubahan S&amp;K dari waktu ke waktu
        dengan membaca, mengerti dan menyetujui S&amp;K yang berlaku tersebut. Dengan mendaftarkan diri Anda ke layanan
        GetMo, kami menganggap bahwa anda sudah membaca, mengerti, dan menyetujui semua S&amp;K secara sadar dan tanpa
        paksaan dari pihak manapun.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <h4>
        <strong>2. DEFINISI</strong>
      </h4>
      <p>
        2.1 Dalam S&amp;K ini, kecuali ditentukan lain secara tegas, beberapa istilah yang terkandung di dalam S&amp;K
        ini memiliki arti sebagai berikut:
      </p>
      <p>
        a. S&amp;K adalah perikatan antara GetMo dan Anda yang beirisikan seperangkat peraturan yang mengatur hak,
        kewajiban, dan tanggung jawab.
      </p>
      <p>b. Pinjaman adalah dana atau fasilitas yang dipinjamkan oleh Rekanan Lembaga Jasa Keuangan kepada Anda.</p>
      <p>
        c. <b>Rekanan Lembaga Jasa Keuangan</b> adalah perusahaan lembaga jasa keuangan bernama PT Digital Micro
        Indonesia atau Danabijak&nbsp; yang telah berdiri dan terdaftar dalam Otoritas Jasa Keuangan (“<b>OJK</b>”)
        sebagai Penyelenggara Layanan Pinjam Meminjam Uang Berbasis Teknologi Informasi melalui Keputusan Anggota Dewan
        Komisioner OJK No. KEP-92/D.05/2021 tanggal 8 September 2021, yang bekerjasama dengan GetMo untuk memberikan
        fasilitas pencairan Pinjaman kepada Penerima Pinjaman.
      </p>
      <p>
        d. <b>Informasi Rahasia</b> adalah semua informasi yang dikomunikasikan antara pihak dalam S&amp;K ini, baik
        secara tertulis, elektronik, atau lisan, termasuk Layanan ini, tapi tidak termasuk informasi yang sudah menjadi
        atau akan dijadikan untuk publik, terkecuali yang sudah diungkapkan dan terbongkar tanpa hak atau oleh pihak
        pengguna atau lainnya secara tidak sah.
      </p>
      <p>
        e. <b>Data</b> adalah data apapun yang Anda masukan atau dimasukan dengan kewenangan Anda kepada Situs Web.
      </p>
      <p>
        f. <b>Hak Kekayaan Intelektual</b> berarti paten, merek dagang, merek jasa atau layanan, hak cipta, hak pada
        desain, pengetahuan, atau hak kekayaan intelektual atau industri lainnya, maupun terdaftar atau tidak terdaftar.
      </p>
      <p>
        g. <b>Layanan</b> adalah platform Peer-to-Peer lending yang dimiliki, dibuat dan dikelola oleh Rekanan Lembaga
        Jasa Keuangan.{' '}
      </p>
      <p>
        h. <b>Situs Web</b> adalah situs internet di domain <a href="https://id.getmo.tech/">https://www.getmo.tech/</a>{' '}
        atau situs internet lainnya yang dikelola oleh GetMo.{' '}
      </p>
      <p>
        i. <b>Penerima Pinjaman</b> adalah orang, maupun atas nama pribadi, organisasi atau badan lainnya yang mendaftar
        untuk menggunakan Layanan.
      </p>
      <p>
        j. <b>Anda</b> adalah Penerima Pinjaman.
      </p>
      <p>
        k. <b>Customer Support</b> adalah pusat layanan pelanggan yang kami sediakan untuk Anda dan dapat dihubungi
        melalui telepon +6281337428084 dan/atau email <a>customersupport@getmo.id</a>
        <br /> dan/atau live chat di platform GetMo dan dapat digunakan saat Anda mengalami masalah dalam penggunaan
        GetMo atau saat Anda menggunakan Layanan.
      </p>
      <p>&nbsp;&nbsp;</p>
      <h4>
        <strong>3. PENGGUNAAN LAYANAN</strong>
      </h4>
      <p>
        3.1 GetMo memberi Anda hak untuk mengakses dan memakai Layanan. Hak-hak yang tercantum bersifat non-eksklusif,
        tidak berlaku pada pihak lain dan dibatasi oleh pihak yang menandatangani perjanjian.
      </p>
      <p>
        3.2 Anda mengakui dan menyetujui, bergantung kepada perjanjian tulis apapun atau hukum lainnya yang berlaku:
      </p>
      <p>a. Bahwa setiap penggunaan layanan menjadi tanggung jawab Anda. </p>
      <p>
        b. Bahwa kami bertindak sebagai platform agregasi jasa keuangan dengan menyediakan opsi pendanaan dari Rekanan
        Lembaga Jasa Keuangan. Dengan demikian, pengumpulan data yang kami lakukan hanya untuk kepentingan
        penyelenggaraan Layanan. Data-data tersebut tidak kami simpan namun kami teruskan kepada Rekanan Lembaga Jasa
        Keuangan untuk dapat diproses lebih lanjut.
      </p>
      <p>
        c. Bahwa Rekanan Lembaga Jasa Keuangan berhak menerima atau menolak calon Penerima Pinjaman terhadap pengajuan
        Pinjaman.
      </p>
      <p>&nbsp;&nbsp;</p>
      <h4>
        <strong>4. KEWAJIBAN ANDA</strong>
      </h4>
      <p>
        4.1 Kewajiban Umum: Anda harus memastikan hanya menggunakan Layanan dan Situs Web untuk keperluan internal
        bisnis Anda yang benar dan secara sah, sesuai dengan S&amp;K dan pemberitahuan yang diumumkan oleh GetMo atau
        yang tercantum di Situs Web. GetMo tidak bertanggungjawab jika Anda melakukan hal-hal yang melanggar hukum dan
        peraturan yang berlaku di Negara Republik Indonesia selama menggunakan Layanan.
      </p>
      <p>
        4.2 Kewajiban Akses: Anda wajib memastikan bahwa nama pengguna dan kata sandi yang diperlukan untuk mengakses
        Layanan disimpan dengan aman dan rahasia. Anda tidak diijinkan untuk menyalahgunakan, menggunakan akses tidak
        sah, memodifikasi, menyalin, meniru, membongkar, atau merekayasa Layanan atau situs web GetMo dengan cara apapun
        yang dapat mengganggu keberlangsungan Layanan.
      </p>
      <p>&nbsp;&nbsp;</p>
      <h4>
        <strong>5. HUKUM YANG MENGATUR</strong>
      </h4>
      <p>
        5.1 Ketentuan S&amp;K ini diatur dan ditafsirkan serta dilaksanakan berdasarkan hukum yang berlaku di Negara
        Republik Indonesia.
      </p>
      <p>
        5.2 Segala sengketa yang berkaitan dengan S&amp;K ini, diselesaikan secara musyawarah untuk mufakat atau melalui
        Pengadilan Negeri Jakarta Pusat.
      </p>
      <p>&nbsp;</p>
      <h4>
        <strong>6. KETENTUAN UMUM</strong>
      </h4>
      <p>
        6.1 GetMo dapat mengubah S&amp;K Layanan ini setiap saat dengan memposting S&amp;K yang direvisi di Situs Web
        ini. Penggunaan Situs Web oleh Anda setelah perubahan tersebut diposting dianggap sebagai penerimaan Anda
        terhadap S&amp;K Layanan yang direvisi tersebut.
      </p>
      <p>
        6.2 Anda tidak boleh memberikan, mensublisensikan atau memindahkan hak apapun yang diberikan kepada Anda di sini
        atau mensubkontrakkan salah satu kewajiban Anda.
      </p>
      <p>
        6.3 Anda setuju untuk mematuhi semua hukum, undang-undang, peraturan dan kode yang berlaku, termasuk setiap
        perubahannya (apabila ada), yang berkaitan dengan anti penyuapan dan korupsi termasuk dengan tidak terbatas pada
        Peraturan OJK No. 77/POJK.01/2016 tentang Layanan Pinjam Meminjam Uang Berbasis Teknologi Informasi,
        Undang-Undang No. 11 Tahun 1980 tentang Tindak Pidana Suap, Undang-Undang No. 31 Tahun 1999 tentang
        Pemberantasan Tindak Pidana Korupsi, sebagaimana diubah terakhir dengan Undang-Undang No. 20 Tahun 2001
        (sebagaimana dapat diubah dari waktu ke waktu) dan peraturan lainnya yang relevan.
      </p>
      <p>
        6.4 GetMo berpendirian bahwa segala tindakan atau upaya percobaan manipulasi, kejahatan, penipuan, dan/atau
        tindakan-tindakan pelanggaran aturan yang dilakukan oleh Penerima Pinjaman yang terjadi di dalam atau dalam
        penggunaan Situs Web merupakan bentuk pelanggaran yang serius (&quot;Pelanggaran&quot;).
      </p>
      <p>
        6.5 Penerima Pinjaman setuju bahwa GetMo secara mutlak berhak untuk melakukan segala tindakan yang diperlukan
        untuk memulihkan dan melindungi nama baiknya serta meminta penggantian kepada Penerima Pinjaman yang melakukan
        Pelanggaran atas kerugian yang diderita GetMo termasuk namun tidak terbatas pada melakukan pemeriksaan atau
        investigasi (baik sendiri atau bekerja sama dengan pihak yang berwajib), memblokir akun, menghapus konten
        dan/atau akun, menegur Penerima Pinjaman yang bersangkutan, melakukan pembekuan akun, melakukan kompensasi atas
        kerugian yang diderita oleh GetMo.
      </p>
      <p>
        6.6 Apabila anda memiliki pertanyaan atau masalah tentang S&amp;K ini atau persoalan yang muncul dalam S&amp;K
        ini atau di Situs Web, silakan hubungi Customer Support kami.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <h4>
        <strong>7. PENUTUP</strong>
      </h4>
      <p>
        S&amp;K ini merupakan bentuk kesepakatan yang mengikat antara Anda dengan GetMo dan mulai berlaku pada saat Anda
        mulai mengakses layanan Situs Web.
      </p>
    </div>
  );
};

export default TermsAndConditionsInd;
