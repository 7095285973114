import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const OnboardingCompanyContact = OurType.PrismaModel(
  "OnboardingCompanyContact",
  {
    onboardingId: Type.Number(),
    phone: OurType.Nullable(OurType.FullPhone({ title: "Company phone" })),
    email: OurType.Nullable(OurType.Email({ title: "Company email" })),
    finfraUserId: OurType.Nullable(Type.String()),
  }
);
