import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { Currency } from "./Currency";
import { PaymentStatus } from "./PaymentStatus";

export const XenditPayment = OurType.PrismaModel("XenditPayment", {
  referenceId: Type.String(),
  externalId: OurType.Nullable(Type.String()),
  paymentMethodId: Type.String(),
  paymentMethodDisplay: OurType.Nullable(Type.String()),
  currency: Currency,
  amount: OurType.TypeMoney(),
  status: PaymentStatus,
  failureCode: OurType.Nullable(Type.String()),
  webhookIds: Type.Array(Type.String()),
  createdAt: OurType.DateType(),
  updatedAt: OurType.DateType(),
  loanId: Type.Number(),
  xenditCustomerId: OurType.Nullable(Type.String()),
  invoiceDate: OurType.LocalDate(),
});
