import * as OurType from "../../typebox";

export const PhBusinessCategory = OurType.OurEnum(
  {
    sariSariStore: {},
    grocery: {},
    wholesaler: {},
    pharmacy: {},
    motorcycleShop: {},
    marketVendor: {},
    hardwareStore: {},
    foodBusiness: {},
    shopeeLazadaSeller: { title: "Shopee/Lazada seller" },
    fbSeller: { title: "FB seller" },
    onlineSeller: {},
    streetVendor: {},
    b2bSupplier: { title: "B2B Supplier" },
    other: {},
  },
  { title: "Phillipine business category" }
);
