import { DF } from '@getmo/common/vendor/date-fns';
import { CheckCircleOutline, RadioButtonUncheckedOutlined } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { Gauge, gaugeClasses } from '@mui/x-charts';
import { useMemo } from 'react';
import { useThemeQuery } from '../../../hooks/useThemeQuery';

export type StatementState = {
  month: Date;
  isUploaded: boolean;
};

export const UploadedStatements = ({
  accountNumber,
  monthCount,
  statementStates,
}: {
  accountNumber: string;
  monthCount: number;
  statementStates: StatementState[];
}) => {
  const { isExtraSmall, isSmall } = useThemeQuery();
  const uploadedCount = useMemo(() => statementStates.filter(({ isUploaded }) => isUploaded).length, [statementStates]);

  return (
    <>
      {!!statementStates.length && (
        <Box display="flex" alignItems="center" justifyContent="start" gap={3}>
          <Stack direction={isSmall ? 'column' : 'row'} alignItems="center" spacing={isExtraSmall ? 0 : 1}>
            <Typography textAlign="center" variant="caption" width="150px">
              Acc no.{' '}
              <Typography fontSize={isExtraSmall ? 12 : isSmall ? 14 : 16} fontWeight="600">
                {accountNumber}
              </Typography>
            </Typography>
            <Gauge
              value={uploadedCount}
              valueMax={monthCount}
              cornerRadius="50%"
              width={75}
              height={75}
              sx={(theme) => ({
                [`& .${gaugeClasses.valueText}`]: {
                  fontSize: 26,
                },
                [`& .${gaugeClasses.valueArc}`]: {
                  fill: '#2e7d32',
                },
                [`& .${gaugeClasses.referenceArc}`]: {
                  fill: theme.palette.text.disabled,
                },
                flexShrink: 0,
              })}
            />
          </Stack>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: `repeat(${isSmall ? '6' : '12'}, 1fr)`,
              gap: isSmall ? '0 0.25rem' : '0.25rem',
            }}
          >
            {statementStates.map(({ month, isUploaded }) => (
              <Box
                key={month.valueOf()}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  rowGap: isSmall ? '0' : '0.25rem',
                  height: '100%',
                }}
              >
                <Typography variant="caption">{DF.format(month, 'MMM')}</Typography>
                {isUploaded ? (
                  <CheckCircleOutline color="success" />
                ) : (
                  <RadioButtonUncheckedOutlined color="disabled" />
                )}
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};
