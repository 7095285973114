import * as OurType from "../../typebox";

export const ShopType = OurType.OurEnum({
  shopee: {},
  tokopedia: {},
  lazada: {},
  tiktok: {},
  blibli: {},
  website: {},
  youtube: {},
  instagram: {},
  facebook: {},
  twitter: { title: "X (Twitter)" },
});
