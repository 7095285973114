import { Decimal } from '../prisma';
import { Format } from './formats';
import { tz } from '@getmo/common/vendor/@date-fns/tz';
import { DF } from '@getmo/common/vendor/date-fns';
import { R } from '@getmo/common/vendor/remeda';
import {
  FormatRegistry,
  Hint,
  Kind,
  type NumberOptions,
  type ObjectOptions,
  type SchemaOptions,
  type StaticDecode,
  type StringOptions,
  type TArray,
  type TEnum,
  type TEnumValue,
  type TNull,
  type TNumber,
  type TObject,
  type TProperties,
  type TSchema,
  type TTransform,
  type TUnion,
  Type,
  TypeGuard,
  TypeRegistry,
} from '@sinclair/typebox';
import { Value } from '@sinclair/typebox/value';
import { type PascalCase, pascalCase, type SnakeCase, snakeCase } from 'scule';

export type SchemaFor<O> = O extends StaticDecode<infer S extends TSchema> ? S : never;

export const Nullable = <T extends TSchema>(schema: T) => Type.Union([Type.Null(), schema], { default: null });
export type Nullable<T extends TSchema = TSchema> = TUnion<[TNull, T]>;
export const NullableGuard = (schema: unknown): schema is Nullable =>
  TypeGuard.IsUnion(schema) && schema.anyOf.length === 2 && schema.anyOf.some((s) => TypeGuard.IsNull(s));

export const OurEnum = <T extends TEnumValue>(
  values: Record<T, SchemaOptions>,
  opts?: SchemaOptions,
): TEnum<Record<T, T>> => ({
  ...Type.Union(
    R.map(R.entries(values) as never, ([k, v]: [T, SchemaOptions]) => Type.Literal(k, v)),
    opts,
  ),
  [Hint]: 'Enum',
});
export const OurEnumNumeric = <T extends TEnumValue>(
  values: [T, SchemaOptions][],
  opts?: SchemaOptions,
): TEnum<Record<string, T>> => ({
  ...Type.Union(
    R.map(values, ([k, v]) => Type.Literal(k, v)),
    opts,
  ),
  [Hint]: 'Enum',
});

export const EnumGuard = (schema: unknown): schema is TEnum => TypeGuard.IsUnion(schema) && schema[Hint] === 'Enum';

export const FileType = () =>
  Type.Transform(Type.Any({ type: 'string', format: Format.Binary }))
    .Decode((v) => v as unknown as File)
    .Encode((v) => v as unknown as File);

export const DateType = (opts?: SchemaOptions) =>
  Type.Transform(Type.String({ format: Format.DateTime, ...opts }))
    .Decode((v) => {
      const d = new Date(v);
      if (!DF.isValid(d)) throw new Error('Invalid date');
      return d;
    })
    .Encode((v) => v.toISOString());

export const DateGuard = (schema: unknown): schema is ReturnType<typeof DateType> =>
  TypeGuard.IsTransform(schema) && TypeGuard.IsString(schema) && schema.format === Format.DateTime;

export const LocalDate = (opts?: SchemaOptions) =>
  Type.Transform(Type.String({ format: Format.Date, ...opts }))
    .Decode((v) => {
      const d = DF.parse(v, 'yyyy-MM-dd', 0);
      return d;
    })
    .Encode((v) => DF.format(v, 'yyyy-MM-dd'));

export const LocalDateGuard = (schema: unknown): schema is ReturnType<typeof LocalDate> =>
  TypeGuard.IsTransform(schema) && TypeGuard.IsString(schema) && schema.format === Format.Date;

export const TypeLocalDateTime = (opts?: SchemaOptions) =>
  Type.Transform(Type.String({ format: Format.LocalDateTime, ...opts }))
    .Decode((v) => {
      const d = new Date(v);
      if (!DF.isValid(d)) throw new Error('Invalid date');
      return d;
    })
    .Encode((v) => DF.format(v, 'yyyy-MM-dd HH:mm:ss'));

export const LocalDateTimeGuard = (schema: unknown): schema is ReturnType<typeof DateType> =>
  TypeGuard.IsTransform(schema) && TypeGuard.IsString(schema) && schema.format === Format.LocalDateTime;

export const TypeFixedZoneDateTime = (timeZone: string, opts?: SchemaOptions) =>
  Type.Transform(Type.String({ format: Format.LocalDateTime, ...opts }))
    .Decode((v) => new Date(DF.parseISO(v, { in: tz(timeZone) })))
    .Encode((v) => DF.format(v, 'yyyy-MM-dd HH:mm:ss', { in: tz(timeZone) }));

export const TypeBigInt = (opts?: NumberOptions) =>
  // Do not use Integer because Value.Convert does `value | 0` for some reason
  Type.Transform(Type.Number(opts))
    // Temp fix for decimals
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .Decode((v) => BigInt(Number.parseInt(v as any, 10)))
    .Encode((v) => Number(v.toString()));

export type TypePercent = TNumber & { numberFormat: 'percent' };
export const TypePercent = (opts?: NumberOptions) =>
  Type.Number({ numberFormat: 'percent', unit: '%', ...opts }) as TypePercent;
export const PercentGuard = (schema: unknown): schema is TypePercent =>
  TypeGuard.IsNumber(schema) && schema.numberFormat === 'percent';

export const TypeDecimal = (opts?: NumberOptions) =>
  Type.Transform(Type.Number({ numberFormat: 'decimal', ...opts }))
    .Decode((v) => new Decimal(v))
    .Encode((v) => v.toNumber());

export const DecimalString = (opts?: NumberOptions) =>
  Type.Transform(Type.String({ ...opts }))
    .Decode((v) => new Decimal(v))
    .Encode((v) => v.toString());

export type Money = Decimal;
export const Money = Decimal;
export type TMoney = ReturnType<typeof TypeMoney>;
export const TypeMoney = (opts?: NumberOptions) => TypeDecimal({ money: true, ...opts });
export const MoneyGuard = (schema: unknown): schema is TMoney =>
  TypeGuard.IsNumber(schema) && schema.numberFormat === 'decimal' && schema.money;

export const Secret = (opts?: StringOptions) => Type.String({ format: Format.Secret, ...opts });
export const SecretGuard = (schema: unknown): schema is ReturnType<typeof Secret> =>
  TypeGuard.IsString(schema) && schema.format === Format.Secret;

export const Base64 = (opts?: StringOptions) => Type.String({ format: Format.Base64, ...opts });
export const Base64Guard = (schema: unknown): schema is ReturnType<typeof Base64> =>
  TypeGuard.IsString(schema) && schema.format === Format.Base64;

export const Uri = (opts?: StringOptions) => Type.String({ format: Format.URI, ...opts });
export const Email = (opts?: StringOptions) => Type.String({ format: Format.Email, ...opts });

export const FullPhone = (opts?: StringOptions) => Object.assign(Type.String(opts), { format: Format.FullPhone });
export const FullPhoneGuard = (schema: unknown): schema is ReturnType<typeof FullPhone> =>
  TypeGuard.IsString(schema) && schema.format === Format.FullPhone;

export const Npwp = (opts?: StringOptions) => Object.assign(Type.String(opts), { format: Format.npwp });
export const NpwpGuard = (schema: unknown): schema is ReturnType<typeof Npwp> =>
  TypeGuard.IsString(schema) && schema.format === Format.npwp;

export const Multiline = (opts?: StringOptions) => Object.assign(Type.String(opts), { format: Format.Multiline });
export const MultilineGuard = (schema: unknown): schema is ReturnType<typeof Multiline> =>
  TypeGuard.IsString(schema) && schema.format === Format.Multiline;

export const RichText = (opts?: StringOptions) => Object.assign(Type.String(opts), { format: Format.RichText });
export const RichTextGuard = (schema: unknown): schema is ReturnType<typeof RichText> =>
  TypeGuard.IsString(schema) && schema.format === Format.RichText;

export const YearMonth = (opts?: StringOptions) => Type.String({ format: Format.YearMonth, ...opts });
export const YearMonthGuard = (schema: unknown): schema is ReturnType<typeof YearMonth> =>
  TypeGuard.IsString(schema) && schema.format === Format.YearMonth;

export type Point = [number, number];
export const TypePoint = (opts?: SchemaOptions) => Type.Tuple([Type.Number(), Type.Number()], opts);

FormatRegistry.Set(Format.Base64, () => true);
FormatRegistry.Set(Format.Secret, () => true);
FormatRegistry.Set(Format.DateTime, () => true);
FormatRegistry.Set(Format.Date, () => true);
FormatRegistry.Set(Format.LocalDateTime, () => true);
FormatRegistry.Set(Format.URI, () => true);
FormatRegistry.Set(Format.Email, () => true);
// const npwpRegexp = /^\d\d\.\d\d\d\.\d\d\d\.\d-\d\d\d\.\d\d\d$/;
FormatRegistry.Set(Format.npwp, () => true);
FormatRegistry.Set(Format.FullPhone, () => true);
FormatRegistry.Set(Format.Multiline, () => true);
FormatRegistry.Set(Format.RichText, () => true);
FormatRegistry.Set(Format.YearMonth, () => true);

export const PartialObject = <T extends TProperties>(properties: T, options?: ObjectOptions) =>
  Type.Partial(Type.Object(properties, options));

const customTypeKind = 'Custom';
const CustomPredicate = 'Custom predicate';
export interface CustomType<T> extends TSchema {
  [Kind]: typeof customTypeKind;
  [CustomPredicate]: (v: unknown) => boolean;
  static: T;
}
export const CustomType = <T>(base: TSchema, predicate: (v: unknown) => boolean): CustomType<T> =>
  ({
    ...base,
    [Kind]: customTypeKind,
    [CustomPredicate]: predicate,
  }) as never;
TypeRegistry.Set<CustomType<TSchema>>(customTypeKind, (schema, value) => schema[CustomPredicate](value));

export type Path<T> = T extends (infer U)[]
  ? `${number}.${Path<U>}`
  : T extends object
    ? {
        [K in keyof T & (string | number)]: K extends string ? `${K}` | `${K}.${Path<T[K]>}` : never;
      }[keyof T & (string | number)]
    : never;

export type FlatPath<T> = T extends (infer U)[]
  ? `${FlatPath<U>}`
  : T extends object
    ? {
        [K in keyof T & (string | number)]: K extends string ? `${K}` | `${K}.${FlatPath<T[K]>}` : never;
      }[keyof T & (string | number)]
    : never;

export type ObjectOnlyPath<T> = T extends object
  ? {
      [K in keyof T & (string | number)]: K extends string ? `${K}` | `${K}.${ObjectOnlyPath<T[K]>}` : never;
    }[keyof T & (string | number)]
  : never;

export type PathForSchema<T extends TSchema> =
  T extends TUnion<infer U>
    ? ObjectOnlyPathForSchema<U[number]>
    : T extends TArray<infer I>
      ? `${number}.${PathForSchema<I>}`
      : T extends TObject<infer P>
        ? {
            [K in keyof P]: K extends string ? `${K}` | `${K}.${PathForSchema<P[K]>}` : never;
          }[keyof P]
        : never;

export type FlatPathForSchema<T extends TSchema> =
  T extends TUnion<infer U>
    ? ObjectOnlyPathForSchema<U[number]>
    : T extends TArray<infer I>
      ? FlatPathForSchema<I>
      : T extends TObject<infer P>
        ? {
            [K in keyof P]: K extends string ? `${K}` | `${K}.${FlatPathForSchema<P[K]>}` : never;
          }[keyof P]
        : never;

export type ObjectOnlyPathForSchema<T extends TSchema> =
  T extends TUnion<infer U>
    ? ObjectOnlyPathForSchema<U[number]>
    : T extends TObject<infer P>
      ? {
          [K in keyof P]: K extends string ? `${K}` | `${K}.${ObjectOnlyPathForSchema<P[K]>}` : never;
        }[keyof P]
      : never;

type SchemaForKey<T extends TSchema, K extends string> =
  T extends TUnion<infer U> ? SchemaForKey<U[number], K> : T extends TObject<infer Props> ? Props[K] : never;
export type SchemaForPath<T extends TSchema, P extends string> = P extends `${infer K}.${infer R}`
  ? SchemaForPath<SchemaForKey<T, K>, R>
  : SchemaForKey<T, P>;

export const PathType = <T extends TSchema>(objSchema: T) =>
  CustomType<ObjectOnlyPathForSchema<T>>(Type.String(), (v) => {
    if (typeof v !== 'string') return false;
    let s: TSchema | null = objSchema;
    const parts: string[] = R.stringToPath(v);
    for (const key of parts) {
      s = getSchemaPath(s, key);
      if (!s) return false;
    }
    return true;
  });

export const SnakeCaseKeysTransform = <T extends Record<string, TSchema>, K extends string = keyof T & string>(
  schema: TObject<T>,
  options?: ObjectOptions,
): TTransform<TObject<{ [Key in K as SnakeCase<Key>]: T[Key] }>, { [Key in K]: StaticDecode<T[Key]> }> => {
  const encodeMap = R.mapValues(schema.properties, (_, k) => snakeCase(k)) as Record<string, string>;
  const decodeMap = R.invert(encodeMap);

  return Type.Transform(
    Type.Object(R.mapKeys(schema.properties, (k) => encodeMap[k as string] as string) as never, options),
  )
    .Decode((o) => R.mapKeys(o, (k) => decodeMap[k] as string))
    .Encode((o) => R.mapKeys(o, (k) => encodeMap[k] as string) as never) as never;
};

export const PascalCaseKeysTransform = <T extends Record<string, TSchema>, K extends string = keyof T & string>(
  schema: TObject<T>,
  options?: ObjectOptions,
): TTransform<TObject<{ [Key in K as PascalCase<Key>]: T[Key] }>, { [Key in K]: StaticDecode<T[Key]> }> => {
  const encodeMap = R.mapValues(schema.properties, (_, k) => pascalCase(k)) as Record<string, string>;
  const decodeMap = R.invert(encodeMap);

  return Type.Transform(
    Type.Object(R.mapKeys(schema.properties, (k) => encodeMap[k as string] as string) as never, options),
  )
    .Decode((o) => R.mapKeys(o, (k) => decodeMap[k] as string))
    .Encode((o) => R.mapKeys(o, (k) => encodeMap[k] as string) as never) as never;
};

export const sortOrder = Type.Union([Type.Literal('asc'), Type.Literal('desc')]);
export type SortOrder = StaticDecode<typeof sortOrder>;
export const SortOrder: Record<SortOrder, SortOrder> = {
  asc: 'asc',
  desc: 'desc',
};

export type MaybeNullable<T extends TSchema> = T | Nullable<T>;

export const createNullableGuard =
  <T extends TSchema>(predicate: (s: unknown) => s is T) =>
  (v: unknown): v is T | Nullable<T> =>
    predicate(v) || (TypeGuard.IsUnion(v) && v.anyOf.every((s) => TypeGuard.IsNull(s) || predicate(s)));

export const guardNullable = <T extends TSchema>(
  predicate: (s: unknown) => s is T,
  schema: unknown,
): schema is T | Nullable<T> =>
  predicate(schema) || (TypeGuard.IsUnion(schema) && schema.anyOf.every((s) => TypeGuard.IsNull(s) || predicate(s)));

export type UnwrapNullable<T extends TSchema> = T extends Nullable<infer S> ? S : T;
export const unwrapNullable = <T extends TSchema>(schema: T) =>
  (NullableGuard(schema) ? schema.anyOf.find((s) => !TypeGuard.IsNull(s)) : schema) as UnwrapNullable<T>;

export const cleanUndefineds = <T>(value: T): T => {
  if (R.isArray(value)) return value.map(cleanUndefineds) as T;

  if (R.isPlainObject(value)) {
    return R.fromEntries(
      R.entries(value as Record<string, unknown>)
        .filter(([, v]) => v !== undefined)
        .map(([k, v]) => [k, cleanUndefineds(v)]),
    ) as T;
  }

  return value;
};

export const stringifyQsJsons = (schema: TSchema, query: unknown) => {
  if (TypeGuard.IsObject(schema) && R.isPlainObject(query)) {
    for (const [key, propSchema] of R.entries(schema.properties)) {
      const value = query[key];
      if ((TypeGuard.IsArray(propSchema) || TypeGuard.IsObject(propSchema)) && typeof value === 'object') {
        query[key] = JSON.stringify(value);
      }
    }
  }
};

const parseQsJsons = (schema: TSchema, query: unknown) => {
  if (TypeGuard.IsObject(schema) && R.isPlainObject(query)) {
    for (const [key, propSchema] of R.entries(schema.properties)) {
      const value = query[key];
      if (
        (TypeGuard.IsObject(propSchema) || TypeGuard.IsArray(propSchema) || TypeGuard.IsRecord(propSchema)) &&
        typeof value === 'string'
      ) {
        query[key] = JSON.parse(value);
      }
    }
  }
};

export const convertQs = (schema: TSchema, query: unknown) => {
  parseQsJsons(schema, query);
  return Value.Convert(schema, query);
};

export const getSchemaPath = (schema: TSchema, path: string) => {
  let s = schema;
  for (const segment of R.stringToPath(path) as string[]) {
    if (TypeGuard.IsArray(s)) {
      s = s.items;
      if (!Number.isNaN(Number.parseInt(segment, 10))) {
        continue;
      }
    }

    if (TypeGuard.IsObject(s)) {
      const nested = s.properties[segment] ?? s.additionalProperties;
      if (typeof nested !== 'object') {
        return null;
      }
      s = nested;
    } else if (TypeGuard.IsUnion(s)) {
      const member = s.anyOf.find(
        (nested) => TypeGuard.IsObject(nested) && typeof nested.properties[segment] === 'object',
      );
      if (!member) {
        return null;
      }

      s = member.properties[segment];
    } else {
      return null;
    }
  }

  return s;
};

export type SchemaValue<T extends TSchema> = {
  schema: T;
  value: StaticDecode<T>;
};

export const guardSchemaValue = <T extends TSchema>(
  predicate: (s: unknown) => s is T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  v: SchemaValue<any>,
): v is SchemaValue<T> => predicate(v.schema);

export const guardNullableSchemaValue = <T extends TSchema>(
  predicate: (s: unknown) => s is T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  v: SchemaValue<any>,
): v is SchemaValue<T | Nullable<T>> => guardSchemaValue(createNullableGuard(predicate), v);

export const unwrapNullableSchemaValue = <T extends TSchema>(schemaValue: {
  schema: MaybeNullable<T>;
  value: StaticDecode<T>;
}) => ({ schema: unwrapNullable(schemaValue.schema), value: schemaValue.value }) as SchemaValue<T>;

export const mapSchemaTree = <S extends TSchema, T>(
  _rootSchema: S,
  cb: (opts: { property: string; path: Path<S>; schema: TSchema; children?: T[] }) => T | undefined,
): T[] => {
  const traverse = (schema: TObject, pathPrefix = ''): T[] => {
    const result: T[] = [];

    for (const property of R.keys(schema.properties)) {
      const path = pathPrefix ? `${pathPrefix}.${property}` : property;
      const s = schema.properties[property];
      const nonNullable = s && unwrapNullable(s);

      if (TypeGuard.IsObject(nonNullable)) {
        const item = cb({
          path: path as Path<S>,
          property,
          schema: nonNullable,
          children: traverse(nonNullable, path),
        });
        if (item) {
          result.push(item);
        }
      } else if (nonNullable) {
        const item = cb({
          path: path as Path<S>,
          property,
          schema: nonNullable,
        });
        if (item) {
          result.push(item);
        }
      }
    }

    return result;
  };

  const rootSchema = unwrapNullable(_rootSchema);
  return TypeGuard.IsObject(rootSchema)
    ? traverse(rootSchema)
    : ([cb({ path: '' as Path<S>, property: '', schema: rootSchema })].filter(Boolean) as T[]);
};
