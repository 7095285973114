import { $Enums } from '@getmo/common/prisma';
import { Grid, Stack } from '@mui/material';
import { Form, Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { createSelector } from 'reselect';
import * as Yup from 'yup';
import { DocumentType } from '@getmo/onboarding/shared/endpoints/documents';
import { StepContent } from '../../../components/StepContent/StepContent';
import { StepSubmitButton } from '../../../components/StepSubmitButton/StepSubmitButton';
import { summaryAction } from '../../../store/reducers/actions';
import { findDocuments, selectSummaryData } from '../../../store/reducers/summaryReducer';
import { summaryThunk } from '../../../store/reducers/thunks';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { yup } from '../../../utils/yup';
import FormikTextInput from '../../components/FormikTextInput';
import { useSnackbar } from '../../components/snackbar/useSnackbar';
import { DocumentFileInput } from '../../components/upload/DocumentFileInput';
import { useApiErrors } from '../../hooks/useApiErrors';
import { useNextStep } from '../../hooks/useNextStep';
import { defaultMimeTypes } from '../../types/mimeType';
import { Document } from '@getmo/onboarding/shared/endpoints/documents';

export type FinancialDataInitialValues = {
  topSuppliers: string;
  outstandingDebt: string;
  sales: Document[];
  balance: Document[];
  proof: Document[];
  invoice: Document[];
};

const selectInitialValues = createSelector([selectSummaryData], (data): FinancialDataInitialValues => {
  return {
    topSuppliers: data.financial.topSuppliers || '',
    outstandingDebt: data.financial.outstandingDebt || '',
    sales: findDocuments(data, DocumentType.salesScreenshot),
    balance: findDocuments(data, DocumentType.balance),
    proof: findDocuments(data, DocumentType.saleChannelOwnershipProof),
    invoice: findDocuments(data, DocumentType.customerInvoice3m),
  };
});

const FinancialData: FC = () => {
  const title = <>Financial Data</>;
  const subtitle = (
    <>
      To facilitate a smooth loan application process and ensure we offer the best financial solutions, we kindly
      request the following financial information. We want to assure you that the security of your data is our utmost
      priority.
    </>
  );

  const proofSubtitle = (
    <>
      <i>
        <strong>Online:</strong> Screenshot of E-Commerce Admin showing Name & Connected Bank
        <br />
        <strong>Offline:</strong> Photos of the Store / Factory / Distribution center
      </i>
    </>
  );

  const suppliersPlaceholder = `Top Suppliers:
  1. ABC Supplier - 25% of total costs 
  2. XYZ Manufacturing - 20% of total costs
  3. LMN Distributors - 15% of total costs
  
  Top 5 Customers:
  1. Global Retail Inc. - 30% of total revenue 
  2. International Corp. - 25% of total revenue
  3. National Distributors Co. - 15% of total revenue`;

  const { business } = useAppSelector((state) => state.summary.data);
  const isOffline =
    business.typeOfSalesChannel === $Enums.SaleChannelType.offline ||
    business.typeOfSalesChannel === $Enums.SaleChannelType.OnlineOffline;

  const isCorporate = business.legalType === $Enums.LegalType.corporate;

  const summaryValues = useAppSelector((state) => selectInitialValues(state));
  const [initialValues, setInitialValues] = useState<typeof summaryValues>(summaryValues);
  const isSummaryLoaded = useAppSelector((state) => state.summary.isLoaded);

  useEffect(() => {
    setInitialValues(summaryValues);
  }, [isSummaryLoaded]);

  const [initialErrors, setInitialErrors] = useState({});

  const validation = Yup.object({
    sales: Yup.array().min(isCorporate ? 1 : 0, yup.uploadMinFile(1)),
    proof: Yup.array().min(1, yup.uploadMinFile(1)),
    ...(isOffline
      ? {
          invoice: Yup.array().min(isCorporate ? 1 : 0, yup.uploadMinFile(1)),
          topSuppliers: Yup.string().required(),
        }
      : {}),
  });

  const { goToNextStep } = useNextStep();

  const { showError } = useSnackbar();
  const [isSubmitting, setSubmitting] = useState(false);

  const saveData = (values: typeof initialValues) => {
    dispatch(
      summaryThunk.updateFinancialData({
        topSuppliers: values.topSuppliers,
        outstandingDebt: values.outstandingDebt,
      }),
    )
      .unwrap()
      .then(() => goToNextStep())
      .catch((e) => {
        const { error, validationErrors } = useApiErrors(e);
        setInitialErrors(validationErrors);
        if (error) {
          showError(error);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const dispatch = useAppDispatch();
  const onSubmit = (values: typeof initialValues) => {
    dispatch(summaryAction.setFiles({ type: DocumentType.salesScreenshot, files: values.sales }));
    dispatch(summaryAction.setFiles({ type: DocumentType.balance, files: values.balance }));
    dispatch(summaryAction.setFiles({ type: DocumentType.saleChannelOwnershipProof, files: values.proof }));

    setInitialErrors({});
    saveData(values);
  };

  return (
    <StepContent title={title} subtitle={subtitle} width="medium">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        initialErrors={initialErrors}
        validationSchema={validation}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Grid container direction="column" gap={4}>
                <Stack>
                  {isCorporate && (
                    <DocumentFileInput
                      name="sales"
                      label="Sales Data in the Last 12 Months"
                      docType={DocumentType.salesScreenshot}
                      mimeTypes={defaultMimeTypes.sales}
                    />
                  )}
                  {isCorporate && (
                    <DocumentFileInput
                      name="balance"
                      label="Business Balance Sheet & Profit and Loss"
                      docType={DocumentType.balance}
                      mimeTypes={defaultMimeTypes.balance}
                      required={false}
                    />
                  )}
                  {isOffline && isCorporate && (
                    <DocumentFileInput
                      name="invoice"
                      label="Original Customer Invoice for the Last 3 Months"
                      docType={DocumentType.customerInvoice3m}
                      mimeTypes={defaultMimeTypes.invoice}
                    />
                  )}
                  <FormikTextInput
                    name="outstandingDebt"
                    type="text"
                    placeholder="Rp500,000,000 with a monthly repayment of Rp10,000,000 from GetMo.
                    "
                    label="Current Outstanding Debt & Amount of Monthly Repayment"
                  />
                  {isOffline && (
                    <FormikTextInput
                      name="topSuppliers"
                      type="text"
                      subLabel="Please input % share in total costs/revenue."
                      multiline
                      minRows={4}
                      placeholder={suppliersPlaceholder}
                      label="List of Top 5 Suppliers and Top 5 Customers"
                      required
                    />
                  )}
                  <DocumentFileInput
                    name="proof"
                    label="Store Ownership Proof"
                    subtitle={proofSubtitle}
                    docType={DocumentType.saleChannelOwnershipProof}
                    mimeTypes={defaultMimeTypes.proof}
                  />

                  {isCorporate && (
                    <DocumentFileInput
                      name="accountsReceivableAgingReport"
                      label="Accounts Receivable Aging Report"
                      docType={DocumentType.accountsReceivableAgingReport}
                      mimeTypes={defaultMimeTypes.accountsReceivableAgingReport}
                      required={false}
                      multiple={false}
                    />
                  )}
                </Stack>
              </Grid>
              <Grid item display="flex" justifyContent="center" py={3}>
                <StepSubmitButton isSubmitting={isSubmitting} />
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </StepContent>
  );
};

export default FinancialData;
