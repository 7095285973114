import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { Region } from "./Region";
import { LegalType } from "./LegalType";
import { SaleChannelType } from "./SaleChannelType";
import { KycStatus } from "./KycStatus";
import { OnboardingStatus } from "./OnboardingStatus";
import { PhBusinessCategory } from "./PhBusinessCategory";
import { PhCity } from "./PhCity";
import { OnboardingQualification } from "./OnboardingQualification";
import { IdCity } from "./IdCity";
import { IdBusinessCategory } from "./IdBusinessCategory";

export const Onboarding = OurType.PrismaModel("Onboarding", {
  id: Type.Number(),
  name: OurType.Nullable(Type.String()),
  phone: OurType.Nullable(OurType.FullPhone()),
  email: OurType.Nullable(OurType.Email()),
  createdAt: OurType.DateType(),
  updatedAt: OurType.DateType(),
  submittedAt: OurType.DateType(),
  status: OnboardingStatus,
  region: Region,
  utm: OurType.Nullable(Type.Any()),
  loanAmount: OurType.Nullable(OurType.TypeMoney()),
  revenue: OurType.Nullable(OurType.TypeMoney()),
  loanDuration: OurType.Nullable(Type.Number()),
  originalId: OurType.Nullable(Type.Number()),
  loanOrderNumber: Type.Number({ default: 1 }),
  shopName: OurType.Nullable(Type.String()),
  geoDivisionCode: OurType.Nullable(Type.String()),
  location: OurType.Nullable(Type.String()),
  landmark: OurType.Nullable(Type.String()),
  geoPos: Type.Array(Type.Number()),
  phCheckingAccount: OurType.Nullable(
    Type.Boolean({
      title: "Does the client have checking account under his name ",
    })
  ),
  phHasAfs: OurType.Nullable(
    Type.Boolean({ title: "Does the client have ITR/AFS for 2023?" })
  ),
  phIsBusinessOwner: OurType.Nullable(
    Type.Boolean({ title: "Does client own the business" })
  ),
  phIsReadyForVisit: OurType.Nullable(
    Type.Boolean({ title: "Is client ready for field visit" })
  ),
  phCity: OurType.Nullable(PhCity),
  phBusinessCategory: OurType.Nullable(PhBusinessCategory),
  step: OurType.Nullable(Type.String()),
  qualification: OurType.Nullable(OnboardingQualification),
  leadOwnerId: OurType.Nullable(Type.Number()),
  applicantId: OurType.Nullable(Type.Number()),
  slackReportThreadId: OurType.Nullable(Type.String()),
  idCity: OurType.Nullable(IdCity),
  idBusinessCategory: OurType.Nullable(IdBusinessCategory),
  loanPurpose: OurType.Nullable(Type.String()),
  legalType: OurType.Nullable(LegalType),
  legalCategory: Type.Array(Type.String()),
  typeOfSalesChannel: OurType.Nullable(SaleChannelType),
  yearsBusinessOperation: OurType.Nullable(
    Type.Number({ title: "How many years your business operates" })
  ),
  vidaKycStatus: OurType.Nullable(KycStatus),
  finfraUserId: OurType.Nullable(Type.String()),
  top5Suppliers: OurType.Nullable(
    OurType.Multiline({ title: "List of Top 5 Suppliers and Top 5 Customers" })
  ),
  outstandingDebt: OurType.Nullable(Type.String()),
  hubspotContactId: OurType.Nullable(Type.String()),
  leadSource: OurType.Nullable(Type.String()),
  hubspotLeadSource: Type.String({ title: "Lead Source", default: "" }),
  hubspotTrafficSource: Type.String({ title: "Traffic Source", default: "" }),
  shownName: OurType.Nullable(Type.String()),
});
