import { createReducer } from '@reduxjs/toolkit';
import { steps } from '../../onboarding/Steps';
import { updateCompletionByPath } from './actions';
import { AppState } from '../store';

export interface Page {
  name: string;
  path: string;
  condition?: (state: AppState) => boolean;
}

export interface Step {
  label: string;
  completed: boolean;
  pages: Page[];
}

interface StepsState {
  steps: Step[];
}

const initialState: StepsState = {
  steps: steps.map(({ label, content }): Step => {
    return {
      label,
      pages: content.map((child) => ({
        name: child.name,
        path: child.path,
        condition: child.condition,
      })),
      completed: false,
    };
  }),
};

export default createReducer(initialState, (builder) => {
  builder.addCase(updateCompletionByPath, (state, { payload: currentPath }) => {
    const activeStepIndex = state.steps.findIndex((step) =>
      step.pages.some(({ path }) => {
        return currentPath.match(`^${path}/?$`);
      }),
    );
    if (activeStepIndex !== -1) {
      state.steps.slice(0, activeStepIndex).forEach((step) => {
        step.completed = true;
      });

      state.steps.slice(activeStepIndex).forEach((step) => {
        step.completed = false;
      });
    }
  });
});
