import { AddLink, CloseRounded, DoneAll } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Grid, TextField, Theme } from '@mui/material';
import { Stack, SxProps } from '@mui/system';
import { Image } from '../../../components/Image';
import { ShopInput } from '../../../store/reducers/shopReducer';
import { useThemeQuery } from '../../hooks/useThemeQuery';
import { useConnectionStoreLink } from './useConnectionStoreLink';
import { SHOP_ICONS } from './StoreIcon';

interface Props {
  input: ShopInput;
  isLoading: boolean;
  isLast: boolean;
}

export const ConnectStoreLink = ({ input, isLoading, isLast }: Props) => {
  const { isSmall } = useThemeQuery();

  const {
    shop,
    isConnectRequired,
    isConnected,
    inputValue,
    inputRef,
    error,
    onURLChange,
    onBlur,
    onClickRemoveBtn,
    handleConnect,
    needAcceptEmail,
  } = useConnectionStoreLink(input);

  const buttonsSx: SxProps<Theme> = {
    height: '54px',
    bgcolor: isConnected ? 'success.light' : 'primary.light',
    '&:hover': {
      bgcolor: isConnected ? 'success.light' : 'primary.main',
      cursor: isConnected ? 'default' : 'pointer',
    },
  };

  return (
    <Grid container direction="row">
      <Grid item flex={3} ref={inputRef}>
        <TextField
          type="text"
          placeholder={input.placeholder}
          fullWidth
          error={!!error}
          helperText={error || ' '}
          value={inputValue}
          onChange={onURLChange}
          onBlur={onBlur}
          disabled={isLoading}
          inputProps={{ 'data-testid': 'input-shopLink' }}
          InputProps={{
            endAdornment: (
              <Stack display="flex" direction="row" alignItems="center">
                {shop && <Image src={SHOP_ICONS[shop.type]} width={30} height={30} />}
                {!isLast && <CloseRounded color="disabled" onClick={onClickRemoveBtn} sx={{ cursor: 'pointer' }} />}
              </Stack>
            ),
          }}
        />
      </Grid>
      <Grid item pl={2} sx={{ minWidth: isSmall ? '80px' : '185px' }}>
        {isConnectRequired && (
          <LoadingButton
            loading={input.isFetching}
            disabled={!shop}
            variant="contained"
            color={needAcceptEmail ? 'warning' : undefined}
            size="small"
            sx={buttonsSx}
            fullWidth
            disableRipple={isConnected}
            disableElevation={isConnected}
            onClick={() => !isConnected && handleConnect()}
          >
            {!!isSmall && (!isConnected ? <AddLink /> : <DoneAll />)}
            {!isSmall && (!isConnected ? 'Connect Account' : 'Connected')}{' '}
          </LoadingButton>
        )}
      </Grid>
    </Grid>
  );
};
