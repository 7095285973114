import { useEffect } from 'react';

export const useDebounce = (callback: VoidFunction, timeout: number) => {
  let timeoutId: NodeJS.Timeout | undefined = undefined;

  useEffect(() => {
    timeoutId = setTimeout(() => callback(), timeout);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [callback, timeout]);

  const clearDebounce = () => clearTimeout(timeoutId);

  return clearDebounce;
};
