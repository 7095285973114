import { ErrorResponse, ExceptionResponse, ValidationErrorResponse } from '../../api';
import { formik } from '../../utils/formik';

export const useApiErrors = (e: unknown) => {
  let error = '';
  let validationErrors = {};

  if (e instanceof ErrorResponse) {
    const { response } = e as ErrorResponse;
    if (response.message && typeof response.message === 'string') {
      error = response.message;
    }

    if (Object.entries(response).every(([, value]) => Array.isArray(value))) {
      validationErrors = formik.parseApiErrors(response as ValidationErrorResponse);
    }
  }

  if (e instanceof ExceptionResponse) {
    error = e.response.message;
  }

  return { error, validationErrors };
};
