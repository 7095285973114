import * as OurType from "../../typebox";

export const Bank = OurType.OurEnum({
  bca: { title: "BCA" },
  bri: { title: "BRI" },
  bni: { title: "BNI" },
  mandiri: {},
  seabank: { title: "SeaBank" },
  ocbc: { title: "OCBC" },
  cimbNiaga: { title: "CIMB Niaga" },
  other: {},
});
