import ReportIcon from '@mui/icons-material/Report';
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, List, ListItem, Typography } from '@mui/material';
import { Fragment, useState } from 'react';
import { personVerificationReason } from '@getmo/onboarding/shared/endpoints/personVerification';
import { StaticDecode } from '@sinclair/typebox';

export type PersonVerificationReason = StaticDecode<typeof personVerificationReason>;
export const useVerificationModal = (
  reasons: PersonVerificationReason[],
  retryKyc: VoidFunction,
  retrySelfie: VoidFunction,
) => {
  const [open, setOpen] = useState(false);

  const errors: Record<PersonVerificationReason, string[]> = {
    imgManipulationScore: [
      'Ensure that your entire face is visible and not partially obscured by hair, glasses, mask, or any other objects ',
      'Keep a neutral expression, avoiding smiling, frowning, or making any other exaggerated facial expressions',
      'Ensure that your face is straight-on and positioned in the center of the frame.',
    ],
    liveness: [
      'Ensure that the image is well-lit and not too dark or too bright',
      'Ensure that the background is plain and neutral, without any distracting elements',
      'Avoid taking the blur selfie',
      'Avoid taking the selfie in front of a bright light source',
    ],
  };

  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };
  const retry = () => {
    if (reasons.length) {
      retrySelfie();
      closeModal();
      return;
    }
    retryKyc();
  };

  const content = (
    <Fragment>
      <ReportIcon color="warning" sx={{ alignSelf: 'center', paddingTop: '15px', fontSize: '3rem' }} />
      <DialogContent>
        <Typography mb={1} variant="body1" fontWeight={500} textAlign="center">
          Please, retry your verification
        </Typography>
        <Box display="flex" flexDirection="column" gap={1}>
          {reasons.map(
            (reason, rIdx) =>
              errors[reason] && (
                <Box key={rIdx}>
                  <Alert variant="outlined" severity="warning" color="warning">
                    {reason === 'imgManipulationScore'
                      ? 'Folow Face Appearance Guidelines:'
                      : 'Check the quality of the captured selfie'}
                    <List dense>
                      {(errors[reason] ?? []).map((e, idx) => (
                        <ListItem key={idx} disableGutters>
                          • {e}
                        </ListItem>
                      ))}
                    </List>
                  </Alert>
                </Box>
              ),
          )}
          {!reasons.length && (
            <Box>
              <Alert variant="outlined" severity="warning" color="warning" sx={{ alignItems: 'center' }}>
                Check if you have correctly entered your KTP number, name, and date of birth
              </Alert>
            </Box>
          )}
        </Box>
      </DialogContent>
      <Box>
        <DialogActions sx={{ padding: '10px 20px' }}>
          <Button size="small" onClick={closeModal} fullWidth>
            Cancel
          </Button>
          <Button variant="contained" color="primary" size="small" onClick={retry} fullWidth>
            Retry
          </Button>
        </DialogActions>
      </Box>
    </Fragment>
  );

  const modal = (
    <Dialog keepMounted open={open} onClose={closeModal} maxWidth={'xs'}>
      {content}
    </Dialog>
  );

  return { modal, openModal, closeModal };
};
