import { useCallback } from 'react';
import { useSnackbar } from '../components/snackbar/useSnackbar';

export const useOpenWindow = () => {
  const { showWarning } = useSnackbar();

  const openWindow = useCallback(
    (url: string, target = '_blank') => {
      const openedWindow = window.open(url, target);
      if (!openedWindow || openedWindow.closed || typeof openedWindow.closed == 'undefined') {
        showWarning('Please check your pop-up blocker settings and try again');
      }
    },
    [showWarning],
  );

  return { openWindow };
};
