import { createEndpoint } from '@getmo/common/endpoint';
import { Type } from '@getmo/common/vendor/typebox';

export const sendOtpEndpoint = createEndpoint({
  anonymous: true,
  method: 'post',
  path: '/login/otp/send',
  body: Type.Object({ phone: Type.String() }),
  response: Type.Object({
    sessionId: Type.Optional(Type.String()),
  }),
});

export const checkOtpEndpoint = createEndpoint({
  anonymous: true,
  method: 'post',
  path: '/login/otp/check',
  body: Type.Object({
    phone: Type.String(),
    sessionId: Type.Optional(Type.String()),
    code: Type.String(),
  }),
  response: Type.Object({
    token: Type.String(),
  }),
});
