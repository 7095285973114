import { Box, Card, CardContent, CardHeader, Grid, Paper, Rating, Stack, TextField, Typography } from '@mui/material';
import { ErrorMessage, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Mail from '../../assets/mail.svg';
import Thanks from '../../assets/thank-you.svg';
import { Image } from '../../components/Image';
import { StepContent } from '../../components/StepContent/StepContent';
import { StepSubmitButton } from '../../components/StepSubmitButton/StepSubmitButton';
import { useSnackbar } from '../components/snackbar/useSnackbar';
import { useApiErrors } from '../hooks/useApiErrors';
import { useAppDispatch } from '../../store/store';
import { summaryAction } from '../../store/reducers/actions';
import { useBrandSettings } from '../hooks/useBrandSettings';
import { callApi } from '../../api';
import { feedbackEndpoint } from '@getmo/onboarding/shared/endpoints/feedback';

export const Finish = () => {
  const title = [<>Onboarding Completed</>];
  const brandSettings = useBrandSettings();
  const subtitle = (
    <>
      Thank you for registering with {brandSettings.name}! We will contact you via email or WhatsApp within
      3&nbsp;business days with the results of your loan application.
    </>
  );

  const thankMsg =
    'Thank you for taking the time to share your feedback with us! Your input helps us enhance our services to better meet your needs.';

  interface InitialValues {
    mark: number | null;
    text: string;
  }

  const initialValues: InitialValues = {
    mark: null,
    text: '',
  };

  const validation = Yup.object({
    mark: Yup.number().required('Please, provide a rating between 1 and 10'),
  });

  const [isFeedback, setIsFeedback] = useState(false);

  const { showError } = useSnackbar();
  const onSubmit = async (values: InitialValues) => {
    try {
      await callApi(feedbackEndpoint, {
        body: {
          ...values,
          mark: values.mark || 0,
        },
      });
      setIsFeedback(true);
    } catch (e) {
      const { error } = useApiErrors(e);
      showError(error);
    }
  };

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(summaryAction.setFinished());
  }, []);

  return (
    <>
      <Image src={Mail} sx={{ maxWidth: '100px', pt: '1rem', mb: '0' }} />
      <StepContent title={title} width="extended" subtitle={subtitle}>
        <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validation}>
          {({ handleSubmit, setFieldValue, values }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Grid container direction="column" flex={1} alignItems="center">
                  <Card component={Paper} elevation={3}>
                    <CardHeader
                      title={
                        <Typography color="white" variant="h6">
                          {isFeedback ? "We've got your feedback!" : 'Feel free to give us your feedback!'}
                        </Typography>
                      }
                      sx={{ backgroundColor: 'primary.light' }}
                    />
                    <CardContent>
                      {!isFeedback && (
                        <>
                          <Typography variant="body1" sx={{ mb: '1rem' }}>
                            On a scale of 1 to 10, how satisfied are you with your onboarding experience?
                          </Typography>
                          <Rating
                            name="mark"
                            max={10}
                            sx={{ mb: '1rem' }}
                            size="large"
                            value={values.mark}
                            onChange={(e, value) => setFieldValue('mark', value)}
                          />
                          <Box sx={{ color: 'red' }} mt={0} mb={0.5}>
                            <ErrorMessage name="mark" />
                          </Box>

                          <Typography variant="body1" sx={{ mb: '1rem' }}>
                            Would you like to add a comment?
                          </Typography>
                          <Grid item>
                            <TextField
                              inputProps={{ 'data-testid': 'comment' }}
                              multiline
                              maxRows={8}
                              minRows={3}
                              fullWidth
                              value={values.text}
                              onChange={(e) => setFieldValue('text', e.target.value)}
                            />
                          </Grid>
                        </>
                      )}
                      {isFeedback && (
                        <Stack alignItems="center" pb={2}>
                          <Image src={Thanks} sx={{ maxWidth: '100px', pt: '1rem', mb: '1rem', alignSelf: 'center' }} />
                          <Typography variant="subtitle1" color="text.secondary" textAlign="center">
                            {thankMsg}
                          </Typography>
                        </Stack>
                      )}
                    </CardContent>
                    {!isFeedback && (
                      <Grid item display="flex" justifyContent="end" p={2}>
                        <StepSubmitButton text="send" sx={{ width: 'fit-content', borderRadius: '4px' }} />
                      </Grid>
                    )}
                  </Card>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </StepContent>
    </>
  );
};
