import { Grid } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import { Form, Formik } from 'formik';
import { FC, useState } from 'react';
import * as Yup from 'yup';
import { ContactType } from '@getmo/onboarding/shared/endpoints/onboarding';
import { DocumentType } from '@getmo/onboarding/shared/endpoints/documents';
import { Document } from '@getmo/onboarding/shared/endpoints/documents';
import { ContactData } from '@getmo/onboarding/shared/endpoints/onboarding';
import { StepContent } from '../../../components/StepContent/StepContent';
import { StepSubmitButton } from '../../../components/StepSubmitButton/StepSubmitButton';
import { summaryAction } from '../../../store/reducers/actions';
import { findContact, findDocuments, selectSummaryData } from '../../../store/reducers/summaryReducer';
import { UpdateContactData, summaryThunk } from '../../../store/reducers/thunks';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { phone } from '../../../utils/phone';
import { yup } from '../../../utils/yup';
import FormikTextInput from '../../components/FormikTextInput';
import { InputEmailIcon } from '../../components/input/icons/InputEmailIcon';
import { InputPhoneIcon } from '../../components/input/icons/InputPhoneIcon';
import { useSnackbar } from '../../components/snackbar/useSnackbar';
import { DocumentFileInput } from '../../components/upload/DocumentFileInput';
import { useApiErrors } from '../../hooks/useApiErrors';
import { useNextStep } from '../../hooks/useNextStep';
import { defaultMimeTypes as defaultMimes } from '../../types/mimeType';

const selectInitialValues = createSelector([selectSummaryData], (summary): InitialValues => {
  const contact = findContact<ContactData>(summary, ContactType.Company);
  return {
    email: contact?.email || '',
    phone: contact?.phone?.replace(/^\+?62/, '') || '',
    files: {
      nib: findDocuments(summary, DocumentType.nib),
      npwpCorporate: findDocuments(summary, DocumentType.npwpCorporate),
      sk: findDocuments(summary, DocumentType.sk),
      trademarkCertificate: findDocuments(summary, DocumentType.trademarkCertificate),
      businessLicense: findDocuments(summary, DocumentType.businessPermit),
      companyProfile: findDocuments(summary, DocumentType.companyProfile),
    },
  };
});

interface InitialValues {
  email: string;
  phone: string;
  files: {
    nib: Document[];
    npwpCorporate: Document[];
    sk: Document[];
    trademarkCertificate: Document[];
    businessLicense: Document[];
    companyProfile: Document[];
  };
}

const CompanyInformation: FC = () => {
  const title = <>Company Information</>;
  const subtitle = (
    <>
      Please provide the following essential details to assist us in tailoring the perfect financing solution for your
      business.
    </>
  );

  const { goToNextStep } = useNextStep();
  const initialValues = useAppSelector((state) => selectInitialValues(state));

  const validation = Yup.object({
    email: yup.validateEmail().required(),
    phone: yup.validatePhone(),
    files: Yup.object({
      nib: Yup.array().min(1, yup.uploadMinFile(1)),
      npwpCorporate: Yup.array().min(1, yup.uploadMinFile(1)),
      sk: Yup.array().min(1, yup.uploadMinFile(1)),
    }),
  });

  const [isSubmitting, setSubmitting] = useState(false);
  const [initialErrors, setInitialErrors] = useState({});
  const { showError } = useSnackbar();
  const dispatch = useAppDispatch();

  const onSubmit = async (values: typeof initialValues) => {
    setInitialErrors({});
    setSubmitting(true);

    const data: UpdateContactData = {
      ...values,
      type: ContactType.Company,
    };

    dispatch(summaryThunk.updateContactData(data))
      .unwrap()
      .then(() => {
        dispatch(summaryAction.setFiles({ type: DocumentType.nib, files: values.files.nib }));
        dispatch(summaryAction.setFiles({ type: DocumentType.npwpCorporate, files: values.files.npwpCorporate }));
        dispatch(summaryAction.setFiles({ type: DocumentType.sk, files: values.files.sk }));
        dispatch(
          summaryAction.setFiles({ type: DocumentType.trademarkCertificate, files: values.files.trademarkCertificate }),
        );
        dispatch(summaryAction.setFiles({ type: DocumentType.businessPermit, files: values.files.businessLicense }));
        dispatch(summaryAction.setFiles({ type: DocumentType.companyProfile, files: values.files.companyProfile }));
        goToNextStep();
      })
      .catch((e) => {
        const { error, validationErrors } = useApiErrors(e);
        setInitialErrors(validationErrors);
        if (error) {
          showError(error);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <StepContent title={title} subtitle={subtitle} width="medium">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        initialErrors={initialErrors}
        validationSchema={validation}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Grid container direction="column" gap={0} pt={0}>
                <Grid item>
                  <DocumentFileInput
                    name="files.nib"
                    label="Company NIB"
                    docType={DocumentType.nib}
                    mimeTypes={defaultMimes.nib}
                  />
                </Grid>
                <Grid item>
                  <DocumentFileInput
                    name="files.npwpCorporate"
                    label="Company NPWP"
                    docType={DocumentType.npwpCorporate}
                    mimeTypes={defaultMimes.npwpCorporate}
                    extractImageFromPdf="npwp"
                  />
                </Grid>
                <Grid item>
                  <FormikTextInput
                    name="email"
                    type="email"
                    label="Company Email"
                    transform={(v) => v.trim()}
                    InputProps={{ startAdornment: <InputEmailIcon /> }}
                    required
                  />
                </Grid>
                <Grid item sx={{ mb: 1 }}>
                  <FormikTextInput
                    name="phone"
                    type="text"
                    label="Company Phone Number"
                    transform={(v) => phone.prettify(v)}
                    untransform={(v) => phone.depretty(v)}
                    InputProps={{ startAdornment: <InputPhoneIcon /> }}
                    required
                  />
                </Grid>
                <Grid item>
                  <DocumentFileInput
                    name="files.sk"
                    label="Company SK or Akta Notaris (for CV)"
                    docType={DocumentType.sk}
                    mimeTypes={defaultMimes.sk}
                  />
                </Grid>
                <Grid item>
                  <DocumentFileInput
                    name="files.trademarkCertificate"
                    label="Trademark Certificate"
                    docType={DocumentType.trademarkCertificate}
                    mimeTypes={defaultMimes.trademarkCertificate}
                    required={false}
                  />
                </Grid>
                <Grid item>
                  <DocumentFileInput
                    name="files.businessLicense"
                    label="Business License"
                    subtitle="Example: Export License, PIRT, SIUP, etc."
                    docType={DocumentType.businessPermit}
                    mimeTypes={defaultMimes.businessLicense}
                    required={false}
                  />
                </Grid>
                <Grid item>
                  <DocumentFileInput
                    name="files.companyProfile"
                    label="Company Profile"
                    docType={DocumentType.companyProfile}
                    mimeTypes={defaultMimes.companyProfile}
                    required={false}
                  />
                </Grid>
                <Grid item display="flex" justifyContent="center" pt={2}>
                  <StepSubmitButton isSubmitting={isSubmitting} />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </StepContent>
  );
};

export default CompanyInformation;
