import { createReducer } from '@reduxjs/toolkit';
import { loanThunk } from './thunks';

interface State {
  loanInfo?: {
    minAmount: number;
    maxAmount: number;
    minPeriod: number;
    maxPeriod: number;
    minFee: number;
    maxFee: number;
  };
}

const initialState: State = {};

export default createReducer(initialState, (builder) => {
  builder.addCase(loanThunk.get.fulfilled, (state, { payload }) => {
    state.loanInfo = payload;
  });
});
