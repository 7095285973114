import { GeoDivision, RelGeoDivisionParent } from '../models/dictionaries';
import { messageResponse } from './common';
import { document } from './documents';
import { createEndpoint } from '@getmo/common/endpoint';
import * as DB from '@getmo/common/prisma/typebox';
import { Nullable, RelWithRelations, TypePoint, WithRelations } from '@getmo/common/typebox';
import { type StaticDecode, Type } from '@getmo/common/vendor/typebox';

export { LeadSource } from '@getmo/common/shared/models/onboarding';

const businessInfoData = Type.Object({
  name: Type.String(),
  legalType: DB.LegalType,
  typeOfSalesChannel: DB.SaleChannelType,
  category: Type.Array(Type.String()),
  idBusinessCategory: DB.IdBusinessCategory,
  idCity: DB.IdCity,
  yearsBusinessOperation: Type.Number(),
  revenue: Type.Number(),
  loanAmount: Type.Number(),
  loanPurpose: Type.String(),
  loanDuration: Type.Number(),
  phBusinessCategory: DB.PhBusinessCategory,
  phCity: DB.PhCity,
  geoDivisionCode: Type.String(),
  location: Type.String(),
  landmark: Type.String(),
  geoPos: TypePoint(),
  phCheckingAccount: Type.Boolean(),
  phHasAfs: Type.Boolean(),
  phIsBusinessOwner: Type.Boolean(),
  phIsReadyForVisit: Type.Boolean(),

  // Backward compatibility
  businessCategoryPh: DB.PhBusinessCategory,
  checkingAccountPh: Type.Boolean(),
  isBusinessOwnerPh: Type.Boolean(),
  isReadyForVisitPh: Type.Boolean(),
});
export type BusinessInfoData = StaticDecode<typeof businessInfoData>;

export const businessSaveEndpoint = createEndpoint({
  method: 'post',
  path: '/user/data/business/save',
  body: Type.Partial(businessInfoData),
  response: messageResponse,
});

export const contactType = Type.Union([
  Type.Literal('applicant'),
  Type.Literal('company'),
  Type.Literal('inversor'),
  Type.Literal('emergency'),
  Type.Literal('coowner'),
]);
export type ContactType = StaticDecode<typeof contactType>;
export const ContactType = {
  Applicant: 'applicant',
  Company: 'company',
  Inversor: 'inversor',
  Emergency: 'emergency',
  Coowner: 'coowner',
} satisfies Record<'Applicant' | 'Company' | 'Inversor' | 'Emergency' | 'Coowner', ContactType>;

export const contactInfo = Type.Object({
  id: Type.Optional(Type.Number()),
  name: Type.Optional(Nullable(Type.String())),
  phone: Type.Optional(Nullable(Type.String())),
  email: Type.Optional(Nullable(Type.String())),
  relation: Type.Optional(Nullable(Type.String())),
  documentIds: Type.Optional(Type.Array(Type.Number())),
  leadSource: Type.Optional(Nullable(Type.String())),
  role: Type.Optional(Nullable(Type.String())),
});
export type ContactInfo = StaticDecode<typeof contactInfo>;

export const contactInfoEndpoint = createEndpoint({
  method: 'post',
  path: '/user/data/contact/:type',
  params: Type.Object({
    type: contactType,
  }),
  body: contactInfo,
  response: contactInfo,
});

export const coownerDeleteEndpoint = createEndpoint({
  method: 'delete',
  path: '/user/data/coowners/:coownerId',
  params: Type.Object({
    coownerId: Type.Number(),
  }),
  response: messageResponse,
});

const loanPreviewInfo = Type.Object({
  minAmount: Type.Number(),
  maxAmount: Type.Number(),
  minPeriod: Type.Number(),
  maxPeriod: Type.Number(),
  minFee: Type.Number(),
  maxFee: Type.Number(),
});
export type LoanPreviewInfo = StaticDecode<typeof loanPreviewInfo>;

export const loanGetEndpoint = createEndpoint({
  method: 'get',
  path: '/user/application/loanPreview',
  response: loanPreviewInfo,
});

export const stepSetEndpoint = createEndpoint({
  method: 'post',
  path: '/user/step',
  body: Type.Object({ step: Type.String() }),
  response: Type.Void(),
});

const contactData = Type.Object({
  name: Type.Optional(Nullable(Type.String())),
  email: Type.Optional(Nullable(Type.String())),
  phone: Type.Optional(Nullable(Type.String())),
  type: contactType,
});
export type ContactData = StaticDecode<typeof contactData>;

const emergencyContactData = Type.Object({
  name: Type.String(),
  phone: Type.String(),
  type: Type.Literal('emergency'),
  relation: Type.String(),
});
export type EmergencyContactData = StaticDecode<typeof emergencyContactData>;

const businessData = Type.Object({
  name: Type.String(),
  legalType: Nullable(DB.LegalType),
  typeOfSalesChannel: Nullable(DB.SaleChannelType),
  category: Nullable(Type.Array(Type.String())),
  idBusinessCategory: Nullable(DB.IdBusinessCategory),
  idCity: Nullable(DB.IdCity),
  yearsBusinessOperation: Nullable(Type.Number()),
  revenue: Nullable(Type.Number()),
  loanAmount: Nullable(Type.Number()),
  loanPurpose: Nullable(Type.String()),
  loanDuration: Nullable(Type.Number()),
  phBusinessCategory: Nullable(DB.PhBusinessCategory),
  phCity: Nullable(DB.PhCity),
  geoDivision: Nullable(
    WithRelations(
      GeoDivision,
      RelWithRelations(RelGeoDivisionParent, RelWithRelations(RelGeoDivisionParent, RelGeoDivisionParent)),
    ),
  ),
  location: Nullable(Type.String()),
  landmark: Nullable(Type.String()),
  geoPos: Type.Array(Type.Number()),
  phCheckingAccount: Nullable(Type.Boolean()),
  phHasAfs: Nullable(Type.Boolean()),
  phIsBusinessOwner: Nullable(Type.Boolean()),
  phIsReadyForVisit: Nullable(Type.Boolean()),
  qualification: Nullable(DB.OnboardingQualification),

  // Backward compatibility
  businessCategoryId: Type.Optional(Nullable(DB.IdBusinessCategory)),
  cityId: Type.Optional(Nullable(DB.IdCity)),
  businessCategoryPh: Type.Optional(Nullable(DB.PhBusinessCategory)),
  cityPh: Type.Optional(Nullable(DB.PhCity)),
  checkingAccountPh: Type.Optional(Nullable(Type.Boolean())),
  isBusinessOwnerPh: Type.Optional(Nullable(Type.Boolean())),
  isReadyForVisitPh: Type.Optional(Nullable(Type.Boolean())),
});
export type BusinessData = StaticDecode<typeof businessData>;

const coownerData = Type.Object({
  id: Type.Number(),
  name: Type.String(),
  role: Type.String(),
  phone: Type.String(),
  email: Type.String(),
  type: Type.Literal('coowner'),
  documents: Type.Array(document),
});
export type CoownerContactData = StaticDecode<typeof coownerData>;

const financialResult = Type.Object({
  topSuppliers: Nullable(Type.String()),
  outstandingDebt: Nullable(Type.String()),
});

const summary = Type.Object({
  onboardingId: DB.Onboarding.properties.id,
  contactData: Type.Array(Type.Union([emergencyContactData, coownerData, contactData])),
  business: businessData,
  financial: financialResult,
  shops: Type.Array(
    Type.Object({
      id: Type.Number(),
      isSynced: Type.Boolean(),
      link: Type.String(),
      type: Nullable(Type.String()),
    }),
  ),
  documents: Type.Array(document),
  kyc: Type.Object({
    status: Type.String(),
    dob: Type.Optional(DB.PersonUnverifiedData.properties.dob),
    fullName: Type.Optional(DB.PersonUnverifiedData.properties.fullName),
    personalIdNumber: Type.Optional(DB.PersonUnverifiedData.properties.personalIdNumber),
    phPersonalIdType: Type.Optional(DB.PersonUnverifiedData.properties.phPersonalIdType),
    gender: Type.Optional(DB.PersonUnverifiedData.properties.gender),
  }),
  step: Type.Optional(Type.String()),
});
export type Summary = StaticDecode<typeof summary>;

export const summaryEndpoint = createEndpoint({
  method: 'get',
  path: '/user/data/summary/get',
  response: summary,
});

export const finishEndpoint = createEndpoint({
  method: 'post',
  path: '/user/application/finish',
  response: Type.Object({}),
});
