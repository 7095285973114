import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const XenditCustomer = OurType.PrismaModel("XenditCustomer", {
  externalId: Type.String(),
  linkedAccountTokenId: OurType.Nullable(Type.String()),
  paymentMethodId: OurType.Nullable(Type.String()),
  paymentMethodDisplay: OurType.Nullable(Type.String()),
  onboardingId: Type.Number(),
});
