import * as OurType from "../../typebox";

export const PhCity = OurType.OurEnum(
  {
    malabon: {},
    navotas: {},
    caloocan: {},
    valenzuela: {},
    taguig: {},
    paranaque: {},
    mandaluyong: {},
    pasay: {},
    pasig: {},
    makati: {},
    manila: {},
    marikina: {},
    lasPinas: {},
    qc: { title: "Quezon City" },
    pateros: {},
    sanJuan: {},
    muntinlupa: {},
    bulacan: {},
    notNcr: { title: "Not NCR" },
  },
  { title: "Phillipine city" }
);
