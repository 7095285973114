import { LeadSource } from '@getmo/onboarding/shared/endpoints/onboarding';
import { ThemeOptions } from '@mui/material';
import Danabijak from '../../assets/logos/logoDanabijak.svg';
import GetMo from '../../assets/logos/logoGetmo.svg';
import Juragan from '../../assets/logos/logoJuragancod.jpeg';
import DanabijakMobile from '../../assets/logos/logoMobileDanabijak.png';
import GetMoMobile from '../../assets/logos/logoMobileGetmo.svg';
import JuraganMobile from '../../assets/logos/logoMobileJuragan.jpg';
import PondoPinoy from '../../assets/logos/pondoPinoy.png';
import {
  themeSettings,
  themeSettingsDanabijak,
  themeSettingsJuragan,
  themeSettingsPondoPinoy,
} from '../../theme/themeSettings';
import { TermsAndConditions } from '../pages/welcome/TermsAndConditions';
import TermsAndConditionsIndDanabijak from '../pages/welcome/termsTexts/TermsAndConditionsIndDanabijak';
import TermsAndConditionsIndJuragan from '../pages/welcome/termsTexts/TermsAndConditionsIndJuragan';
import TermsAndConditionsPondoPinoy from '../pages/welcome/termsTexts/TermsAndConditionsPondoPinoy';
import { WelcomePartnerSign } from '../pages/welcome/WelcomePartnerSign';

export type Favicon = {
  href: string;
  rel: string;
  type?: string;
};

type BrandSettings = {
  name: string;
  source?: string;
  termsConditions: JSX.Element;
  partnerSign?: JSX.Element;
  themeSettings: ThemeOptions;
  favicons: Favicon[];
  logo: {
    desktop: string;
    mobile: string;
  };
  region?: 'id' | 'ph';
  consent?: string;
  applicationInUnderwritingText?: JSX.Element;
};

type BrandSettingsWithHostname = BrandSettings & { hostname: RegExp };

const getmoSettings: BrandSettings = {
  name: 'GetMo',
  termsConditions: <TermsAndConditions />,
  themeSettings: themeSettings,
  favicons: [
    {
      href: 'https://uploads-ssl.webflow.com/64b98f97fc88521ac017350c/64ce38c59c11711e177d2f13_32.svg',
      rel: 'icon',
      type: 'image/x-icon',
    },
    {
      href: 'https://uploads-ssl.webflow.com/64b98f97fc88521ac017350c/64ce38c761ee35ca75b39ef9_256.svg',
      rel: 'apple-touch-icon',
    },
  ],
  logo: {
    desktop: GetMo,
    mobile: GetMoMobile,
  },
};

const brandsSettings: BrandSettingsWithHostname[] = [
  {
    favicons: [
      {
        href: 'https://danabijak.com/favicon.ico',
        rel: 'icon',
        type: 'image/x-icon',
      },
    ],
    hostname: /apply.danabijak.com/g,
    source: LeadSource.Danabijak,
    logo: {
      desktop: Danabijak,
      mobile: DanabijakMobile,
    },
    name: 'Danabijak',
    termsConditions: <TermsAndConditionsIndDanabijak />,
    partnerSign: <WelcomePartnerSign />,
    themeSettings: themeSettingsDanabijak,
  },
  {
    favicons: [
      {
        href: 'https://juragancod.com/favicon.ico',
        rel: 'icon',
        type: 'image/x-icon',
      },
    ],
    hostname: /juragan.getmo.id$/g,
    source: LeadSource.Juragan,
    logo: {
      desktop: Juragan,
      mobile: JuraganMobile,
    },
    name: 'JuraganCOD',
    termsConditions: <TermsAndConditionsIndJuragan />,
    themeSettings: themeSettingsJuragan,
  },
  {
    favicons: [],
    hostname: /pondopinoy/g,
    logo: {
      desktop: PondoPinoy,
      mobile: PondoPinoy,
    },
    name: 'Pondo Pinoy',
    termsConditions: <TermsAndConditionsPondoPinoy />,
    themeSettings: themeSettingsPondoPinoy,
    region: 'ph',
    consent: 'I agree to allow Pondo Pinoy Lending Corp. to store and process my personal data.',
    applicationInUnderwritingText: (
      <>
        To get the status of your application you can email <a href="mailto:loans@pondopinoy.ph">loans@pondopinoy.ph</a>
        , call <a href="tel:+6309755652318">+6309755652318</a> or chat in fb group -{' '}
        <a href="https://www.facebook.com/profile.php?id=100094102530841">
          https://www.facebook.com/profile.php?id=100094102530841
        </a>
      </>
    ),
  },
];

export const useBrandSettings = (): BrandSettings => {
  return brandsSettings.find(({ hostname }) => window.location.hostname.match(hostname)) ?? getmoSettings;
};

export const usePhoneCode = () => (useBrandSettings().region === 'ph' ? '63' : '62');
