import { noTranslateClassName } from '../../../../utils/weglot';

export const VidaTermsAndConditions = () => {
  return (
    <div className={noTranslateClassName}>
      <h3>VIDA Ketentuan Privasi</h3>
      <p>
        Sehubungan dengan verifikasi identitas untuk tujuan GetMo Onboarging, data pribadi Anda berupa data demografi
        dan/atau biometrik akan diperiksa kesesuaiannya, oleh PT Indonesia Digital Identity (VIDA) sebagai mitra kami,
        dengan data yang tercatat pada sistem instansi pemerintahan yang berhak mengeluarkan identitas tersebut. Apabila
        data pribadi Anda terverifikasi kesesuaiannya, maka VIDA sebagai Penyelenggara Sertifikasi Elektronik
        tersertifikasi oleh Kementerian Telekomunikasi dan Informasi, akan menerbitkan sertifikat elektronik sebagai
        bukti bahwa data pribadi Anda telah diverifikasi dan sesuai dengan data yang tercatat pada sistem instansi yang
        berhak mengeluarkan identitas tersebut. Oleh karenanya, Anda menjamin keakuratan data pribadi yang Anda sediakan
        dan setuju atas pemrosesan data pribadi Anda tersebut untuk tujuan penerbitan sertifikat elektronik serta
        layanan lain yang melekat pada sertifikat elektronik yang dilakukan oleh VIDA. Anda telah membaca, memahami, dan
        setuju untuk terikat pada syarat dan ketentuan layanan Penyelenggara Sertifikasi Elektronik yang terdapat pada{' '}
        <a href="https://dl.vida.id/VIDA-Subscriber_Agreement.pdf" target="_blank" rel="noreferrer">
          Perjanjian Kepemilikan Sertifikat Elektronik
        </a>{' '}
        (Subscriber Agreement),{' '}
        <a href="https://dl.vida.id/VIDA-Privacy_Policy_for_Digital_Certificate.pdf" target="_blank" rel="noreferrer">
          Kebijakan Privasi PSrE{' '}
        </a>{' '}
        (CA Privacy Policy), serta{' '}
        <a href="https://dl.vida.id/VIDA-CPS.pdf" target="_blank" rel="noreferrer">
          Pernyataan Penyelenggaraan Sertifikasi Elektronik{' '}
        </a>
        (Certification Practice Statement) VIDA yang dapat diakses melalui{' '}
        <a href="https://www.repo.vida.id/" target="_blank" rel="noreferrer">
          https://repo.vida.id
        </a>
      </p>{' '}
      <p>ENG ver: </p>
      <p>
        In terms of the identity verification process for the purpose of GetMo Onboarging, your personal data in the
        form of demographic and/or biometric data will be checked for conformity, by PT Indonesia Digital Identity
        (VIDA) as our partner, with data recorded in the system of the government agency authorised to issue the
        relevant identity. If your personal data is verified for conformity, then VIDA as the Certification Authority
        certified by the Ministry of Communication and Informatics, will issue a digital certificate as evidence that
        your personal data has been verified and is in accordance with the data recorded in the system of the government
        agency authorised to issue the identity. Therefore, you guarantee the accuracy of the personal data you provide
        and agree to the processing of your personal data for the purpose of issuing digital certificates and other
        services attached to digital certificates performed by VIDA. You have read, understood, and agreed to be bound
        by the terms and conditions of the Certification Authority&apos;s service contained in the{' '}
        <a href="https://dl.vida.id/VIDA-Subscriber_Agreement.pdf" target="_blank" rel="noreferrer">
          Subscriber Agreement
        </a>
        ,{' '}
        <a href="https://dl.vida.id/VIDA-Privacy_Policy_for_Digital_Certificate.pdf" target="_blank" rel="noreferrer">
          CA Privacy Policy
        </a>{' '}
        , and{' '}
        <a href="https://dl.vida.id/VIDA-CPS.pdf" target="_blank" rel="noreferrer">
          VIDA&apos;s Certification Practice Statement
        </a>{' '}
        which can be accessed via{' '}
        <a href="https://www.repo.vida.id/" target="_blank" rel="noreferrer">
          https://repo.vida.id
        </a>
      </p>
    </div>
  );
};

export default VidaTermsAndConditions;
