import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

export const JointeffPaymentHistory = OurType.PrismaModel(
  "JointeffPaymentHistory",
  {
    id: Type.Number(),
    underwritingId: Type.Number(),
    token: OurType.Nullable(Type.String()),
  }
);
