import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { useState } from 'react';
import { useBrandSettings } from '../../hooks/useBrandSettings';

export const useTermsAndConditionsModal = () => {
  const { termsConditions } = useBrandSettings();
  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };

  const modal = (
    <Dialog keepMounted open={open} onClose={closeModal} fullWidth={true} maxWidth={'sm'}>
      <DialogContent>{termsConditions}</DialogContent>
      <DialogActions>
        <Button size="small" onClick={closeModal}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  return { modal, openModal, closeModal };
};
