import { Document } from '@getmo/onboarding/shared/endpoints/documents';
import { Grid } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import { Form, Formik } from 'formik';
import { FC, useState } from 'react';
import { DocumentType } from '@getmo/onboarding/shared/endpoints/documents';
import { StepContent } from '../../../components/StepContent/StepContent';
import { StepSubmitButton } from '../../../components/StepSubmitButton/StepSubmitButton';
import { findDocuments, selectSummaryData } from '../../../store/reducers/summaryReducer';
import { useAppSelector } from '../../../store/store';
import { useSnackbar } from '../../components/snackbar/useSnackbar';
import { useApiErrors } from '../../hooks/useApiErrors';
import { useNextStep } from '../../hooks/useNextStep';
import { PhSelfieInput } from './PhSelfieInput';
import { callApi } from '../../../api';
import { personSelfieUploadEndpoint } from '@getmo/onboarding/shared/endpoints/personVerification';

const selectInitialValues = createSelector([selectSummaryData], (summary): InitialValues => {
  return {
    files: {
      selfie: findDocuments(summary, DocumentType.selfie),
    },
  };
});

interface InitialValues {
  files: {
    selfie: Document[];
  };
}

const PhSelfie: FC = () => {
  const title = <>Identity Verification</>;
  const subtitle = (
    <>
      Please provide the following essential details to assist us in tailoring the perfect financing solution for your
      business.
    </>
  );

  const { goToNextStep } = useNextStep();
  const initialValues = useAppSelector((state) => selectInitialValues(state));

  const [isSubmitting, setSubmitting] = useState(false);
  const [initialErrors, setInitialErrors] = useState({});
  const [selfie, setSelfie] = useState('');
  const { showError } = useSnackbar();

  const onSubmit = async () => {
    setInitialErrors({});
    setSubmitting(true);

    try {
      await callApi(personSelfieUploadEndpoint, {
        body: { selfiePhoto: selfie.replace(/^data:\w+\/\w+;base64,/, '') },
      });
      await goToNextStep();
    } catch (e) {
      const { error, validationErrors } = useApiErrors(e);
      setInitialErrors(validationErrors);
      if (error) {
        showError(error);
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <StepContent title={title} subtitle={subtitle} width="medium">
      <Formik enableReinitialize initialValues={initialValues} initialErrors={initialErrors} onSubmit={onSubmit}>
        {({ handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Grid container direction="column" gap={0} pt={0}>
                <Grid item>
                  <PhSelfieInput selfie={selfie} onChange={setSelfie} />
                </Grid>
                <Grid item display="flex" justifyContent="center" pt={2}>
                  <StepSubmitButton isSubmitting={isSubmitting} />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </StepContent>
  );
};

export default PhSelfie;
