import { FileUploadOutlined, InfoOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  InputBase,
  Tooltip,
  Typography,
} from '@mui/material';
import mime from 'mime';
import { AllHTMLAttributes, ChangeEventHandler, useCallback, useRef, useState } from 'react';
import { getPdfImages, loadPdfWithPassword, loadPdfWithoutEncryption } from '../../../../utils/pdf';
import { useThemeQuery } from '../../../hooks/useThemeQuery';
import { MimeType } from '../../../types/mimeType';
import { useFilePasswordModal } from './pdf/password/useFilePasswordModal';
import { useImagePickerModal } from './pdf/images/useImagePickerModal';
import { UploadFunction, OnUploadedFunction } from './useFileUpload';

interface Props {
  formLabel?: string;
  subtitle?: string | JSX.Element;
  header?: JSX.Element;
  upload: UploadFunction;
  onUploaded: OnUploadedFunction;
  isDisabled: boolean;
  isMultiple: boolean;
  isRequired: boolean;
  mimeTypes?: MimeType[];
  error?: string;
  hasUploaded?: boolean;
  showInfoAlert?: boolean;
  inputName: string;
  extractImageFromPdf?: 'ktp' | 'npwp';
  capture?: AllHTMLAttributes<HTMLInputElement>['capture'];
}

const readFile = (f: File): Promise<ArrayBuffer | null> =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result instanceof ArrayBuffer ? reader.result : null);
    reader.onerror = () => resolve(null);
    reader.readAsArrayBuffer(f);
  });

export const DocumentFileInput = ({
  formLabel,
  subtitle,
  header,
  upload,
  onUploaded,
  isDisabled,
  isMultiple,
  isRequired,
  mimeTypes,
  error,
  hasUploaded,
  showInfoAlert,
  inputName,
  extractImageFromPdf,
  capture,
}: Props) => {
  const passwordModal = useFilePasswordModal();
  const imagePickModal = useImagePickerModal();
  const usedPasswords = useRef<string[]>([]);
  const [uploading, setUploading] = useState(false);

  const handleFile: (file: File) => Promise<File | null> = async (file: File) => {
    const fileName = file.name.substring(0, file.name.lastIndexOf('.'));
    const content = await readFile(file);
    if (!content) {
      return null;
    }

    // check if document is pdf and encrypted
    const document = await loadPdfWithoutEncryption(content);
    if (!document) {
      return file;
    }

    if (!document.isEncrypted || (await loadPdfWithPassword(content, ''))) {
      // Some files are encrypted without a password. Pass them without decryption
      const images = extractImageFromPdf ? await getPdfImages(document, fileName, extractImageFromPdf) : [];
      if (images.length) {
        return await imagePickModal.open(images);
      }
      return file;
    }

    let isFirstTry = true;
    let pdfDoc: Awaited<ReturnType<typeof loadPdfWithPassword>> | null = null;

    // trying passwords that matched before
    for (const password of usedPasswords.current) {
      pdfDoc = await loadPdfWithPassword(content, password ?? '');
      if (pdfDoc) {
        break;
      }
    }

    // ask for a document password
    while (!pdfDoc) {
      const password = await passwordModal.open(file.name, isFirstTry);
      isFirstTry = false;

      // user rejected to provide a password
      if (password === null) {
        return null;
      }

      pdfDoc = await loadPdfWithPassword(content, password);
      if (pdfDoc) {
        usedPasswords.current.push(password);
      }
    }

    const images = extractImageFromPdf ? await getPdfImages(pdfDoc, fileName, extractImageFromPdf) : [];
    if (images.length) {
      return await imagePickModal.open(images);
    }

    // resave pdf without password
    const pdfCopy = await pdfDoc.save({ useObjectStreams: false });
    const fileCopy = new File([pdfCopy], file.name, { type: file.type });

    return fileCopy;
  };

  const handleFileUpload: ChangeEventHandler<HTMLInputElement> = async (e) => {
    if (!e.currentTarget.files) {
      return;
    }

    try {
      const files = Array.from(e.currentTarget.files);
      const promises: Promise<void>[] = [];
      setUploading(true);

      for (const file of files) {
        const fileToUpload = await handleFile(file);
        if (!fileToUpload) {
          continue;
        }

        const promise = upload(fileToUpload).then((uploaded) => {
          if (uploaded) {
            onUploaded([uploaded]);
          }
        });
        promises.push(promise);
      }

      await Promise.all(promises);
    } finally {
      setUploading(false);
    }
  };

  const { isSmall } = useThemeQuery();
  const getExtensions = useCallback(() => {
    return (mimeTypes || []).map((mimeType) => mime.getExtension(mimeType)?.toUpperCase());
  }, [mimeTypes]);

  const uploadAlert = (
    <>
      {isMultiple && <Box>You can upload more than 1 file at the same time. </Box>}
      {mimeTypes && <Box>Only {getExtensions().join(', ')} files with max size of 10&nbsp;MB each.</Box>}
    </>
  );

  return (
    <Box width="100%">
      {(formLabel || subtitle) && (
        <Box mb={1}>
          {!!formLabel && (
            <FormLabel htmlFor={formLabel} required={isRequired} sx={{ fontSize: '1.1rem', fontWeight: '500' }}>
              {formLabel}
            </FormLabel>
          )}
          {subtitle ? (
            <Typography variant="subtitle2" sx={{ color: 'text.secondary', fontWeight: '400' }}>
              {subtitle}
            </Typography>
          ) : (
            ''
          )}
        </Box>
      )}
      {header}
      <Box display="flex" flexDirection="row" alignItems="center" columnGap={3}>
        {passwordModal.modal}
        {imagePickModal.modal}
        {uploading ? (
          <LoadingButton
            disableRipple
            variant="outlined"
            sx={{
              height: '46.5px',
              minWidth: '111px',
              borderRadius: '5px',
              cursor: 'default',
            }}
          >
            <CircularProgress size={22} />
          </LoadingButton>
        ) : (
          <Button
            component="label"
            variant="contained"
            startIcon={<FileUploadOutlined />}
            disabled={isDisabled}
            sx={{
              flexShrink: '0',
              borderRadius: '5px',
              p: '10px',
              minWidth: '111px',
            }}
          >
            Add File
            <InputBase
              type="file"
              disabled={isDisabled}
              inputProps={{
                multiple: isMultiple,
                accept: mimeTypes,
                'data-testid': inputName,
                capture,
              }}
              onChange={handleFileUpload}
              sx={{ display: 'none' }}
            />
          </Button>
        )}
        {!isSmall && showInfoAlert && (
          <Alert
            severity="info"
            sx={{
              display: 'flex',
              alignItems: 'center',
              py: '5px',
              px: '10px',
              width: '100%',
              fontSize: '14px',
            }}
          >
            {uploadAlert}
          </Alert>
        )}
        {isSmall && showInfoAlert && (
          <Tooltip title={uploadAlert} enterTouchDelay={0}>
            <InfoOutlined color="primary" />
          </Tooltip>
        )}
      </Box>
      <FormControl error={!!error} fullWidth>
        <FormHelperText sx={{ mx: 0 }}>{error || (hasUploaded ? '' : ' ')}</FormHelperText>
      </FormControl>
    </Box>
  );
};
