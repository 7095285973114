import { Type } from "@sinclair/typebox";
import * as OurType from "../../typebox";

import { UnderwritingPhObligationType } from "./UnderwritingPhObligationType";
import { UnderwritingPhObligationInformationSource } from "./UnderwritingPhObligationInformationSource";

export const UnderwritingPhObligation = OurType.PrismaModel(
  "UnderwritingPhObligation",
  {
    id: Type.Number(),
    underwritingId: Type.Number(),
    type: UnderwritingPhObligationType,
    informationSource: UnderwritingPhObligationInformationSource,
    loanAmount: OurType.TypeMoney(),
    loanDuration: Type.Number(),
    remainingLoanDuration: Type.Number(),
    monthlyRepayment: OurType.TypeMoney(),
  }
);
