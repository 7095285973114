import * as OurType from "../../typebox";

export const MessageStatus = OurType.OurEnum({
  queued: {},
  queuingFailed: {},
  sent: {},
  deliveredToOperator: {},
  deliveredToRecipient: {},
  rejectedByOperator: {},
  rejectedByRecipient: {},
  read: {},
  ok: {},
  error: {},
  unknown: {},
});
