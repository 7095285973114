export const TermsAndConditionsIndJuragan = () => {
  return (
    <div>
      <center>
        {' '}
        <h2>Ketentuan Privasi Kami</h2>
      </center>
      <ul>
        <li>
          Kebijakan privasi ini bertujuan untuk membantu anda memahami data yang kami kumpulkan sesuai dengan Kebijakan
          Privasi ini, yang mana ketentuan di dalamnya dapat berubah dari waktu ke waktu sehingga anda dapat melakukan
          pengecekan kebijakan secara berkala.
        </li>
        <li>
          Kami dapat mengumpulkan informasi pribadi anda jika anda secara sukarela mengirimkan informasi kepada kami
          melalui input data pribadi pada dashboard <strong>JuraganCOD</strong>
        </li>
        <li>
          Anda harus mengirimkan kepada kami informasi yang akurat dan tidak menyesatkan dan anda harus tetap
          memperbarui dan menginformasikan perubahan kepada kami.
        </li>
        <li>
          Kami mungkin tidak dapat memberikan layanan kepada anda apabila anda memilih untuk tidak memberikan informasi
          pribadi anda dan selanjutnya menarik persetujuan anda untuk penggunaan informasi pribadi anda oleh kami.
        </li>
        <li>
          Informasi pribadi anda tidak akan disebarluaskan kepada pihak ketiga kecuali sebagaimana yang diperlukan dalam
          proses penyediaan layanan <strong>JuraganCOD</strong>.
        </li>
        <li>
          Jika anda meberikan informasi pribadi dari pihak ketiga mana pun kepada kami, kami menganggap bahwa anda telah
          memperoleh persetujuan yang diperlukan dari pihak ketiga yang relevan untuk membagikan dan mentransfer
          informasi pribadinya kepada kami
        </li>
        <li>
          Kebijakan dalam <strong>JuraganCOD</strong> ini berlaku mulai 1 September 2022.
        </li>
      </ul>
      <h4>Cara kami mendapatkan data pribadi</h4>
      <ul>
        <li>
          kami menerima data pribadi melalui data yang telah di diisi oleh anda yang telah menggunakan layanan kami
          melalui platform <strong>JuraganCOD</strong>.
        </li>
        <li>
          <strong>JuraganCOD</strong> juga dapat, dari waktu ke waktu, menghubungi anda melalui email, melalui telepon
          secara langsung, untuk meminta informasi tambahan yang secara wajar diperlukan untuk memfaslitiasi pengiriman
          produk pengiriman kepada anda. Dalam kasus tersebut, dengan memberikan data pribadi apa pun secara langsung ke
          JuraganCOD, anda akan dianggap telah menyetujui persyaratan kebijakan ini, termasuk tujuan yang ditetapkan
          dalam kebijakan ini dimana JuraganCOD mengumpulkan menggunakan, dan mengungkapkan data pribadi anda.
        </li>
      </ul>
      <h4>Data Pribadi yang dicakup oleh kebijakan ini</h4>
      <ul>
        <li>
          Saat anda membuat akun <strong>JuraganCOD</strong>, atau memberikan informasi pribadi anda kepada kami melalui
          platform, informasi pribadi yang kami kumpulkan mungkin termasuk nama (termasuk depan dan belakang), Alamat
          mengiriman (termasuk tempat tinggal dan tempat kerja), alamat email, nomor telepon, informasi mengenai produk
          (termasuk deskripsi produk, jumlah, harga, dan informasi penagihan, dan setiap informasi lain yang mungkin
          diperlukan secara wajar untuk memfasilitasi pengiriman produk kepada anda dan penerima perorangan lainnya.
        </li>
      </ul>
      <h4>Cara Kami Melindungi Privasi Anda</h4>
      <ul>
        <li>
          Informasi rahasia yang mencakup konten atau informasi pribadi apapun yang anda berikan kepada perusahaan kami,
          tidak akan diketahui oleh publik kecuali informasi yang ; (a) berada/masuk di domain publik pada saat kita
          menerimanya; (b) kami menerima dari seseorang selain anda / kami diwajibkan oleh hukum untuk mengungkapkan.
        </li>
        <li>
          <strong>JuraganCOD</strong> tidak akan menjual, mengalihkan/membuka informasi dan data-data pribadi anda
          kepada orang lain/ pihak ketiga yang tidak berkepentingan. Sehubungan dengan hal tersebut maka anda setuju
          untuk membebaskan kami dari segala klaim, tuntutan dan gugatan yang berkaitan dengan pemberian akses atas
          informasi tersebut.
        </li>
      </ul>
      <h4>Pentingnya Privasi Anda Bagi Kami</h4>
      <ul>
        <li>
          Kami dapat dan berhak mengumpulkan semua data informasi yang anda berikan dengan tujuan seperti pengiriman
          email promosi tentang penawaran baru atau informasi lain yang menarik dengan menggunakan alamat email yang
          anda berikan, serta menggunakan informasi tersebut untuk menyesuaikan layanan sesuai dengan minat anda.. Data
          informasi yang anda berikan akan kami jaga dan simpan sebaik-baiknya dengan memperhatikan faktor keamanan.
        </li>
      </ul>
      <h4>Cara kami menggunakan data pribadi</h4>
      <ul>
        <li>
          Informasi pribadi yang kami kumpulkan dari anda akan digunakan, atau dibagikan dengan pihak ketiga (penyedia
          layanan ekspedisi), termasuk dengan tujuan untuk melayani anda, memastikan pengiriman tepat waktu dan sukses
          untuk anda, dan meningkatikan kualitas layanan kami untuk anda dan untuk beberapa atau semua tujuan berikut
          (jika dapat diberlakukan):
        </li>
        <ul>
          <li>
            Mengelola operasi adminstrasi dan bisnis <strong>JuraganCOD</strong> dan mematuhi kebijakan dan prosedur
            internal
          </li>
          <li>Kinerja kayanan pengiriman anda,</li>
          <li>Pelacakan dan analisis pengiriman anda,</li>
          <li>
            Memberitahu anda tentang status pesanan atau pengiriman anda dan memberi informasi status terbaru pada anda
            mengenai pesanan yang sama,
          </li>
          <li>Menghubungi anda untuk mengetahui tanggapan anda setelah atau selama pengiriman,</li>
          <li>Menanggapi dan menangani pertanyaan dan permintaan anda,</li>
          <li>Melakukan investigasi yang berkaitan dengan perselisihan, penagihan atau penipuan,</li>
          <li>Mengatasi keluhan, masalah atau perselisihan,</li>
          <li>
            Memantau atau merekam panggilan telepon dan interaksi yang dihadapi pelanggan untuk jaminan kualitas,
            pelatihan karyawan dan evaluasi kerja,
          </li>
          <li>Memberikan pengumuman dan tanggapan media,</li>
          <li>
            Tujuan hukum (termasuk tetapi tidak terbatas pada mendapatkan nasihat hukum dan penyelesaian perselishian),
          </li>
          <li>Tujuan akuntansi, manajemen risiko, kepatuhan dan pencatatan,</li>
          <li>
            Memenuhi atau mematuhi peraturan, hukum ataupun perundang-undangan, etika praktik atau pedoman yang berlaku
            yang dikeluarkan oleh badan hukum atau badan pengaturanmana pun yang mengikat pada JuraganCOD, dan
          </li>
          <li>Tujuan yang secara wajar terkait dengan yang disebutkan diatas.</li>
          <li>
            Dalam keadaan khusus, JuraganCOD mungkin diminta untuk mengungkapkan informasi pribadi, seperti Ketika ada
            alasan untuk percaya bahwa pengungkapan tersebut diperlukan untuk mencegah ancaman terhadap kehidupan atau
            Kesehatan, atau untuk tujuan penegakan hukum, atau untuk memenuhi pesyaratan hukum dan peraturan dan
            permintaan.
          </li>
        </ul>
        <li>
          saat menggunakan data pribadi anda untuk tujuan di atas, kami dapat menghubungi anda melalui email, telepon
          atau sarana elektronik lainnya.
        </li>
      </ul>
      <h4>Perubahan Kebijakan Privasi</h4>
      <strong>JuraganCOD</strong> secara teratur akan meninjau kecukupan kebijakan privasi ini. Kami berhak mengubah
      kebijakan privasi setiap saat. Setiap perubahan pada kebijakan ini akan dipublikasikan di platform{' '}
      <strong>JuraganCOD</strong>. juraganCOD menghimbau anda untuk setiap perubahan material yang terjadi, anda
      dipersilahkan unutk mengunjungi laman website ini untuk mempelajari perubahan terjadi dari waktu ke waktu.
      <br />
      <h4>Lain - Lain</h4>
      <ul>
        <li>
          “Cookie” adalah file kecil yang secara otomatis akan tersimpan dalam perangkat anda untuk mengidentifikasi dan
          memantau koneksi jaringan anda sehingga memungkinkan anda untuk mengakses layanan <strong>JuraganCOD</strong>{' '}
          secara optimal, seperti; nama anda, alamat, nomor telepon, alamat surel, dan mengumpulkan informasi tentang
          lokasi anda saat anda menggunakan layanan kami. Hal ini bertujuan untuk dapat membantu proses pengambilan dan
          pengiriman paket pengguna <strong>JuraganCOD</strong> dengan aplikasi berbasis GPS, alamat IP, data sensor
          dari perangkat anda serta titik akses Wi-Fi, dan perangkat Bluetooth.
        </li>
        <li>Semua jenis data lokasi yang kami kumpulkan bergantung pada perangkat dan pengaturan akun anda.</li>
      </ul>
      <h4>Cara menghubungi kami</h4>
      Jika anda memiliki pertanyaan lebih lanjut tentang kebijakan privasi silahkan hubungi kami melalui email ke{' '}
      <a href="mailto:juragancodcooperation@gmail.com">
        <mark style={{ backgroundColor: '#fcf8e3' }}>juragancodcooperation@gmail.com</mark>
      </a>
      <br />
      <strong>
        {' '}
        *Ketentuan-ketentuan dalam kebijakan privasi ini tunduk kepada hukum di wilayah negara Republik Indonesia.{' '}
      </strong>
    </div>
  );
};

export default TermsAndConditionsIndJuragan;
