import { useEffect } from 'react';
import { Favicon } from './useBrandSettings';

export const useDynamicFavicon = (favicons: Favicon[]) => {
  useEffect(() => {
    const oldFavicons = document.querySelectorAll("link[rel*='icon']");
    oldFavicons.forEach((f) => {
      if (document.contains(f)) {
        document.removeChild(f);
      }
    });

    favicons.forEach(({ href, rel, type }) => {
      const link = document.createElement('link') as HTMLLinkElement;
      link.href = href;
      link.rel = rel;
      if (type) {
        link.type = type;
      }
      document.head.appendChild(link);
    });
  }, [favicons]);
};
