/** Utils shared with onboarding/frontend */

import { cleanUndefineds, stringifyQsJsons } from '../typebox';
import { type Endpoint } from '@getmo/common/endpoint';
import { type StaticDecode, type TSchema } from '@sinclair/typebox';
import { Value } from '@sinclair/typebox/value';

export const formatPath = <E extends Endpoint<TSchema, TSchema, TSchema, TSchema>>(
  baseUrl: string,
  endpoint: E,
  params: StaticDecode<E['params']>,
) => {
  const encodedParams = Value.Encode(endpoint.params, params);
  return (
    baseUrl +
    endpoint.path.replace(/:([^/]+)/g, (_, _param) => {
      const param = _param as keyof typeof encodedParams;
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!encodedParams?.[param]) {
        throw new Error('Missing parameter');
      }

      return String(encodedParams[param]);
    })
  );
};

export const formatApiPath = <E extends Endpoint<TSchema, TSchema, TSchema, TSchema>>(
  endpoint: E,
  params: StaticDecode<E['params']>,
) => formatPath('/api', endpoint, params);

export const encodeQs = <T extends TSchema>(schema: T, data: StaticDecode<T>) => {
  const encoded = Value.Clean(schema, Value.Encode(schema, cleanUndefineds(data)));
  stringifyQsJsons(schema, encoded);
  return encoded as Record<string, string | number>;
};

export const formatQs = <T extends TSchema>(schema: T, data: StaticDecode<T>) => {
  const encodedQs = encodeQs(schema, data) as Record<string, string>;

  return '?' + new URLSearchParams(encodedQs).toString();
};
