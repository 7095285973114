import { $Enums } from '../prisma';
import { isUrlMatching } from './url';

export const SHOP_EXAMPLE_URLS: Record<$Enums.Region, string> = {
  [$Enums.Region.id]: 'https://www.shopee.co.id/your-store-name',
  [$Enums.Region.ph]: 'https://www.shopee.com.ph/your-store-name',
};

export const getShopType = (url: string): $Enums.ShopType | null => {
  const urlOpts = { strictProtocol: false };

  if (
    isUrlMatching(url, 'shopee.co.id', urlOpts) ||
    isUrlMatching(url, 'id.shp.ee', urlOpts) ||
    isUrlMatching(url, 'shopee.ph', urlOpts) ||
    isUrlMatching(url, 'ph.shp.ee', urlOpts)
  ) {
    return $Enums.ShopType.shopee;
  }

  if (isUrlMatching(url, 'tokopedia.com', urlOpts) || isUrlMatching(url, 'tokopedia.link', urlOpts)) {
    return $Enums.ShopType.tokopedia;
  }
  if (
    isUrlMatching(url, 'lazada.co.id', urlOpts) ||
    isUrlMatching(url, 's.lazada.co.id', urlOpts) ||
    isUrlMatching(url, 'lazada.com.ph', urlOpts) ||
    isUrlMatching(url, 's.lazada.com.ph', urlOpts)
  ) {
    return $Enums.ShopType.lazada;
  }
  if (isUrlMatching(url, 'shop.tiktok.com', urlOpts)) {
    return $Enums.ShopType.tiktok;
  }
  if (isUrlMatching(url, 'blibli.com', urlOpts)) {
    return $Enums.ShopType.blibli;
  }

  if (isUrlMatching(url, 'youtube.com', urlOpts)) {
    return $Enums.ShopType.youtube;
  }

  if (isUrlMatching(url, 'twitter.com', urlOpts)) {
    return $Enums.ShopType.twitter;
  }

  if (isUrlMatching(url, 'instagram.com', urlOpts)) {
    return $Enums.ShopType.instagram;
  }

  if (isUrlMatching(url, 'facebook.com', urlOpts)) {
    return $Enums.ShopType.facebook;
  }

  if (isUrlMatching(url, undefined, urlOpts)) {
    return $Enums.ShopType.website;
  }

  return null;
};

export const getShopConnectionType = (shopType: $Enums.ShopType): false | 'redirect' | 'email' => {
  switch (shopType) {
    case $Enums.ShopType.shopee:
    case $Enums.ShopType.lazada:
      return 'redirect';
    case $Enums.ShopType.tokopedia:
      return 'email';
    default:
      return false;
  }
};
