import { Box, Stack } from '@mui/material';
import { useThemeQuery } from '../hooks/useThemeQuery';
import { useBrandSettings } from '../hooks/useBrandSettings';

interface Props {
  children: JSX.Element;
}

const { logo } = useBrandSettings();

const AppHeader = ({ children }: Props) => {
  const { isMedium } = useThemeQuery();

  return (
    <Stack
      display="flex"
      direction="row"
      sx={{
        minHeight: '65px',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #e2e4e8',
      }}
    >
      {children}
      <Box
        component="img"
        height={34}
        padding={'5px 15px 5px 10px'}
        alt="logo"
        src={isMedium ? logo.mobile : logo.desktop}
      />
    </Stack>
  );
};

export default AppHeader;
